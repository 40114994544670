export function maskEmail(email) {
  let split = email.split("@");
  return (
    email.substr(0, 3) +
    new Array(split[0].length - 1).fill("*").join("") +
    "@" +
    split[1]
  );
}

export function maskPhoneNumber(phoneNumber) {
  let subNum = phoneNumber.toString().substring(0, 0);
  subNum = subNum + "*********";
  return subNum;
}

export function b64toBlob(b64Data, contentType, sliceSize) {
  contentType = contentType || "";
  sliceSize = sliceSize || 512;

  var byteCharacters = atob(b64Data); // window.atob(b64Data)
  var byteArrays = [];

  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    var slice = byteCharacters.slice(offset, offset + sliceSize);

    var byteNumbers = new Array(slice.length);
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    var byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  var blob = new Blob(byteArrays, { type: contentType });
  return blob;
}

export function prettyDate(date) {
  const newDate = new Date(date);
  return newDate.toDateString();
}

export function truncateString(str, num) {
  if (str.length > num) {
      return str.slice(0, num) + '...';
  } else {
      return str;
  }
}

export function getGender() {}

export const validation = (values) => {
  const errors = {};

  const email_pattern = /^[^\s@]+@[^\s@]+\.[^\s@]{2,6}$/;
  const password_pattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9]{8,}$/;

  if (values.firstName === "") {
    errors.firstName = "Firstname is required";
  }

  if (values.lastName === "") {
    errors.lastName = "Lastname is required";
  }

  if (values.phoneNo === "") {
    errors.phoneNo = "Phone number is required";
  }

  if (values.dateOfBirth === "") {
    errors.dateOfBirth = "Date of birth is required";
  }

  if (values.email === "") {
    errors.email = "Email is required";
  } else if (!email_pattern.test(values.email)) {
    errors.email = "Enter a valid email";
  }

  if (values.password === "") {
    errors.password = "Password is required";
  }

  return errors;
};

export const history = {
  navigate: null,
  location: null,
};
