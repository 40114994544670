import axios from "axios";
import { history } from "../../utils/helpers";

const BaseUrl = process.env.REACT_APP_BASE_API_URL;

// RESGITER USER
const register = async (userData) => {
  const response = await axios.post(
    `${BaseUrl}/registernewindividual`,
    userData
  );
  return response.data;
};

// RESGITER BUSINESS
const registerBusiness = async (corporateData) => {
  const response = await axios.post(
    `${BaseUrl}/registernewcorporateclient`,
    corporateData
  );
  return response.data;
};

//Login User
const login = async (userData) => {
  return await axios.post(`${BaseUrl}/login`, userData);
};

const verify = async (userData) => {
  const headers = {
    "Content-Type": "application/json; charset=utf-8",
  };

  const response = await axios.post(`${BaseUrl}/login2fa`, userData, headers);
  if (response.data) {
    localStorage.setItem("user", JSON.stringify(response.data));
  }
  return response.data;
};

const accountActivate = async (userData) => {
  const headers = {
    "Content-Type": "application/json; charset=utf-8",
  };

  return await axios.post(`${BaseUrl}/activateaccount`, userData, headers);
};

const registerExistingUser = async (userData) => {
  const headers = {
    "Content-Type": "application/json; charset=utf-8",
  };

  return await axios.post(
    `${BaseUrl}/registerexistingindividual`,
    userData,
    headers
  );
};

const registerExistingBusiness = async (userData) => {
  const headers = {
    "Content-Type": "application/json; charset=utf-8",
  };

  return await axios.post(
    `${BaseUrl}/registerexistingcorporateclient`,
    userData,
    headers
  );
};

//Logout user
const logout = () => {
  localStorage.clear();
};

const authService = {
  register,
  registerBusiness,
  registerExistingUser,
  registerExistingBusiness,
  login,
  logout,
  verify,
  accountActivate,
};

export default authService;
