import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useFormik, Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import * as Icons from "react-icons/fa6";

import { registerBusiness, reset } from "../../../../features/auth/authSlice";
import { alertActions } from "../../../../app/store";

const steps = ["Account Details", "Personal Info"];

const Corporate = () => {
  const [loading, setLoading] = useState(false);

  const [activeStep, setActiveStep] = useState(0);
  const isLastStep = activeStep === steps.length - 1;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const initialValues = {
    companyName: "",
    registeredCompanyNumber: "",
    companyEmailAddress: "",
    companyMobileNumber: "",
    dateOfIncorporation: "",
    emailAddress: "",
    password: "",
    firstName: "",
    lastName: "",
    phoneNo: "",
    referredBy: ""
  };

  const validationSchema = [
    Yup.object().shape({
      companyName: Yup.string().required("This field is required!"),
      registeredCompanyNumber: Yup.string().required("This field is required!"),
      companyEmailAddress: Yup.string().required("This field is required!"),
      companyMobileNumber: Yup.string().required("This field is required!"),
      dateOfIncorporation: Yup.string().required("This field is required!"),
    }),
    Yup.object().shape({
      firstName: Yup.string().required("This field is required!"),
      lastName: Yup.string().required("This field is required!"),
      emailAddress: Yup.string().required("This field is required!"),
      password: Yup.string()
        .required("This field is required!")
        .matches(
          /(?=.*?[A-Z])/,
          "Password must contain at least one capital letter"
        )
        .matches(/(?=.*?[0-9])/, "Password must contain at least one number")
        .matches(
          /(?=.*?[#?!@$%^&*-])/,
          "Password must contain at least one special character"
        ),
      phoneNo: Yup.string().required("This field is required!"),
    }),
    Yup.object().shape({}),
  ];

  const currentValidationSchema = validationSchema[activeStep];

  function _sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async function _submitForm(values) {
    // await _sleep(1000);
    // alert(JSON.stringify(values, null, 2));

    // setActiveStep(activeStep + 1);

    const {
      firstName,
      lastName,
      emailAddress,
      phoneNo,
      password,
      companyName,
      registeredCompanyNumber,
      companyEmailAddress,
      companyMobileNumber,
      dateOfIncorporation,
      referredBy
    } = values;

    dispatch(alertActions.clear());

    try {
      setLoading(true);

      await dispatch(
        registerBusiness({
          firstName,
          lastName,
          emailAddress,
          phoneNo,
          password,
          companyName,
          registeredCompanyNumber,
          companyEmailAddress,
          companyMobileNumber,
          dateOfIncorporation,
          clientType: 0,
          referredBy,
        })
      ).unwrap();
      localStorage.setItem("email", emailAddress);
      navigate("/account/activate");
      dispatch(
        alertActions.success({
          message: "You have been successfully registered",
          showAfterRedirect: true,
        })
      );
      setLoading(false);
    } catch (error) {
      dispatch(alertActions.error(error));
      setLoading(false);
    }
  }

  const _handleSubmit = async (values) => {
    if (isLastStep) {
      _submitForm(values);
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  function _handleBack() {
    setActiveStep(activeStep - 1);
  }

  const formContent = ({ errors, touched, values }) => {
    if (activeStep === 0) {
      return <Step1 errors={errors} touched={touched} values={values} />;
    } else {
      return <Step2 errors={errors} touched={touched} values={values} />;
    }
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={currentValidationSchema}
        onSubmit={_handleSubmit}
      >
        {({ errors, touched, values }) => (
          <Form>
            <div className="mt-1">
              <div className="flex mb-2">
                <div
                  className={`w-1/2 ${
                    activeStep === 0
                      ? "bg-h28_dark_green text-white"
                      : "bg-h28_dark_green  text-white"
                  } h-1.5 bordertext-center cursor-pointer rounded-l`}
                  onClick={() => setActiveStep(0)}
                ></div>
                <div
                  className={`w-1/2 ${
                    activeStep === 1
                      ? "bg-h28_dark_green  text-white"
                      : "bg-gray-200 text-gray-600"
                  } text-center rounded-r cursor-pointer`}
                  onClick={() => setActiveStep(1)}
                ></div>
              </div>
              {formContent({ errors, touched, values })}
              <div className="mt-3">
                <p className="text-gray-500 text-sm font-normal">
                  By signing up, you agree to{" "}
                  <span className="text-h28_dark_green">
                    STLAM's Terms of Use & Privacy Policy
                  </span>
                </p>
              </div>
              <div className="flex justify-between gap-3 mt-2">
                {activeStep !== 0 && (
                  <button
                    type="button"
                    className="w-full px-4 py-2.5 border border-gray-200 text-gray-600 rounded-md "
                    onClick={_handleBack}
                  >
                    Back
                  </button>
                )}
                {loading ? (
                  <button
                    type="submit"
                    className="w-full px-4 py-2.5 font-bold text-white bg-h28_dark_green rounded-md flex items-center justify-center"
                    disabled={loading}
                  >
                    <span
                      className="mr-5 inline-block h-5 w-5 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                      role="status"
                    >
                      <span class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                        Loading...
                      </span>
                    </span>
                    Loading...
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="w-full px-4 py-2.5 text-white rounded-md bg-h28_dark_green"
                  >
                    <span className="text-md font-bold">
                      {isLastStep ? "Submit" : "Next"}
                    </span>
                  </button>
                )}
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

const Step1 = ({ errors, touched, values }) => {
  return (
    <>
      <div className="">
        <label className="block text-h28_dark_green text-sm font-semibold">
          Company Name
        </label>
        <Field
          type="text"
          placeholder="Insert Company Name"
          autoComplete="off"
          name="companyName"
          className={`w-full px-4 py-3 border text-neutral-500 text-sm rounded-md focus:outline-none ${
            errors.companyName && touched.companyName ? "border-red-500" : ""
          } focus:border-h28_dark_green`}
        />
        <ErrorMessage
          name="companyName"
          component="div"
          className="text-red-500 text-sm"
        />
      </div>
      <div className="mt-3">
        <label className="block text-h28_dark_green text-sm font-semibold">
          Registration Number
        </label>
        <Field
          type="text"
          placeholder="Insert Registration Number"
          autoComplete="off"
          name="registeredCompanyNumber"
          className={`w-full px-4 py-3 border text-neutral-500 text-sm rounded-md focus:outline-none ${
            errors.registeredCompanyNumber && touched.registeredCompanyNumber
              ? "border-red-500"
              : ""
          } focus:border-h28_dark_green`}
        />
        <ErrorMessage
          name="registeredCompanyNumber"
          component="div"
          className="text-red-500 text-sm"
        />
      </div>
      <div className="mt-3">
        <label className="block text-h28_dark_green text-sm font-semibold">
          Date of Incorporation
        </label>
        <Field
          type="date"
          placeholder="Insert Date of Incorporation"
          autoComplete="off"
          name="dateOfIncorporation"
          className={`w-full px-4 py-3 border text-neutral-500 text-sm rounded-md focus:outline-none ${
            errors.dateOfIncorporation && touched.dateOfIncorporation
              ? "border-red-500"
              : ""
          } focus:border-h28_dark_green`}
        />
        <ErrorMessage
          name="dateOfIncorporation"
          component="div"
          className="text-red-500 text-sm"
        />
      </div>
      <div className="mt-3">
        <label className="block text-h28_dark_green text-sm font-semibold">
          Company Phone Number
        </label>
        <Field
          type="text"
          placeholder="Insert Company Phone Number"
          autoComplete="off"
          name="companyMobileNumber"
          className={`w-full px-4 py-3 border text-neutral-500 text-sm rounded-md focus:outline-none ${
            errors.companyMobileNumber && touched.companyMobileNumber
              ? "border-red-500"
              : ""
          } focus:border-h28_dark_green`}
        />
        <ErrorMessage
          name="companyMobileNumber"
          component="div"
          className="text-red-500 text-sm"
        />
      </div>
      <div className="mt-3">
        <label className="block text-h28_dark_green text-sm font-semibold">
          Company Email Address
        </label>
        <Field
          type="text"
          placeholder="Insert Company Email Address"
          autoComplete="off"
          name="companyEmailAddress"
          className={`w-full px-4 py-3 border text-neutral-500 text-sm rounded-md focus:outline-none ${
            errors.companyEmailAddress && touched.companyEmailAddress
              ? "border-red-500"
              : ""
          } focus:border-h28_dark_green`}
        />
        <ErrorMessage
          name="companyEmailAddress"
          component="div"
          className="text-red-500 text-sm"
        />
      </div>
      <div className="mt-3">
        <label className="block text-h28_dark_green text-sm font-semibold">
          Referred by
        </label>
        <Field
          type="text"
          placeholder="Insert who referred you"
          autoComplete="off"
          name="referredBy"
          className={`w-full px-4 py-3 border text-neutral-500 text-sm rounded-md focus:outline-none ${
            errors.referredBy && touched.referredBy
              ? "border-red-500"
              : ""
          } focus:border-h28_dark_green`}
        />
        <ErrorMessage
          name="referredBy"
          component="div"
          className="text-red-500 text-sm"
        />
      </div>
    </>
  );
};

const Step2 = ({ errors, touched, values }) => {
  const [passwordView, setPasswordView] = useState(true);

  return (
    <>
      {/* <h2 className="text-h28_dark_green text-sm">
        Just a few more things from you
      </h2> */}
      <div className="mt-1">
        <label className="block text-h28_dark_green text-sm font-semibold">
          First Name
        </label>
        <Field
          type="text"
          placeholder="First Name"
          autoComplete="off"
          name="firstName"
          className={`w-full px-4 py-3 border text-neutral-500 text-sm rounded-md focus:outline-none ${
            errors.firstName && touched.firstName ? "border-red-500" : ""
          } focus:border-h28_dark_green`}
        />
        <ErrorMessage
          name="firstName"
          component="div"
          className="text-red-500 text-sm"
        />
      </div>
      <div className="mt-3">
        <label className="block text-h28_dark_green text-sm font-semibold">
          Last Name
        </label>
        <Field
          type="text"
          placeholder="Last Name"
          autoComplete="off"
          name="lastName"
          className={`w-full px-4 py-3 border text-neutral-500 text-sm rounded-md focus:outline-none ${
            errors.lastName && touched.lastName ? "border-red-500" : ""
          } focus:border-h28_dark_green`}
        />
        <ErrorMessage
          name="lastName"
          component="div"
          className="text-red-500 text-sm"
        />
      </div>
      <div className="mt-3">
        <label className="block text-h28_dark_green text-sm font-semibold">
          Phone Number
        </label>
        <Field
          type="text"
          placeholder="Phone Number"
          autoComplete="off"
          name="phoneNo"
          className={`w-full px-4 py-3 border text-neutral-500 text-sm rounded-md focus:outline-none ${
            errors.phoneNo && touched.phoneNo ? "border-red-500" : ""
          } focus:border-h28_dark_green`}
        />
        <ErrorMessage
          name="phoneNo"
          component="div"
          className="text-red-500 text-sm"
        />
      </div>
      <div className="mt-3">
        <label className="block text-h28_dark_green text-sm font-semibold">
          Email Address
        </label>
        <Field
          type="text"
          placeholder="Email Address"
          autoComplete="off"
          name="emailAddress"
          className={`w-full px-4 py-3 border text-neutral-500 text-sm rounded-md focus:outline-none ${
            errors.emailAddress && touched.emailAddress ? "border-red-500" : ""
          } focus:border-h28_dark_green`}
        />
        <ErrorMessage
          name="emailAddress"
          component="div"
          className="text-red-500 text-sm"
        />
      </div>
      <div className="mt-3">
        <label className="block text-h28_dark_green text-sm font-semibold">
          Password
        </label>
        <div
          className={`w-full flex flex-row justify-between items-center border text-neutral-500 rounded-md focus:outline-none ${
            errors.password && touched.password ? "border-red-500" : ""
          } focus:border-h28_dark_green`}
        >
          <Field
            type={passwordView === true ? "password" : "text"}
            placeholder="Input your yassword"
            autoComplete="off"
            name="password"
            className={`w-full px-3 py-2 text-neutral-500 text-sm m-1 focus:outline-none`}
          />
          <span className="px-4">
            <button
              type="button"
              onClick={() => setPasswordView(!passwordView)}
            >
              {passwordView === true ? (
                <Icons.FaEye size={17} />
              ) : (
                <Icons.FaEyeSlash size={17} />
              )}
            </button>
          </span>
        </div>
        <ErrorMessage
          name="password"
          component="div"
          className="text-red-500 text-sm"
        />
      </div>
    </>
  );
};

export default Corporate;
