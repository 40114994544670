import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import { login } from "../../../features/auth/authSlice";

import { alertActions } from "../../../app/store";

import logo_2 from "../../../assets/images/logo_2.svg";

const Login = () => {
  const initialValues = {
    username: "",
    password: "",
  };

  const validationSchema = Yup.object().shape({
    username: Yup.string()
      .required("This field is required!")
      .email("Please enter a valid email"),
    password: Yup.string()
      .required("This field is required!")
      .min(8, "Password must be at least 6 characters"),
  });

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = async (formValue) => {
    const { username, password } = formValue;

    dispatch(alertActions.clear());

    try {
      setLoading(true);

      await dispatch(login({ username: username, password })).unwrap();
      localStorage.setItem("email", username);
      navigate("/login/otp");
      dispatch(
        alertActions.success({
          message: "Login successful",
          showAfterRedirect: true,
        })
      );
      setLoading(false);
    } catch (error) {
      dispatch(alertActions.error(error));
      setLoading(false);
    }
  };

  return (
    <div className="w-[420px] md:w-[440px] p-8 bg-white rounded-lg">
      {/* <img src={logo_2} alt="logo" className="w-10 mb-5" /> */}
      <h3 className="text-2xl font-semibold text-h28_dark_green mb-2">
        Welcome Back!!
      </h3>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched }) => (
          <Form>
            <div className="mt-4">
              <div className="mt-4">
                <label className="block text-h28_dark_green text-sm font-semibold">
                  Email
                </label>
                <Field
                  type="text"
                  id="email"
                  placeholder="Email"
                  autoComplete="off"
                  name="username"
                  className={`w-full px-4 py-3 mt-1 border text-neutral-500 text-sm rounded-md focus:outline-none ${
                    errors.username && touched.username ? "border-red-500" : ""
                  } focus:border-h28_dark_green`}
                />
                <ErrorMessage
                  name="username"
                  component="div"
                  className="text-red-500 text-sm"
                />
                {/* {errors.email && (
                <p className="text-red-500 text-sm">{errors.email}</p>
              )} */}
              </div>
              <div className="mt-4">
                <label className="block text-h28_dark_green text-sm font-semibold">
                  Password
                </label>
                <Field
                  type="password"
                  placeholder="Password"
                  autoComplete="off"
                  name="password"
                  className={`w-full px-4 py-3 mt-1 border text-neutral-500 text-sm rounded-md focus:outline-none ${
                    errors.password && touched.password ? "border-red-500" : ""
                  } focus:border-h28_dark_green`}
                />
                <ErrorMessage
                  name="password"
                  component="div"
                  className="text-red-500 text-sm"
                />
                {/* {errors.password && (
                <p className="text-red-500 text-sm">{errors.password}</p>
              )} */}
                <div className="flex flex-row justify-between mt-2">
                  <div className="flex items-center">
                    <input
                      id="default-checkbox"
                      type="checkbox"
                      value=""
                      className="w-3 h-3 text-h28_dark_green checked:bg-h28_dark_green bg-gray-100 rounded-md border-gray-300"
                    />
                    <label className="ml-2 text-sm text-neutral-500">
                      Remember me
                    </label>
                  </div>
                  <Link
                    to="/forgot-password"
                    className=" text-sm text-neutral-500 hover:text-h28_dark_green"
                  >
                    Forgot your password?
                  </Link>
                </div>
              </div>
              <div className="flex items-baseline justify-between mt-3">
                {loading ? (
                  <button
                    type="submit"
                    className="w-full px-4 py-3 font-bold text-white bg-h28_green rounded-md flex items-center justify-center"
                    disabled={loading}
                  >
                    <span
                      className="mr-5 inline-block h-5 w-5 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                      role="status"
                    >
                      <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                        Loading...
                      </span>
                    </span>
                    Loading...
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="w-full px-4 py-3 font-bold text-white bg-h28_dark_green rounded-md"
                  >
                    Login
                  </button>
                )}
              </div>
              <div className="flex items-center justify-center mt-2">
                <p className="mt-1 text-sm text-neutral-500">
                  New Here?
                  <Link to="/register" className="text-h28_dark_green">
                    <span> Create an Account</span>
                  </Link>
                </p>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Login;
