import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";

import authImage from "../assets/images/Black.svg";
import logo from "../assets/images/Group 1846.svg";
import { reset } from "../features/auth/authSlice";

const AuthLayout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const myStyle = {
    backgroundImage: `url("../assets/images/Black.svg")`,
  };

  const { user, isSuccess, isError, message } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    if (user !== null) {
      navigate("/");
    }
    dispatch(reset());
  }, [user, isError, isSuccess, message, navigate, dispatch]);

  return (
    <div className="relative min-h-screen font-gabarito antialiased lg:flex bg-white">
      <div className="flex flex-row">
        <div className="hidden md:flex flex-col justify-between w-[45rem] h-screen xs:hidden bg-h28_dark_green  text-white fixed ">
          <div className="flex flex-col justify-center p-16">
            <span className="flex flex-col">
              <img src={logo} alt="1Click" className="w-28" />
              <p className="text-xl font-normal ml-[4.7rem] -mt-1">
                by <span className="text-2xl font-bold">STL</span>
              </p>
            </span>
            <hr className="w-44 my-10" />
            <h3 className="text-6xl font-semibold mb-6 w-10 antialiased">
              Building Sustainable Wealth...
            </h3>
            <p className="text-3xl font-normal text-h28_light_green_2 antialiased">
              Diversify and grow your wealth with vetted investment offerings.
            </p>
          </div>
          <div className="-left-32 -bottom-20 relative z-50">
            <img src={authImage} alt="name" className="" />
          </div>
        </div>
        <main className="flex w-full md:w-[60rem] p-5 md:p-5 items-center justify-center relative left-0 md:left-[47rem] overflow-x-hidden">
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default AuthLayout;
