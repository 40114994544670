import React, { useEffect, useRef, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Formik, Form } from "formik";
import * as Yup from "yup";

import About from "../components/modals/steps/About";
import Selfie from "../components/modals/steps/Selfie";
import Identity from "../components/modals/steps/Identity";
import authImage from "../assets/images/Black.svg";
import logo from "../assets/images/Group 1846.svg";
import footer from "../assets/images/basefoot.svg";
import { logout, reset } from "../features/auth/authSlice";
import { getProfile } from "../features/profile/profileSlice";
import { alertActions } from "../app/store";
import { setKYC } from "../features/kyc/kycSlice";
import CompletedForm from "../components/modals/steps/CompletedForm";

const FormTitles = ["Identity", "Address", "Selfy"];

const UserOnboarding = () => {
  //const { user, isError, message } = useSelector((state) => state.auth);

  const [loading, setLoading] = useState(false);
  //const [page, setPage] = useState(0);
  const [activeStep2, setActiveStep2] = useState(0);
  const isLastStep2 = activeStep2 === FormTitles.length - 1;
  const [selfie, setSelfie] = useState("");
  //const [file, setFile] = useState();
  const inputRef = useRef(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { profile } = useSelector((state) => state.profile);

  if (profile?.clientType === "C") {
    return <Navigate to="/onboarding/business" />;
  }

  const initialValues = {
    nin: "",
    city: "",
    state: "",
    title: "",
    gender: "",
    marital_status: "",
    religion: "",
    address: "",
    occupation: "",
    country: "",
    nationality: "",
    bvn: "",
    bank: "",
    account_name: "",
    account_number: "",
    expiry: new Date(),
    id_number: "",
    idType: "",
    governmentId: "",
  };

  const validationSchema = [
    Yup.object().shape({
      expiry: Yup.string().nullable("This field is required!"),
      id_number: Yup.string().required("This field is required!"),
      idType: Yup.string().required("This field is required!"),
      nationality: Yup.string().nullable("This field is required!"),
      bvn: Yup.string().nullable(),
    }),
    Yup.object().shape({
      city: Yup.string().required("This field is required!"),
      state: Yup.string().required("This field is required!"),
      title: Yup.string().required("This field is required!"),
      gender: Yup.string().required("This field is required!"),
      marital_status: Yup.string().required("This field is required!"),
      religion: Yup.string().nullable("This field is required!"),
      address: Yup.string().required("This field is required!"),
      occupation: Yup.string().nullable("This field is required!"),
      country: Yup.string().required("This field is required!"),
    }),
    Yup.object().shape({
      selfie: Yup.string().nullable("This field is required!"),
    }),
  ];

  const currentValidationSchema = validationSchema[activeStep2];

  function _sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  async function _submitForm(values) {
    await _sleep(1000);

    try {
      setLoading(true);

      await dispatch(setKYC(values)).unwrap();

      dispatch(
        alertActions.success({
          message:
            "Your documents have been sent successfully. Please logout and login again.",
          showAfterRedirect: true,
        })
      );
      setLoading(false);
      setActiveStep2(activeStep2 + 1);
    } catch (error) {
      dispatch(alertActions.error(error));
      setLoading(false);
    }

    dispatch(setKYC(values))
      .unwrap()
      .then(() => {})
      .catch((error) => {
        dispatch(alertActions.error(error));
        setLoading(false);
      });
  }

  const _handleSubmit = async (values) => {
    const {
      city,
      state,
      address,
      country,
      expiry,
      id_number,
      idType,
      title,
      gender,
      marital_status,
    } = values;

    const userData = {
      firstname: profile?.firstname,
      surname: profile?.surname,
      transId: profile?.transId,
      clientGroupId: profile?.clientGroupId,
      clientType: profile?.clientType,
      emailAddress: profile?.emailAddress,
      dateOfBirth: profile?.dateOfBirth,
      mobileNumber: profile?.mobileNumber,
      referralCode: profile?.referralCode,
      titleCode: title,
      gender: gender,
      maritalStatus: marital_status,
      city: city,
      state: state,
      address1: address,
      expiry: expiry,
      country: country,
      nationality: country,
      identificationExpiry: expiry,
      identificationNumber: id_number,
      identificationType: idType,
      nin: id_number,
      //file: file,
    };

    if (isLastStep2) {
      console.log(userData);
      _submitForm(userData);
    } else {
      setActiveStep2(activeStep2 + 1);
    }
  };

  function _handleBack() {
    setActiveStep2(activeStep2 - 1);
  }

  const onLogout = () => {
    dispatch(reset());
    dispatch(logout());
    navigate("/login");
  };

  const PageDisplay = ({ errors, touched, values }) => {
    if (activeStep2 === 0) {
      return <Identity errors={errors} touched={touched} values={values} />;
    } else if (activeStep2 === 1) {
      return <About errors={errors} touched={touched} values={values} />;
    } else {
      return <Selfie selfie={selfie} setSelfie={setSelfie} />;
    }
  };

  return (
    <div className="flex w-full justify-between">
      <div className="flex flex-col justify-center w-full">
        <div className="flex flex-col md:flex-row md:px-28 px-5 m-5 justify-between md:items-center ">
          <span className="flex flex-col my-3">
            <h1 className="flex flex-row text-h28_dark_green text-xl font-bold">
              Set up your account
            </h1>
            <p className="text-sm text-gray-600">
              Takes approximately 7 minutes.
            </p>
          </span>
          <span className="flex flex-row items-center justify gap-2 md:gap-4">
            {FormTitles.map((FormTitle) => (
              <h4
                className={` text-md md:text-lg font-semibold   ${
                  activeStep2 === 0 && FormTitle === "Identity"
                    ? "text-h28_dark_green"
                    : activeStep2 === 1 && FormTitle === "Address"
                    ? "text-h28_dark_green"
                    : activeStep2 === 2 && FormTitle === "Selfy"
                    ? "text-h28_dark_green"
                    : "text-gray-400"
                }`}
                key={FormTitle}
              >
                {FormTitle}
              </h4>
            ))}

            <button
              className="ml-10 py-2 px-3 bg-h28_dark_green text-white text-sm font-semibold rounded-md"
              type="button"
              onClick={onLogout}
            >
              Logout
            </button>
          </span>
        </div>

        <div className="w-full bg-gray-200 h-1">
          <div
            className={`${
              activeStep2 === 0
                ? "w-1/3"
                : activeStep2 === 1
                ? "w-2/3"
                : "w-3/3"
            } bg-h28_dark_green h-1`}
          ></div>
        </div>
        <div className="flex flex-col w-full gap-48 md:gap-32">
          <div className="w-full flex flex-col-reverse md:flex-row px-5 md:px-28 gap-96 ">
            <div className="hidden md:flex flex-col gap-20 w-full md:w-2/4 mt-5">
              <div>
                <p className="text-md text-gray-600">
                  For you to continue we will require a few information from you
                  such as your means of identification, utility bill etc.
                </p>
              </div>
              <div className="bg-h28_dark_green rounded-lg text-white p-5">
                <h1 className="font-semibold text-lg">
                  Tips for uploading documents
                </h1>
                <div className="flex flex-col mt-7 text-sm space-y-5">
                  <span className="flex flex-row space-x-2">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM16.78 9.7L11.11 15.37C10.97 15.51 10.78 15.59 10.58 15.59C10.38 15.59 10.19 15.51 10.05 15.37L7.22 12.54C6.93 12.25 6.93 11.77 7.22 11.48C7.51 11.19 7.99 11.19 8.28 11.48L10.58 13.78L15.72 8.64C16.01 8.35 16.49 8.35 16.78 8.64C17.07 8.93 17.07 9.4 16.78 9.7Z"
                        fill="#ffffff"
                      />
                    </svg>
                    <p>Use clear, non-blurry photos</p>
                  </span>
                  <span className="flex flex-row space-x-2">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM16.78 9.7L11.11 15.37C10.97 15.51 10.78 15.59 10.58 15.59C10.38 15.59 10.19 15.51 10.05 15.37L7.22 12.54C6.93 12.25 6.93 11.77 7.22 11.48C7.51 11.19 7.99 11.19 8.28 11.48L10.58 13.78L15.72 8.64C16.01 8.35 16.49 8.35 16.78 8.64C17.07 8.93 17.07 9.4 16.78 9.7Z"
                        fill="#ffffff"
                      />
                    </svg>
                    <p>Make sure ID numbers are visible</p>
                  </span>
                  <span className="flex flex-row space-x-2">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM16.78 9.7L11.11 15.37C10.97 15.51 10.78 15.59 10.58 15.59C10.38 15.59 10.19 15.51 10.05 15.37L7.22 12.54C6.93 12.25 6.93 11.77 7.22 11.48C7.51 11.19 7.99 11.19 8.28 11.48L10.58 13.78L15.72 8.64C16.01 8.35 16.49 8.35 16.78 8.64C17.07 8.93 17.07 9.4 16.78 9.7Z"
                        fill="#ffffff"
                      />
                    </svg>
                    <p>If applicable, make sure your photo is visible</p>
                  </span>
                </div>
              </div>
            </div>
            <div className="flex flex-col w-full md:w-2/4">
              {activeStep2 === FormTitles.length ? (
                <div className="mt-5">
                  <span>
                    <p>
                      Your documents have been sent successfully. Please logout
                      and login again.
                    </p>
                  </span>
                </div>
              ) : (
                <Formik
                  initialValues={initialValues}
                  validationSchema={currentValidationSchema}
                  onSubmit={_handleSubmit}
                >
                  {({ errors, touched, values }) => (
                    <Form id="onboarding">
                      {PageDisplay({ errors, touched, values })}
                      <div className="mt-5 gap-5 flex">
                        {activeStep2 !== 0 && (
                          <button
                            type="button"
                            className="md:w-44 w-28 flex-1 mt-2 p-2.5 border border-gray-200 text-gray-600 rounded-md "
                            onClick={_handleBack}
                          >
                            Back
                          </button>
                        )}

                        <button
                          type="submit"
                          className="md:w-44 w-28 mt-2 p-2.5 h-12 flex-1 text-white rounded-md bg-h28_dark_green"
                        >
                          <span className="text-md font-bold">
                            {isLastStep2 ? "Submit" : "Next"}
                          </span>
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              )}
            </div>
          </div>
          <div className="flex w-full mt-20 justify-end">
            <img className="w-full -top-20" src={footer} alt="footer" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserOnboarding;
