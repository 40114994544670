import axios from "axios";

const BaseUrl = process.env.REACT_APP_BASE_API_URL;

//Get products from api
const getPortfolioBalance = async ({ token }) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(
    `${BaseUrl}/getclientmutualfundonlinebalances`,
    config
  );

  return response.data;
};

const getPortfolioDetail = async ({token, formData}) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  console.log(formData)

  const date = new Date();
  date.setDate(date.getDate() - 1);

  const response = await axios.get(
    `${BaseUrl}/getmutualfundstatement/${formData.id}/${date.toISOString()}`,
    config
  );

  return response.data;

}

const portfolioBalanceService = {
  getPortfolioBalance,
  getPortfolioDetail
};

export default portfolioBalanceService;
