import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import isOnline from "is-online";
import { prettyDate } from "../utils/helpers";

//Icons
import bell from "../assets/icons/notification.svg";
import girl from "../assets/images/girl.svg";
import apps from "../assets/images/apps.svg";

import * as BsIcon from "react-icons/bs";

//Components
import QuickSave from "../components/modals/QuickSave";
import KYC from "../components/modals/KYC";

import { maskPhoneNumber } from "../utils/helpers/index.js";
import QuickWithdraw from "../components/modals/QuickWithdraw";
import {
  getVirtualAccount,
  reset as virtualAccountsReset,
} from "../features/virtualAccount/virtualAccountSlice";
import {
  getWalletBalance,
  reset as walletBalanceReset,
} from "../features/walletBalance/walletBalanceSlice";
import {
  getRecentTransactions,
  reset as recentTransactionsReset,
} from "../features/transactions/transactionsSlice";
import {
  getPortfolioBalance,
  reset as portfolioBalanceReset,
} from "../features/portfolioBalance/portfolioBalanceSlice";

const Dashboard = () => {
  //const balance = 10.0;
  //Quicksave Modal State
  const [isOpen, setIsOpen] = useState(false);
  const [openWithdraw, setOpenWithdraw] = useState(false);
  const [openKYC, setOpenKYC] = useState(false);
  const [kycStatus, setKycStatus] = useState(true);
  const [hideMoney, setHideMoney] = useState(false);

  const { virtual_account, isError, message } = useSelector(
    (state) => state.virtual_account
  );

  const { wallet_balance } = useSelector((state) => state.wallet_balance);

  const { recent_transactions } = useSelector(
    (state) => state.recent_transactions
  );

  const firstFiveTransactions = recent_transactions.slice(0, 5);

  const balanceIndex = wallet_balance && wallet_balance?.find(
    (wallet) => wallet.currencyCode === "NGN"
  );

  const { portfolio_balance } = useSelector((state) => state.portfolio_balance);

  const result = portfolio_balance.reduce(function (acc, obj) {
    return acc + obj.balance;
  }, 0);

  //const [maskedMoney, setMaskedMoney] = useState(100);

  //console.log(balanceIndex?.amount);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPortfolioBalance());
    return () => {
      dispatch(portfolioBalanceReset());
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(getVirtualAccount());
    return () => {
      dispatch(virtualAccountsReset());
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(getWalletBalance());
    return () => {
      dispatch(walletBalanceReset());
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(getRecentTransactions());
    return () => {
      dispatch(recentTransactionsReset());
    };
  }, [dispatch]);

  const hideBalance = () => {
    // const masked = maskPhoneNumber(balanceIndex);
    // setMaskedMoney(masked);
    // setHideMoney(true);
  };

  const unhideBalance = () => {
    // setMaskedMoney(balanceIndex);
    // setHideMoney(false);
  };

  let dollarUSLocale = Intl.NumberFormat("en-US");

  return (
    <>
      <div className="flex-1 flex-col space-y-10">
        {kycStatus === false ? (
          <div
            className="flex flex-row bg-red-100 p-4 rounded-md space-x-2 w-full items-center cursor-pointer"
            onClick={() => setOpenKYC(true)}
          >
            <img src={bell} alt="Notification" />

            <h3 className="text-red-900 font-semibold opacity-80">
              Complete your KYC
            </h3>
          </div>
        ) : (
          <></>
        )}

        {/* //Quick save button */}
        <div className="flex flex-col md:flex-row md:space-x-10 space-x-0  items-center rounded-md">
          <div className="flex flex-col w-full md:w-1/2 h-52 bg-h28_dark_green p-5 rounded-lg">
            <span className="flex flex-row items-center justify-between hover:cursor-pointer">
              <p className="text-white text-sm mr-1 font-semibold">
                Wallet Balance
              </p>
              {hideMoney == false ? (
                <span onClick={hideBalance}>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.2714 9.17834C20.9814 8.71834 20.6714 8.28834 20.3514 7.88834C19.9814 7.41834 19.2814 7.37834 18.8614 7.79834L15.8614 10.7983C16.0814 11.4583 16.1214 12.2183 15.9214 13.0083C15.5714 14.4183 14.4314 15.5583 13.0214 15.9083C12.2314 16.1083 11.4714 16.0683 10.8114 15.8483C10.8114 15.8483 9.38141 17.2783 8.35141 18.3083C7.85141 18.8083 8.01141 19.6883 8.68141 19.9483C9.75141 20.3583 10.8614 20.5683 12.0014 20.5683C13.7814 20.5683 15.5114 20.0483 17.0914 19.0783C18.7014 18.0783 20.1514 16.6083 21.3214 14.7383C22.2714 13.2283 22.2214 10.6883 21.2714 9.17834Z"
                      fill="#fff"
                    />
                    <path
                      d="M14.0206 9.98062L9.98062 14.0206C9.47062 13.5006 9.14062 12.7806 9.14062 12.0006C9.14062 10.4306 10.4206 9.14062 12.0006 9.14062C12.7806 9.14062 13.5006 9.47062 14.0206 9.98062Z"
                      fill="#fff"
                    />
                    <path
                      d="M18.25 5.74969L14.86 9.13969C14.13 8.39969 13.12 7.95969 12 7.95969C9.76 7.95969 7.96 9.76969 7.96 11.9997C7.96 13.1197 8.41 14.1297 9.14 14.8597L5.76 18.2497H5.75C4.64 17.3497 3.62 16.1997 2.75 14.8397C1.75 13.2697 1.75 10.7197 2.75 9.14969C3.91 7.32969 5.33 5.89969 6.91 4.91969C8.49 3.95969 10.22 3.42969 12 3.42969C14.23 3.42969 16.39 4.24969 18.25 5.74969Z"
                      fill="#fff"
                    />
                    <path
                      d="M14.8581 11.9981C14.8581 13.5681 13.5781 14.8581 11.9981 14.8581C11.9381 14.8581 11.8881 14.8581 11.8281 14.8381L14.8381 11.8281C14.8581 11.8881 14.8581 11.9381 14.8581 11.9981Z"
                      fill="#fff"
                    />
                    <path
                      d="M21.7689 2.22891C21.4689 1.92891 20.9789 1.92891 20.6789 2.22891L2.22891 20.6889C1.92891 20.9889 1.92891 21.4789 2.22891 21.7789C2.37891 21.9189 2.56891 21.9989 2.76891 21.9989C2.96891 21.9989 3.15891 21.9189 3.30891 21.7689L21.7689 3.30891C22.0789 3.00891 22.0789 2.52891 21.7689 2.22891Z"
                      fill="#fff"
                    />
                  </svg>
                </span>
              ) : (
                <span onClick={unhideBalance}>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.25 9.14969C18.94 5.51969 15.56 3.42969 12 3.42969C10.22 3.42969 8.49 3.94969 6.91 4.91969C5.33 5.89969 3.91 7.32969 2.75 9.14969C1.75 10.7197 1.75 13.2697 2.75 14.8397C5.06 18.4797 8.44 20.5597 12 20.5597C13.78 20.5597 15.51 20.0397 17.09 19.0697C18.67 18.0897 20.09 16.6597 21.25 14.8397C22.25 13.2797 22.25 10.7197 21.25 9.14969ZM12 16.0397C9.76 16.0397 7.96 14.2297 7.96 11.9997C7.96 9.76969 9.76 7.95969 12 7.95969C14.24 7.95969 16.04 9.76969 16.04 11.9997C16.04 14.2297 14.24 16.0397 12 16.0397Z"
                      fill="#fff"
                    />
                    <path
                      d="M11.9984 9.14062C10.4284 9.14062 9.14844 10.4206 9.14844 12.0006C9.14844 13.5706 10.4284 14.8506 11.9984 14.8506C13.5684 14.8506 14.8584 13.5706 14.8584 12.0006C14.8584 10.4306 13.5684 9.14062 11.9984 9.14062Z"
                      fill="#fff"
                    />
                  </svg>
                </span>
              )}
            </span>
            <span className="flex flex-row mt-5 mb-4">
              <p className="text-xl font-bold text-white">₦</p>
              <h3 className="text-4xl font-bold md:ml-1 ml-0 text-white">
                {balanceIndex?.amount != null
                  ? new Intl.NumberFormat().format(
                      parseFloat(balanceIndex?.amount).toFixed(2)
                    )
                  : parseFloat(0).toFixed(2)}
              </h3>
            </span>
            {/* <span className="text-sm text-white mb-1 mt-0">
              {virtual_account.map((account) => (
                <p key={account.virtualAccountNo}>
                  {account.virtualAccountNo} - Rand Merchant Bank
                </p>
              ))}
            </span> */}
            <div className=" flex flex-row space-x-5 mt-3">
              <button
                className="bg-h28_light_green_2 rounded-lg p-3 w-full"
                onClick={() => setIsOpen(true)}
              >
                <span className="text-h28_dark_green font-semibold tracking-wider">
                  Add Money
                </span>
              </button>
              <button
                className="  bg-white rounded-lg p-3 w-full"
                onClick={() => setOpenWithdraw(true)}
              >
                <span className="text-h28_dark_green font-semibold tracking-wider">
                  Withdraw
                </span>
              </button>
            </div>
          </div>
          <div className="flex flex-col w-full md:w-1/2 h-52 p-5 bg-white rounded-lg">
            <div className="flex flex-row justify-between">
              <span>
                <p className="text-sm text-h28_dark_green font-semibold">
                  My Portfolio
                </p>
              </span>
            </div>
            <div className="flex flex-row justify-between items-center mt-6">
              <span>
                <svg height="100" width="100" viewBox="0 0 20 20">
                  <circle r="10" cx="10" cy="10" fill="#1D4165" />
                  <circle
                    r="5"
                    cx="10"
                    cy="10"
                    fill="transparent"
                    stroke="#44C3D2"
                    strokeWidth="10"
                    strokeDasharray="calc(35 * 31.4 / 100) 31.4"
                    transform="rotate(-90) translate(-20)"
                  />
                  <circle r="5" cx="10" cy="10" fill="#fff" />
                </svg>
              </span>
              <div className="flex flex-col space-y-4">
                <div className="flex flex-row items-center">
                  <div className="w-1.5 h-1.5 rounded-full mr-2 bg-h28_dark_green"></div>
                  <p className="text-sm text-neutral-500">Wallet balance</p>
                </div>
                <div className="flex flex-row items-center">
                  <div className="w-1.5 h-1.5 rounded-full mr-2 bg-h28_light_green_2"></div>
                  <p className="text-sm text-neutral-500">Investment</p>
                </div>
              </div>
              <div className="flex flex-col space-y-4">
                <span>
                  <p className="text-sm text-h28_dark_green font-semibold">
                    ₦{" "}
                    {balanceIndex?.amount != null
                      ? new Intl.NumberFormat().format(
                          parseFloat(balanceIndex?.amount).toFixed(2)
                        )
                      : parseFloat(0.0).toFixed(2)}
                  </p>
                </span>
                <span>
                  <p className="text-sm text-h28_light_green_2 font-semibold">
                    ₦{" "}
                    {result != null
                      ? new Intl.NumberFormat().format(
                          parseFloat(result).toFixed(2)
                        )
                      : parseFloat(0.0).toFixed(2)}
                  </p>
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* //Display cards */}
        <div className="flex flex-col md:flex-row gap-10 overflow-x-auto">
          <div className="flex flex-col w-full md:w-1/2 bg-white rounded-lg p-5">
            <div className="flex flex-row justify-between items-center">
              <h3 className="text-sm font-semibold text-h28_dark_green">
                Get Started
              </h3>
            </div>
            <div className="flex flex-col space-y-5 my-6">
              <Link
                to="/invest"
                className="flex flex-row justify-between items-center"
              >
                <span className="flex flex-row items-center gap-2">
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M24.6372 16.7001C24.3122 16.3251 23.8372 16.1126 23.3497 16.1126H18.8747C16.6747 16.1126 14.8872 14.3251 14.8872 12.1251V7.65006C14.8872 7.16256 14.6747 6.68756 14.2997 6.36256C13.9372 6.03756 13.4372 5.88756 12.9622 5.95006C10.0247 6.32506 7.32469 7.93756 5.56219 10.3626C3.78719 12.8001 3.13719 15.7751 3.69969 18.7501C4.51219 23.0501 7.94969 26.4876 12.2622 27.3001C12.9497 27.4376 13.6372 27.5001 14.3247 27.5001C16.5872 27.5001 18.7747 26.8001 20.6372 25.4376C23.0622 23.6751 24.6747 20.9751 25.0497 18.0376C25.1122 17.5501 24.9622 17.0626 24.6372 16.7001Z"
                      fill="#1D4165"
                    />
                    <path
                      d="M18 13V11"
                      stroke="#1D4165"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M26 13V3"
                      stroke="#44C3D2"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M22 13V7"
                      stroke="#F3E981"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <span>
                    <h4 className="text-md text-h28_dark_green font-bold">
                      Mutual Funds
                    </h4>
                    <p className="text-xs text-neutral-500">
                      Enjoy a variety of plans and earn higher returns.
                    </p>
                  </span>
                </span>

                <span>
                  <svg
                    width="7"
                    height="12"
                    viewBox="0 0 7 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 11L6 6L1 1"
                      stroke="#C4C4C4"
                      stroke-width="1.66667"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
              </Link>
              <hr />
              <Link
                to="/invest"
                className="flex flex-row justify-between items-center"
              >
                <span className="flex flex-row items-center gap-2">
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_70_20)">
                      <path
                        d="M16.0919 29.4253C23.4557 29.4253 29.4253 23.4557 29.4253 16.092C29.4253 8.72816 23.4557 2.75862 16.0919 2.75862C8.72814 2.75862 2.75861 8.72816 2.75861 16.092C2.75861 23.4557 8.72814 29.4253 16.0919 29.4253Z"
                        stroke="#44C3D2"
                        stroke-width="4"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M29.3333 16H24"
                        stroke="#44C3D2"
                        stroke-width="4"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M8.00002 16H2.66669"
                        stroke="#44C3D2"
                        stroke-width="4"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M16 8V2.66667"
                        stroke="#44C3D2"
                        stroke-width="4"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M16 29.3333V24"
                        stroke="#44C3D2"
                        stroke-width="4"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <circle cx="16" cy="16" r="3.31034" fill="#1E4265" />
                    </g>
                    <defs>
                      <clipPath id="clip0_70_20">
                        <rect width="32" height="32" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>

                  <span>
                    <h4 className="text-md text-h28_dark_green font-bold">
                      Investment Plans
                    </h4>
                    <p className="text-xs text-neutral-500 ">
                      Grow a steady investment plan at your own pace.
                    </p>
                  </span>
                </span>

                <span>
                  <svg
                    width="7"
                    height="12"
                    viewBox="0 0 7 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 11L6 6L1 1"
                      stroke="#C4C4C4"
                      stroke-width="1.66667"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
              </Link>
            </div>
          </div>
          <div className="flex flex-col w-full md:w-1/2 bg-white rounded-lg p-5">
            <div className="flex flex-row justify-between items-center mb-1">
              <h3 className="text-sm font-semibold text-h28_dark_green">
                Recent transactions
              </h3>
              <Link
                to="/transactions"
                className="text-sm font-normal text-neutral-500 hover:text-h28_green "
              >
                View all
              </Link>
            </div>
            <hr />
            {firstFiveTransactions.length >= 1 ? (
              <div className="flex flex-col gap-2.5 mt-3">
                {firstFiveTransactions.map((transaction, index) => (
                  <div key={index} className="flex flex-row justify-between">
                    <div className="flex flex-row items-center">
                      {transaction.amount < 0 ? (
                        <>
                          <span className="mr-2 bg-red-50 p-2 rounded-md">
                            <BsIcon.BsArrowUpRightCircle
                              size={25}
                              className="text-red-500"
                            />
                          </span>
                          <span>
                            <h4 className="text-sm font-semibold text-neutral-600">
                              {transaction.description}
                            </h4>
                            <p className="text-xs text-neutral-400">
                              Wallet was debited...
                            </p>
                          </span>
                        </>
                      ) : (
                        <>
                          <span className="mr-2 bg-green-50 p-2 rounded-md">
                            <BsIcon.BsArrowDownLeftCircle
                              size={25}
                              className="text-green-500"
                            />
                          </span>
                          <span>
                            <h4 className="text-sm font-semibold text-neutral-600">
                              {transaction.description}
                            </h4>
                            <p className="text-xs text-neutral-400">
                              Wallet was credited...
                            </p>
                          </span>
                        </>
                      )}
                    </div>
                    <div className="flex flex-row items-center">
                      <span className="flex flex-col justify-end">
                        <h4
                          className={`flex justify-end text-sm font-semibold ${
                            transaction.amount < 0
                              ? "text-red-500"
                              : "text-green-500"
                          }`}
                        >
                          {transaction.currency}{" "}
                          {new Intl.NumberFormat().format(
                            parseFloat(transaction.amount).toFixed(2)
                          )}
                        </h4>
                        <p className="text-xs text-neutral-400">
                          {prettyDate(transaction.valueDate)}
                        </p>
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="flex flex-col justify-center items-center w-full h-full">
                <span>
                  <BsIcon.BsFillCalendarMinusFill
                    size={35}
                    className="text-gray-400"
                  />
                </span>
                <div className="flex items-center ">
                  <h4>No transactions to display yet</h4>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-row space-y-3 bg-white rounded-lg">
          <span className="ml-10">
            <img className="w-28" src={girl} alt="girl" />
          </span>
          <div className="flex flex-row p-5 justify-evenly items-center w-full">
            <div className="flex flex-col">
              <h4 className="text-2xl text-h28_dark_green font-semibold">
                Earn much more...
              </h4>
              <p className="text-xl text-h28_dark_green">even when on the go</p>
            </div>
            <div>
              <h4 className="text-lg font-semibold mb-1">
                Download Our Mobile App
              </h4>
              <span>
                <img className="" src={apps} alt="apps" />
              </span>
            </div>
          </div>
        </div>
      </div>
      {isOpen && <QuickSave open={isOpen} setIsOpen={setIsOpen} />}
      {openWithdraw && (
        <QuickWithdraw open={openWithdraw} setOpenWithdraw={setOpenWithdraw} />
      )}
      {openKYC && <KYC open={openKYC} setOpenKYC={setOpenKYC} />}
    </>
  );
};

export default Dashboard;
