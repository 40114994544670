import axios from "axios";

const BaseUrl = process.env.REACT_APP_BASE_API_URL;

//Get products from api
const getVirtualAccount = async ({ token }) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(`${BaseUrl}/getvirtualaccounts`, config);

  return response.data;
};

const updateVirtualAccount = async ({ token, formData }) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json; charset=utf-8",
    },
  };

  await axios.post(
    `${BaseUrl}/updateindividualclientaccountinfo`,
    formData,
    config
  );

  //return response.data.products;
};

const virtualAccountService = {
  getVirtualAccount,
  updateVirtualAccount,
};

export default virtualAccountService;
