import React, { Fragment, useLayoutEffect, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import * as FaIcon from "react-icons/fa6";

import seclogo from "../../../assets/images/seclogo.svg";
import { getProducts, reset } from "../../../features/products/productSlice";

const MutualFund = () => {
  const dispatch = useDispatch();
  const { products, isError, message } = useSelector((state) => state.products);

  const mutualFunds = products.filter(
    (product) => product.portfolioTypeName === "mutualfund"
  );

  //Load Products
  useLayoutEffect(() => {
    dispatch(getProducts());
    return () => {
      dispatch(reset());
    };
  }, [isError, message, dispatch]);

  return (
    <div className="bg-white rounded-md p-10 space-y-3">
      {mutualFunds?.map((product) => (
        <Fragment key={product.portfolioName}>
          <Link
            to={`/invest/plans/${product.portfolioId}`}
            className="flex flex-row"
          >
            <span className="w-10 h-10 md:w-24 md:h-24 bg-h28_light_green rounded-2xl flex items-center justify-center">
              <svg
                width="60"
                height="60"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M24.6372 16.7001C24.3122 16.3251 23.8372 16.1126 23.3497 16.1126H18.8747C16.6747 16.1126 14.8872 14.3251 14.8872 12.1251V7.65007C14.8872 7.16257 14.6747 6.68757 14.2997 6.36257C13.9372 6.03757 13.4372 5.88757 12.9622 5.95007C10.0247 6.32507 7.32469 7.93757 5.56219 10.3626C3.78719 12.8001 3.13719 15.7751 3.69969 18.7501C4.51219 23.0501 7.94969 26.4876 12.2622 27.3001C12.9497 27.4376 13.6372 27.5001 14.3247 27.5001C16.5872 27.5001 18.7747 26.8001 20.6372 25.4376C23.0622 23.6751 24.6747 20.9751 25.0497 18.0376C25.1122 17.5501 24.9622 17.0626 24.6372 16.7001Z"
                  fill="#1D4165"
                />
                <path
                  d="M26 7.92188V8.70313H18V7.92188H26ZM26 9.60938V10.3906H18V9.60938H26ZM25.3608 5V13H23.8941L20.4039 7.96484H20.3412V13H18.6471V5H20.1333L23.6039 10.0312H23.6706V5H25.3608Z"
                  fill="#44C3D2"
                />
              </svg>
            </span>
            <div className="flex flex-row justify-between w-3/4 ml-5 md:ml-10 items-center">
              <span className="flex flex-col">
                <h3 className="text-sm md:text-xl font-bold text-h28_dark_green">
                  {product.portfolioName}
                </h3>
                <span className="flex flex-row items-center justify-between mt-3">
                  <p className="text-xs md:text-sm">
                    {product.portfolioTypeName === "mutualfund"
                      ? "Mutual Fund"
                      : "Liability"}
                  </p>
                  <span className="w-2 h-2 mx-5 rounded-full bg-h28_dark_green "></span>
                  <span className="flex flex-row items-center space-x-5">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.0011 17.3498C12.9013 17.3498 13.6311 16.6201 13.6311 15.7198C13.6311 14.8196 12.9013 14.0898 12.0011 14.0898C11.1009 14.0898 10.3711 14.8196 10.3711 15.7198C10.3711 16.6201 11.1009 17.3498 12.0011 17.3498Z"
                        fill="#44C3D2"
                      />
                      <path
                        d="M18.28 9.53V8.28C18.28 5.58 17.63 2 12 2C6.37 2 5.72 5.58 5.72 8.28V9.53C2.92 9.88 2 11.3 2 14.79V16.65C2 20.75 3.25 22 7.35 22H16.65C20.75 22 22 20.75 22 16.65V14.79C22 11.3 21.08 9.88 18.28 9.53ZM12 18.74C10.33 18.74 8.98 17.38 8.98 15.72C8.98 14.05 10.34 12.7 12 12.7C13.66 12.7 15.02 14.06 15.02 15.72C15.02 17.39 13.67 18.74 12 18.74ZM7.35 9.44C7.27 9.44 7.2 9.44 7.12 9.44V8.28C7.12 5.35 7.95 3.4 12 3.4C16.05 3.4 16.88 5.35 16.88 8.28V9.45C16.8 9.45 16.73 9.45 16.65 9.45H7.35V9.44Z"
                        fill="#44C3D2"
                      />
                    </svg>
                    <p className="text-xs md:text-sm">{product.minimumHoldingPeriod} Days</p>
                  </span>
                </span>
              </span>
              <span>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.91003 19.9201L15.43 13.4001C16.2 12.6301 16.2 11.3701 15.43 10.6001L8.91003 4.08008"
                    stroke="#44C3D2"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </div>
          </Link>
          <hr className="my-10" />
        </Fragment>
      ))}

      <div className="flex flex-row justify-center items-center space-x-3">
        <img src={seclogo} alt="seclogo" />
        <span className="w-2/3">
          <p className="text-gray-500 text-xs md:text-sm">
            Registered with and Supervised by the Securities and Exchange
            Commision (SEC)
          </p>
        </span>
      </div>
    </div>
  );
};

export default MutualFund;
