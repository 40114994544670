import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import * as FaIcon from "react-icons/fa6";
import { history, prettyDate } from "../utils/helpers";
import { useDispatch, useSelector } from "react-redux";
import * as BsIcon from "react-icons/bs";
import {
  getRecentTransactions,
  reset as recentTransactionsReset,
} from "../features/transactions/transactionsSlice";

const Transactions = () => {
  const dispatch = useDispatch();

  const { recent_transactions } = useSelector(
    (state) => state.recent_transactions
  );

  useEffect(() => {
    dispatch(getRecentTransactions());
    return () => {
      dispatch(recentTransactionsReset());
    };
  }, [dispatch]);
  return (
    <>
      <div>
        <h4 className="text-2xl font-bold text-h28_dark_green mb-5">
          Transactions
        </h4>
      </div>
      <div className="flex-1 flex-col bg-white rounded-lg p-5 md:p-10">
        <div className="flex flex-row mb-5">
          <div
            className="flex flex-row items-center gap-1 text-gray-500 hover:text-h28_dark_green cursor-pointer"
            onClick={() => window.history.back()}
          >
            <span>
              <FaIcon.FaAngleLeft size={13} className="" />
            </span>
            <h4>Back</h4>
          </div>
        </div>
        {recent_transactions.length >= 1 ? (
          <div className="flex flex-col gap-2.5 mt-3">
            {recent_transactions.map((transaction, index) => (
              <div key={index} className="flex flex-row justify-between">
                <div className="flex flex-row items-center">
                  {transaction.amount < 0 ? (
                    <>
                      <span className="mr-2 bg-red-50 p-2 rounded-md">
                        <BsIcon.BsArrowUpRightCircle
                          size={25}
                          className="text-red-500"
                        />
                      </span>
                      <span>
                        <h4 className="text-sm font-semibold text-neutral-600">
                          {transaction.description}
                        </h4>
                        <p className="text-xs text-neutral-400">
                          Wallet was debited...
                        </p>
                      </span>
                    </>
                  ) : (
                    <>
                      <span className="mr-2 bg-green-50 p-2 rounded-md">
                        <BsIcon.BsArrowDownLeftCircle
                          size={25}
                          className="text-green-500"
                        />
                      </span>
                      <span>
                        <h4 className="text-sm font-semibold text-neutral-600">
                          {transaction.description}
                        </h4>
                        <p className="text-xs text-neutral-400">
                          Wallet was credited...
                        </p>
                      </span>
                    </>
                  )}
                </div>
                <div className="flex flex-row items-center">
                  <span className="flex flex-col justify-end">
                    <h4
                      className={`flex justify-end text-sm font-semibold ${
                        transaction.amount < 0
                          ? "text-red-500"
                          : "text-green-500"
                      }`}
                    >
                      {transaction.currency}{" "}
                      {parseFloat(transaction.amount).toFixed(2)}
                    </h4>
                    <p className="text-xs text-neutral-400">
                      {prettyDate(transaction.valueDate)}
                    </p>
                  </span>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="flex flex-col justify-center items-center w-full h-full">
            <span>
              <BsIcon.BsFillCalendarMinusFill
                size={35}
                className="text-gray-400"
              />
            </span>
            <div className="flex items-center ">
              <h4>No transactions to display yet</h4>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Transactions;
