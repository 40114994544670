import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getNok, setNok } from "../../features/nok/nokSlice";
import { alertActions } from "../../app/store";
import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik } from "formik";

const Nok = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { nok } = useSelector((state) => state.nok);
  const nokIndex = Object.keys(nok).length - 1;

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(getNok());
  }, [dispatch]);

  const initialValues = {
    nokFirstname: nok[nokIndex]?.firstname ? nok[nokIndex]?.firstname : "",
    nokLastname: nok[nokIndex]?.surname ? nok[nokIndex]?.surname : "",
    nokPhone: nok[nokIndex]?.telephoneNo ? nok[nokIndex]?.telephoneNo : "",
    nokEmail: nok[nokIndex]?.email ? nok[nokIndex]?.email : "",
    nokGender: nok[nokIndex]?.gender ? nok[nokIndex]?.gender : "",
    nokRelationship: nok[nokIndex]?.relationship
      ? nok[nokIndex]?.relationship
      : "",
  };

  const validationSchema = Yup.object().shape({
    nokFirstname: Yup.string().required("This field is required!"),
    nokLastname: Yup.string().required("This field is required!"),
    nokPhone: Yup.string().required("This field is required!"),
    nokEmail: Yup.string().required("This field is required!"),
    nokGender: Yup.string().required("This field is required!"),
    nokRelationship: Yup.string().required("This field is required!"),
  });

  const { profile, message } = useSelector((state) => state.profile);

  const handleSubmit = async (values) => {
    const {
      nokFirstname,
      nokLastname,
      nokGender,
      nokPhone,
      nokEmail,
      nokRelationship,
    } = values;
    const userData = {
      firstname: nokFirstname,
      surname: nokLastname,
      gender: nokGender,
      telephoneNo: nokPhone,
      email: nokEmail,
      relationship: nokRelationship,
    };

    try {
      setLoading(true);

      await dispatch(setNok(userData)).unwrap();

      dispatch(
        alertActions.success({
          message: "Saved successfully",
          showAfterRedirect: true,
        })
      );
      dispatch(getNok());
      setLoading(false);
    } catch (error) {
      dispatch(alertActions.error(error));
      setLoading(false);
    }
  };
  return (
    <div>
      <div className="flex flex-col mt-5">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched }) => (
            <Form>
              <div className="flex flex-col md:flex-row w-full">
                <div className="flex flex-col w-full md:w-1/3">
                  <h3 className="text-h28_dark_green text-xl font-medium">
                    Fullname
                  </h3>
                  <p className="w-full mt-1 text-sm text-neutral-500 mb-5">
                    Update your next of kin's name
                  </p>
                </div>
                <div className="flex flex-col md:flex-row gap-3 w-full md:w-1/3">
                  <div className="w-full md:w-1/2">
                    <Field
                      type="text"
                      placeholder="Firstname"
                      autoComplete="off"
                      name="nokFirstname"
                      className={`w-full px-4 py-3 mt-1 border text-neutral-500 text-sm rounded-md focus:outline-none ${
                        errors.nokFirstname && touched.nokFirstname
                          ? "border-red-500"
                          : ""
                      } focus:border-h28_dark_green`}
                    />
                    <ErrorMessage
                      name="nokFirstname"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>
                  <div className="w-full md:w-1/2">
                    <Field
                      type="text"
                      placeholder="Lastname"
                      autoComplete="off"
                      name="nokLastname"
                      className={`w-full px-4 py-3 mt-1 border text-neutral-500 text-sm rounded-md focus:outline-none ${
                        errors.nokLastname && touched.nokLastname
                          ? "border-red-500"
                          : ""
                      } focus:border-h28_dark_green`}
                    />
                    <ErrorMessage
                      name="nokLastname"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-col md:flex-row w-full mt-5">
                <div className="flex flex-col w-full md:w-1/3">
                  <h3 className="text-h28_dark_green text-xl font-medium">
                    Email
                  </h3>
                  <p className="mt-1 text-sm text-neutral-500 mb-4">
                    Update your next of kin's email address
                  </p>
                </div>
                <div className="flex flex-col md:flex-row gap-5 w-full md:w-2/3">
                  <div className="w-full md:w-1/2">
                    <Field
                      type="text"
                      placeholder="Firstname"
                      autoComplete="off"
                      name="nokEmail"
                      className={`w-full px-4 py-3 mt-1 border text-neutral-500 text-sm rounded-md focus:outline-none ${
                        errors.nokEmail && touched.nokEmail
                          ? "border-red-500"
                          : ""
                      } focus:border-h28_dark_green`}
                    />
                    <ErrorMessage
                      name="nokEmail"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-col md:flex-row w-full mt-5">
                <div className="flex flex-col w-full md:w-1/3">
                  <h3 className="text-h28_dark_green text-xl font-medium">
                    Phone Number
                  </h3>
                  <p className="mt-1 text-sm text-neutral-500 mb-4">
                    Update your next of kin's number
                  </p>
                </div>
                <div className="flex flex-col md:flex-row gap-5 w-full md:w-2/3">
                  <div className="w-full md:w-1/2">
                    <Field
                      type="text"
                      placeholder="Firstname"
                      autoComplete="off"
                      name="nokPhone"
                      className={`w-full px-4 py-3 mt-1 border text-neutral-500 text-sm rounded-md focus:outline-none ${
                        errors.nokPhone && touched.nokPhone
                          ? "border-red-500"
                          : ""
                      } focus:border-h28_dark_green`}
                    />
                    <ErrorMessage
                      name="nokPhone"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-col md:flex-row w-full mt-5">
                <div className="flex flex-col w-full md:w-1/3">
                  <h3 className="text-h28_dark_green text-xl font-medium">
                    Relationship
                  </h3>
                  <p className="mt-1 text-sm text-neutral-500 mb-4">
                    How how you related?
                  </p>
                </div>
                <div className="flex flex-col md:flex-row gap-5 w-full md:w-2/3">
                  <div className="w-full md:w-1/2">
                    <Field
                      as="select"
                      name="nokRelationship"
                      className={`w-full px-4 py-3 mt-1 border text-neutral-500 text-sm rounded-md focus:outline-none ${
                        errors.nokRelationship && touched.nokRelationship
                          ? "border-red-500"
                          : ""
                      } focus:border-blue-950`}
                    >
                      <option value="">Choose</option>
                      <option value="sibling">Sibling</option>
                      <option value="parent">Parent</option>
                      <option value="spouse">Spouse</option>
                    </Field>

                    <ErrorMessage
                      name="nokRelationship"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-col md:flex-row w-full mt-5">
                <div className="flex flex-col w-full md:w-1/3">
                  <h3 className="text-h28_dark_green text-xl font-medium">
                    Gender
                  </h3>
                  <p className="mt-1 text-sm text-neutral-500 mb-4">
                    How do they identify?
                  </p>
                </div>
                <div className="flex flex-col md:flex-row gap-5 w-full md:w-2/3">
                  <div className="w-full md:w-1/2">
                    <Field
                      as="select"
                      name="nokGender"
                      className={`w-full px-4 py-3 mt-1 border text-neutral-500 text-sm rounded-md focus:outline-none ${
                        errors.nokGender && touched.nokGender
                          ? "border-red-500"
                          : ""
                      } focus:border-blue-950`}
                    >
                      <option value="">Choose</option>
                      <option value="M">Male</option>
                      <option value="F">Female</option>
                      <option value="O">Other</option>
                    </Field>

                    <ErrorMessage
                      name="nokGender"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-col md:flex-row w-full">
                <div className="flex flex-col w-full md:w-1/3"></div>
                <div className="flex flex-col md:flex-row gap-5 w-full md:w-2/3">
                  <div className="w-full md:w-1/2">
                    {loading ? (
                      <button
                        type="submit"
                        className="w-full px-4 py-3 font-bold text-white bg-h28_green rounded-md flex items-center justify-center"
                        disabled={loading}
                      >
                        <span
                          className="mr-5 inline-block h-5 w-5 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                          role="status"
                        >
                          <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                            Loading...
                          </span>
                        </span>
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="w-full px-4 py-3 font-bold text-white bg-h28_dark_green rounded-md"
                      >
                        Save
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Nok;
