import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  getRequiredDocuments,
  reset as getRequiredDocumentsReset,
} from "../../../features/requiredDocuments/requiredDocumentsSlice";
import { useDispatch, useSelector } from "react-redux";

const Directors = () => {
  const [idData, setIdData] = useState(null);
  const [files, setFiles] = useState([]);
  const dispatch = useDispatch();

  const removeFile = (filename) => {
    setFiles(files.filter((file) => file.name !== filename));
  };

  const { user } = useSelector((state) => state.auth);
  const { required_documents } = useSelector(
    (state) => state.required_documents
  );

  const documentIndex = required_documents.find(
    (required_document) =>
      required_document.document === "Means of Identification "
  );

  useEffect(() => {
    dispatch(getRequiredDocuments());
    return () => {
      dispatch(getRequiredDocumentsReset());
    };
  }, [dispatch]);

  const uploadHandler = (event) => {
    // event.preventDefault();
    const file = event.target.files[0];
    if (!file) return;
    file.isUploading = true;
    setFiles([...files, file]);

    // const config = {
    //   headers: {
    //     Authorization: `Bearer ${user.token}`,
    //     "Access-Control-Allow-Origin": "*",
    //     "Content-Type": "multipart/form-data",
    //   },
    // };

    // upload file
    const formData = new FormData();

    formData.append("Files", file);
    formData.append("DocumentId", documentIndex.documentId);
    formData.append("Comment", "Identity Doc");

    //console.log(file);
    console.log(formData);
    axios
      .post(
        "https://xfund.stlassetmgt.com:10443/api/v1/uploadclientdocument",
        formData,
        {
          mode: "cors",
          headers: {
            "Content-Type": "multipart/form-data",
            accept: "*/*",
            Authorization: `Bearer ${user.token}`,
          },
        }
      )
      .then((res) => {
        file.isUploading = false;
        console.log(res.data);
        setFiles([...files, file]);
      })
      .catch((err) => {
        // inform the user
        console.log(err);
        removeFile(file.name);
      });
  };
  return (
    <div>
      <div className="mt-4">
        <label className="block text-h28_dark_green text-ms font-semibold mb-1">
          ID of Authorised Signatories
        </label>

        <input
          type={"file"}
          accept={"image/jpeg,image/png,application/pdf"}
          onChange={uploadHandler}
          className={`w-full px-3 py-2.5 mt-1 border text-black text-sm rounded-md focus:border-nelsa_primary focus:outline-none`}
        />
        {/* <IdUpload /> */}
        {files.map((file, index) => (
          <p key={index}>{file?.name}</p>
        ))}
      </div>
      <div className="mt-4">
        <label className="block text-h28_dark_green text-ms font-semibold mb-1">
          ID of Directors
        </label>

        <input
          type={"file"}
          accept={"image/jpeg,image/png,application/pdf"}
          onChange={uploadHandler}
          className={`w-full px-3 py-2.5 mt-1 border text-black text-sm rounded-md focus:border-nelsa_primary focus:outline-none`}
        />
        {/* <IdUpload /> */}
        {files.map((file, index) => (
          <p key={index}>{file?.name}</p>
        ))}
      </div>
      <div className="mt-4">
        <label className="block text-h28_dark_green text-ms font-semibold mb-1">
          Passport Photograph of Authorized Signatories
        </label>

        <input
          type={"file"}
          accept={"image/jpeg,image/png,application/pdf"}
          onChange={uploadHandler}
          className={`w-full px-3 py-2.5 mt-1 border text-black text-sm rounded-md focus:border-nelsa_primary focus:outline-none`}
        />
        {/* <IdUpload /> */}
        {files.map((file, index) => (
          <p key={index}>{file?.name}</p>
        ))}
      </div>
    </div>
  );
};

export default Directors;
