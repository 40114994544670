import axios from "axios";

const BaseUrl = process.env.REACT_APP_BASE_API_URL;

//Get products from api
const getNok = async ({ token }) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(`${BaseUrl}/getnextofkins`, config);

  //localStorage.setItem("kyc", JSON.stringify(response.data));

  return response.data;
};

const setNok = async ({ token, formData }) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": `*`,
    },
  };
  console.log(config);
  const response = await axios.post(
    `${BaseUrl}/createnextofkin`,
    formData,
    config
  );
  return response.data;
};

const nokService = {
  getNok,
  setNok,
};

export default nokService;
