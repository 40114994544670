import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import virtualAccountService from "./virtualAccountService";

//const banks = JSON.parse(localStorage.getItem("banks"));

const initialState = {
  virtual_account: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

//Get products

export const getVirtualAccount = createAsyncThunk(
  "api/get_virtual_account",
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      //const user_id = thunkAPI.getState().auth.user.user_id;
      return await virtualAccountService.getVirtualAccount({ token });
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateVirtualAccount = createAsyncThunk(
  "api/update_virtual_account",
  async (formData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await virtualAccountService.updateVirtualAccount({
        token,
        formData,
      });
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const virtualAccountSlice = createSlice({
  name: "virtual_account",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder

      .addCase(getVirtualAccount.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getVirtualAccount.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.virtual_account = action.payload;
      })
      .addCase(getVirtualAccount.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.virtual_account = [];
      })
      .addCase(updateVirtualAccount.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateVirtualAccount.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(updateVirtualAccount.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset } = virtualAccountSlice.actions;
export default virtualAccountSlice.reducer;
