import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import * as Icons from "react-icons/fa6";

import { login, registerExistingUser } from "../../../features/auth/authSlice";

import { alertActions } from "../../../app/store";

const ExistingIndividual = () => {
  const initialValues = {
    accountNo: "",
    password: "",
    email: "",
  };

  const validationSchema = Yup.object().shape({
    accountNo: Yup.number().required("This field is required!"),
    email: Yup.string()
      .required("This field is required!")
      .email("Please enter a valid email"),
    password: Yup.string()
      .required("This field is required!")
      .min(6, "Password must be at least 6 characters")
      .matches(
        /(?=.*?[A-Z])/,
        "Password must contain at least one capital letter"
      )
      .matches(/(?=.*?[0-9])/, "Password must contain at least one number")
      .matches(
        /(?=.*?[#?!@$%^&*-])/,
        "Password must contain at least one special character"
      ),
  });

  const [loading, setLoading] = useState(false);
  const [passwordView, setPasswordView] = useState(true);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = async (formValue) => {
    const { accountNo, password, email } = formValue;

    dispatch(alertActions.clear());

    try {
      setLoading(true);

      await dispatch(registerExistingUser({ accountNo, password })).unwrap();
      //("/auth/login/otp");
      localStorage.setItem("email", email);
      navigate("/account/activate");
      dispatch(
        alertActions.success({
          message: "Registration successful",
          showAfterRedirect: true,
        })
      );
      setLoading(false);
    } catch (error) {
      dispatch(alertActions.error(error));
      setLoading(false);
    }
  };
  return (
    <div className="w-[420px] md:w-[440px] p-8 bg-white rounded-lg">
      {/* <img src={logo_2} alt="logo" className="w-10 mb-5" /> */}
      <h3 className="text-2xl font-semibold text-h28_dark_green mb-2">
        Exisiting User Registration.
      </h3>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched }) => (
          <Form>
            <div className="mt-4">
              <div className="mt-4">
                <label className="block text-h28_dark_green text-sm font-semibold">
                  Account Number
                </label>
                <Field
                  placeholder="Account Number"
                  type="text"
                  autoComplete="off"
                  name="accountNo"
                  className={`w-full px-4 py-3 mt-1 border text-neutral-500 text-sm rounded-md focus:outline-none ${
                    errors.accountNo && touched.accountNo
                      ? "border-red-500"
                      : ""
                  } focus:border-h28_dark_green`}
                />
                <ErrorMessage
                  name="accountNo"
                  component="div"
                  className="text-red-500 text-sm"
                />
                {/* {errors.email && (
            <p className="text-red-500 text-sm">{errors.email}</p>
          )} */}
              </div>
              <div className="mt-4">
                <label className="block text-h28_dark_green text-sm font-semibold">
                  Email
                </label>
                <Field
                  type="text"
                  placeholder="Email"
                  autoComplete="off"
                  name="email"
                  className={`w-full px-4 py-3 mt-1 border text-neutral-500 text-sm rounded-md focus:outline-none ${
                    errors.email && touched.email ? "border-red-500" : ""
                  } focus:border-h28_dark_green`}
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="text-red-500 text-sm"
                />
                {/* {errors.email && (
            <p className="text-red-500 text-sm">{errors.email}</p>
          )} */}
              </div>
              <div className="mt-4">
                <label className="block text-h28_dark_green text-sm font-semibold">
                  Password
                </label>
                <div
                  className={`w-full flex flex-row justify-between items-center border text-neutral-500 rounded-md focus:outline-none ${
                    errors.password && touched.password ? "border-red-500" : ""
                  } focus:border-h28_dark_green`}
                >
                  <Field
                    type={passwordView === true ? "password" : "text"}
                    placeholder="Input your yassword"
                    autoComplete="off"
                    name="password"
                    className={`w-full px-3 py-2 text-neutral-500 text-sm m-1 focus:outline-none`}
                  />
                  <span className="px-4">
                    <button
                      type="button"
                      onClick={() => setPasswordView(!passwordView)}
                    >
                      {passwordView === true ? (
                        <Icons.FaEye size={17} />
                      ) : (
                        <Icons.FaEyeSlash size={17} />
                      )}
                    </button>
                  </span>
                </div>
                <ErrorMessage
                  name="password"
                  component="div"
                  className="text-red-500 text-sm"
                />
                {/* {errors.password && (
            <p className="text-red-500 text-sm">{errors.password}</p>
          )} */}
              </div>
              <div className="flex items-baseline justify-between mt-3">
                {loading ? (
                  <button
                    type="submit"
                    className="w-full px-4 py-3 font-bold text-white bg-h28_green rounded-md flex items-center justify-center"
                    disabled={loading}
                  >
                    <span
                      className="mr-5 inline-block h-5 w-5 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                      role="status"
                    >
                      <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                        Loading...
                      </span>
                    </span>
                    Loading...
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="w-full px-4 py-3 font-bold text-white bg-h28_dark_green rounded-md"
                  >
                    Register
                  </button>
                )}
              </div>
              <div className="flex items-center justify-center mt-2">
                <p className="mt-1 text-sm text-neutral-500">
                  Already Registered?
                  <Link to="/login" className="text-h28_dark_green">
                    <span> Sign In</span>
                  </Link>
                </p>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ExistingIndividual;
