import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import kycService from "./kycService";

const kyc = JSON.parse(localStorage.getItem("kyc"));

const initialState = {
  status: kyc ? kyc : [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

//Get products

export const getKYC = createAsyncThunk(
  "api/kyc_status",
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      //const user_id = thunkAPI.getState().auth.user.user_id;
      return await kycService.getKYC({ token });
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const setKYC = createAsyncThunk(
  "api/kyc_save",
  async (formData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await kycService.setKYC({ token, formData });
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const kycSlice = createSlice({
  name: "kyc",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(setKYC.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(setKYC.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.status = action.payload;
      })
      .addCase(setKYC.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.status = null;
      })
      .addCase(getKYC.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getKYC.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.status = action.payload;
      })
      .addCase(getKYC.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.status = [];
      });
  },
});

export const { reset } = kycSlice.actions;
export default kycSlice.reducer;
