import React, { useState } from "react";
import { createPortal } from "react-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { alertActions } from "../../app/store";
import { initiateWithdrawal } from "../../features/withdrawal/withdrawalSlice";

const Withdrawal = ({ setOpenWithdraw, portfolioData }) => {
  const dispatch = useDispatch();
  const initialValues = {
    mutualfundAccountNo: "",
    amount: "",
  };

  const validationSchema = Yup.object().shape({
    mutualfundAccountNo: Yup.string().required("This field is required!"),
    amount: Yup.string().required("This field is required!"),
  });

  const [loading, setLoading] = useState(false);
  const handleSubmit = async (formValue) => {
    const { mutualfundAccountNo, amount } = formValue;

    dispatch(alertActions.clear());

    try {
      setLoading(true);

      await dispatch(
        initiateWithdrawal({ mutualfundAccountNo, amount })
      ).unwrap();
      //localStorage.setItem("email", JSON.stringify(username));
      //navigate("/auth/login/otp");
      dispatch(
        alertActions.success({
          message: "Withdrawal request was successful",
          showAfterRedirect: true,
        })
      );
      setLoading(false);
      setOpenWithdraw(false);
    } catch (error) {
      dispatch(alertActions.error(error));
      setLoading(false);
      setOpenWithdraw(false);
    }
  };
  if (typeof document !== "undefined") {
    return createPortal(
      <>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="fixed inset-0 w-full h-full bg-black opacity-40"></div>
          <div className="flex items-center min-h-screen px-4 py-4">
            <div className="relative w-full max-w-xl p-10 mx-auto bg-white rounded-md shadow-lg font-lexend">
              <div className="w-full">
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {({ errors, touched }) => (
                    <Form>
                      <div className="flex flex-col justify-center">
                        <div className="flex justify-end">
                          <h4
                            className="text-lg font-medium text-gray-500 hover:cursor-pointer"
                            onClick={() => setOpenWithdraw(false)}
                          >
                            X
                          </h4>
                        </div>

                        <div className="mt-5">
                          <label className="block text-h28_dark_green text-ms font-semibold mb-1">
                            Portfolio Product
                          </label>
                          <Field
                            as="select"
                            name="mutualfundAccountNo"
                            className={`w-full px-4 py-3 mt-1 border text-neutral-500 text-sm rounded-md focus:outline-none ${
                              errors.mutualfundAccountNo &&
                              touched.mutualfundAccountNo
                                ? "border-red-500"
                                : ""
                            } focus:border-blue-950`}
                          >
                            <option value="">Choose</option>
                            {portfolioData.map((p_balance, index) => (
                              <option
                                key={index}
                                value={p_balance.mutualfundAccountNo}
                              >
                                {p_balance.portfolio}
                              </option>
                            ))}
                          </Field>

                          <ErrorMessage
                            name="mutualfundAccountNo"
                            component="div"
                            className="text-red-500 text-sm"
                          />
                        </div>

                        <div className="mt-5">
                          <label className="block text-h28_dark_green text-ms font-semibold mb-1">
                            Amount
                          </label>
                          <Field
                            type="number"
                            placeholder="Amount"
                            autoComplete="off"
                            name="amount"
                            className={`w-full px-4 py-3 mt-1 border text-neutral-500 text-sm rounded-md focus:outline-none ${
                              errors.amount && touched.amount
                                ? "border-red-500"
                                : ""
                            } focus:border-h28_dark_green`}
                          />
                          <ErrorMessage
                            name="amount"
                            component="div"
                            className="text-red-500 text-sm"
                          />
                        </div>

                        <div className="items-center gap-2 sm:flex flex-1 mt-5">
                          {loading ? (
                            <button
                              type="submit"
                              className="w-full px-4 py-3 font-bold text-white bg-h28_green rounded-md flex items-center justify-center"
                              disabled={loading}
                            >
                              <span
                                className="mr-5 inline-block h-5 w-5 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                                role="status"
                              >
                                <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                                  Loading...
                                </span>
                              </span>
                              Loading...
                            </button>
                          ) : (
                            <button
                              type="submit"
                              className="w-full px-4 py-3 font-bold text-white bg-h28_dark_green rounded-md"
                            >
                              Submit
                            </button>
                          )}
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </>,
      document.body
    );
  } else {
    return null;
  }
};

export default Withdrawal;
