import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import { logout, reset } from "../features/auth/authSlice";
import { reset as resetKyc, setKYC } from "../features/kyc/kycSlice";
import { alertActions } from "../app/store";
import { getProfile } from "../features/profile/profileSlice";
import RegistrationDocs from "../components/modals/steps/RegistrationDocs";
import Directors from "../components/modals/steps/Directors";

const FormTitles = ["Registration Docs", "Directors"];

const BusinessOnboarding = () => {
  const { user, isError, message } = useSelector((state) => state.auth);
  const { profile } = useSelector((state) => state.profile);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [activeStep, setActiveStep] = useState(0);
  const isLastStep = activeStep === FormTitles.length - 1;
  const [selfie, setSelfie] = useState("");
  const [file, setFile] = useState();
  const inputRef = useRef(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProfile());
    return () => {
      dispatch(reset());
    };
  }, [dispatch]);

  const initialValues = {
    nin: "",
    city: "",
    state: "",
    title: "",
    gender: "",
    marital_status: "",
    religion: "",
    address: "",
    occupation: "",
    country: "",
    nationality: "",
    bvn: "",
    bank: "",
    account_name: "",
    account_number: "",
    expiry: "",
    id_number: "",
    idType: "",
    governmentId: "",
  };

  const validationSchema = [
    Yup.object().shape({
      expiry: Yup.string().nullable("This field is required!"),
    }),
    Yup.object().shape({
      city: Yup.string().nullable("This field is required!"),
    }),
    Yup.object().shape({
      selfie: Yup.string().nullable("This field is required!"),
    }),
  ];

  const currentValidationSchema = validationSchema[activeStep];

  function _sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async function _submitForm(values) {
    await _sleep(1000);
    //alert(JSON.stringify(values, null, 2));
    dispatch(setKYC(values))
      .unwrap()
      .then(() => {
        navigate("/");
        window.location.reload();
      })
      .catch((error) => {
        dispatch(alertActions.error(error));
        setLoading(false);
      });

    setActiveStep(activeStep + 1);
  }

  const _handleSubmit = async (values) => {
    // const {
    //   city,
    //   state,
    //   title,
    //   gender,
    //   marital_status,
    //   religion,
    //   address,
    //   occupation,
    //   country,
    //   nationality,
    //   bvn,
    //   bank,
    //   account_name,
    //   account_number,
    //   expiry,
    //   id_number,
    //   idType,
    // } = values;

    const userData = {};

    if (isLastStep) {
      _submitForm(userData);
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  function _handleBack() {
    setActiveStep(activeStep - 1);
  }

  const onLogout = () => {
    dispatch(reset());
    dispatch(logout());
    navigate("/login");
  };

  const PageDisplay = ({ errors, touched, values }) => {
    if (activeStep === 0) {
      return <RegistrationDocs />;
    } else {
      return <Directors selfie={selfie} setSelfie={setSelfie} />;
    }
  };

  return (
    <div className="flex w-full">
      <div className="flex flex-col justify-center w-full">
        <div className="flex flex-col md:flex-row md:px-28 px-5 mb-5 justify-between md:items-center ">
          <span className="flex flex-col my-5">
            <h1 className="text-h28_dark_green text-xl font-bold">
              Set up your 1Click account
            </h1>
            <p className="text-sm text-gray-600">
              Takes approximately 7 minutes.
            </p>
          </span>
          <span className="flex flex-row md:items-center gap-2 md:gap-4">
            {FormTitles.map((FormTitle) => (
              <h4
                className={` text-md md:text-lg font-semibold   ${
                  activeStep === 0 && FormTitle === "Registration Docs"
                    ? "text-h28_dark_green"
                    : activeStep === 1 && FormTitle === "Directors"
                    ? "text-h28_dark_green"
                    : "text-gray-400"
                }`}
                key={FormTitle}
              >
                {FormTitle}
              </h4>
            ))}

            <button
              className="ml-10 py-2 px-3 bg-h28_dark_green text-white text-sm font-semibold rounded-md"
              type="button"
              onClick={onLogout}
            >
              Logout
            </button>
          </span>
        </div>

        <div className="w-full bg-gray-200 h-1">
          <div
            className={`${
              activeStep === 0 ? "w-1/2" : activeStep === 1 ? "w-2/2" : "w-2/2"
            } bg-h28_dark_green h-1`}
          ></div>
        </div>
        <div className="w-full flex flex-col-reverse md:flex-row md:px-28 px-5 gap-96">
          <div className="flex flex-col justify-between h-96 md:h-[720px] w-full md:w-2/4 mt-5">
            <div>
              <p className="text-md text-gray-600">
                For you to continue we will require a few information from you
                such as your means of identification, utility bill etc.
              </p>
            </div>
            <div className="bg-h28_dark_green rounded-lg text-white p-5">
              <h1 className="font-semibold text-lg">
                Tips for uploading documents
              </h1>
              <div className="flex flex-col mt-7 text-sm space-y-5">
                <span className="flex flex-row space-x-2">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM16.78 9.7L11.11 15.37C10.97 15.51 10.78 15.59 10.58 15.59C10.38 15.59 10.19 15.51 10.05 15.37L7.22 12.54C6.93 12.25 6.93 11.77 7.22 11.48C7.51 11.19 7.99 11.19 8.28 11.48L10.58 13.78L15.72 8.64C16.01 8.35 16.49 8.35 16.78 8.64C17.07 8.93 17.07 9.4 16.78 9.7Z"
                      fill="#ffffff"
                    />
                  </svg>
                  <p>Use clear, non-blurry photos</p>
                </span>
                <span className="flex flex-row space-x-2">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM16.78 9.7L11.11 15.37C10.97 15.51 10.78 15.59 10.58 15.59C10.38 15.59 10.19 15.51 10.05 15.37L7.22 12.54C6.93 12.25 6.93 11.77 7.22 11.48C7.51 11.19 7.99 11.19 8.28 11.48L10.58 13.78L15.72 8.64C16.01 8.35 16.49 8.35 16.78 8.64C17.07 8.93 17.07 9.4 16.78 9.7Z"
                      fill="#ffffff"
                    />
                  </svg>
                  <p>Make sure ID numbers are visible</p>
                </span>
                <span className="flex flex-row space-x-2">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM16.78 9.7L11.11 15.37C10.97 15.51 10.78 15.59 10.58 15.59C10.38 15.59 10.19 15.51 10.05 15.37L7.22 12.54C6.93 12.25 6.93 11.77 7.22 11.48C7.51 11.19 7.99 11.19 8.28 11.48L10.58 13.78L15.72 8.64C16.01 8.35 16.49 8.35 16.78 8.64C17.07 8.93 17.07 9.4 16.78 9.7Z"
                      fill="#ffffff"
                    />
                  </svg>
                  <p>If applicable, make sure your photo is visible</p>
                </span>
              </div>
            </div>
          </div>
          <div className="flex flex-col w-full md:w-2/4">
            {activeStep === FormTitles.length ? (
              <div className="flex items-center justify-center mt-10">
                <span>
                  <p>Your documents have been sent successfully.</p>
                </span>
              </div>
            ) : (
              <Formik
                initialValues={initialValues}
                validationSchema={currentValidationSchema}
                onSubmit={_handleSubmit}
              >
                {({ errors, touched, values }) => (
                  <Form id="onboarding">
                    {PageDisplay({ errors, touched, values })}
                    <div className="mt-5 gap-5 flex">
                      {activeStep !== 0 && (
                        <button
                          type="button"
                          className="md:w-44 w-28 flex-1 mt-2 p-2.5 border border-gray-200 text-gray-600 rounded-md "
                          onClick={_handleBack}
                        >
                          Back
                        </button>
                      )}

                      <button
                        type="submit"
                        className="md:w-44 w-28 mt-2 p-2.5 h-12 flex-1 text-white rounded-md bg-h28_dark_green"
                      >
                        <span className="text-md font-bold">
                          {isLastStep ? "Submit" : "Next"}
                        </span>
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessOnboarding;
