import React, { useEffect, useLayoutEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import * as FaIcon from "react-icons/fa6";

import { getProducts, reset } from "../../features/products/productSlice";
import {
  getWalletBalance,
  reset as walletBalanceReset,
} from "../../features/walletBalance/walletBalanceSlice";
import {
  getPortfolioBalance,
  reset as portfolioBalanceReset,
} from "../../features/portfolioBalance/portfolioBalanceSlice";

const Details = () => {
  //const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const { products } = useSelector((state) => state.products);

  const itemIndex = products.find((product) => product.portfolioId == id);
  //console.log(id);
  const { wallet_balance } = useSelector((state) => state.wallet_balance);

  const balanceIndex = wallet_balance.find(
    (wallet) => wallet.currencyCode === "NGN"
  );

  const { portfolio_balance } = useSelector((state) => state.portfolio_balance);

  const result = portfolio_balance.reduce(function (acc, obj) {
    return acc + obj.balance;
  }, 0);

  useLayoutEffect(() => {
    dispatch(getProducts());
    return () => {
      dispatch(reset());
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(getWalletBalance());
    return () => {
      dispatch(walletBalanceReset());
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(getPortfolioBalance());
    return () => {
      dispatch(portfolioBalanceReset());
    };
  }, [dispatch]);

  const initialValues = {
    amount: "",
    portfolioId: "",
  };

  const validationSchema = Yup.object().shape({
    amount: Yup.number()
      .min(
        itemIndex?.minimumInvestment,
        `Amount must be at least ${itemIndex?.minimumInvestment.toLocaleString(
          "en-US"
        )}`
      )
      .required("Amount is required!"),
  });

  const handleSubmit = async (formValue) => {
    const { amount } = formValue;
    navigate("/invest/review", {
      state: {
        amount: amount,
        name: itemIndex?.portfolioName,
        portfolioId: itemIndex?.portfolioId,
        pricePerUnit: itemIndex?.offerPrice,
      },
    });
  };

  return (
    <>
      <div className="flex-1 flex-col bg-white rounded-lg p-5 md:p-10">
        <div className="flex flex-row mb-5">
          <Link
            className="flex flex-row items-center gap-1 text-gray-500 hover:text-h28_dark_green"
            to="/invest"
          >
            <span>
              <FaIcon.FaAngleLeft size={13} className="" />
            </span>
            <h4>Back</h4>
          </Link>
        </div>
        <div className="flex flex-row justify-between mb-3 items-center">
          <div className="flex flex-row justify-between">
            <div>
              <h3 className="text-2xl font-bold text-h28_dark_green">
                {itemIndex?.portfolioName}
              </h3>
              <p className="text-sm font-semibold text-h28_dark_green">
                {itemIndex?.portfolioTypeName === "mutualfund"
                  ? "Mutual Fund"
                  : "Liability"}
                {" - "}
                {itemIndex?.productMandate}
              </p>
            </div>
          </div>
          <span>
            <svg
              width="60"
              height="60"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M24.6372 16.7001C24.3122 16.3251 23.8372 16.1126 23.3497 16.1126H18.8747C16.6747 16.1126 14.8872 14.3251 14.8872 12.1251V7.65007C14.8872 7.16257 14.6747 6.68757 14.2997 6.36257C13.9372 6.03757 13.4372 5.88757 12.9622 5.95007C10.0247 6.32507 7.32469 7.93757 5.56219 10.3626C3.78719 12.8001 3.13719 15.7751 3.69969 18.7501C4.51219 23.0501 7.94969 26.4876 12.2622 27.3001C12.9497 27.4376 13.6372 27.5001 14.3247 27.5001C16.5872 27.5001 18.7747 26.8001 20.6372 25.4376C23.0622 23.6751 24.6747 20.9751 25.0497 18.0376C25.1122 17.5501 24.9622 17.0626 24.6372 16.7001Z"
                fill="#1D4165"
              />
              <path
                d="M26 7.92188V8.70313H18V7.92188H26ZM26 9.60938V10.3906H18V9.60938H26ZM25.3608 5V13H23.8941L20.4039 7.96484H20.3412V13H18.6471V5H20.1333L23.6039 10.0312H23.6706V5H25.3608Z"
                fill="#44C3D2"
              />
            </svg>
          </span>
        </div>
        <div className="flex flex-row mb-2">
          <div>
            <h2 className="text-4xl text-green-700 font-bold">
              {itemIndex?.return != null
                ? parseFloat(itemIndex?.return).toFixed(2)
                : parseFloat(0).toFixed(2)}
              %
            </h2>
            <p className="text-sm text-h28_dark_green">Annual Returns</p>
          </div>
        </div>
        <div className="mt-4">
          <h4 className="text-md mb-2 text-h28_dark_green font-semibold">
            ₦
            {itemIndex?.offerPrice != null
              ? parseFloat(itemIndex?.offerPrice).toFixed(2)
              : parseFloat(0).toFixed(2)}{" "}
            per unit
          </h4>
          <div className="w-full">
            <p className="text-md text-h28_dark_green">
              {itemIndex?.productMandate}
            </p>
            <p className="text-md text-h28_dark_green underline">Learn more</p>
          </div>
        </div>
        <div className="flex flex-row justify-between items-center mt-5 bg-h28_dark_green rounded-lg p-5">
          <span>
            <h4 className="text-md text-white">Current Balance</h4>
            <span className="flex flex-row mt-1">
              <p className="text-xl font-bold text-white">₦</p>
              <h3 className="text-2xl font-bold md:ml-1 ml-0 text-white">
                {result != null
                  ? new Intl.NumberFormat().format(
                      parseFloat(result).toFixed(2)
                    )
                  : parseFloat(0).toFixed(2)}
              </h3>
            </span>
          </span>
          <span>
            <svg
              width="120"
              height="60"
              viewBox="0 0 107 81"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M30.6739 80.1661C47.6146 80.1661 61.3478 66.433 61.3478 49.4922C61.3478 32.5515 47.6146 18.8184 30.6739 18.8184C13.7332 18.8184 0 32.5515 0 49.4922C0 66.433 13.7332 80.1661 30.6739 80.1661ZM30.4858 74.5206C44.4125 74.5206 55.7024 63.2308 55.7024 49.304C55.7024 35.3773 44.4125 24.0875 30.4858 24.0875C16.5591 24.0875 5.26924 35.3773 5.26924 49.304C5.26924 63.2308 16.5591 74.5206 30.4858 74.5206Z"
                fill="#ffffff"
                fillOpacity="0.2"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M53.2559 61.3478C70.1966 61.3478 83.9298 47.6146 83.9298 30.6739C83.9298 13.7332 70.1966 0 53.2559 0C36.3152 0 22.582 13.7332 22.582 30.6739C22.582 47.6146 36.3152 61.3478 53.2559 61.3478ZM53.0677 55.7023C66.9944 55.7023 78.2842 44.4124 78.2842 30.4857C78.2842 16.559 66.9944 5.26911 53.0677 5.26911C39.1409 5.26911 27.8511 16.559 27.8511 30.4857C27.8511 44.4124 39.1409 55.7023 53.0677 55.7023Z"
                fill="#ffffff"
                fillOpacity="0.2"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M75.8379 80.1661C92.7787 80.1661 106.512 66.433 106.512 49.4922C106.512 32.5515 92.7787 18.8184 75.8379 18.8184C58.8972 18.8184 45.1641 32.5515 45.1641 49.4922C45.1641 66.433 58.8972 80.1661 75.8379 80.1661ZM75.65 74.5206C89.5767 74.5206 100.867 63.2308 100.867 49.304C100.867 35.3773 89.5767 24.0875 75.65 24.0875C61.7232 24.0875 50.4334 35.3773 50.4334 49.304C50.4334 63.2308 61.7232 74.5206 75.65 74.5206Z"
                fill="#ffffff"
                fillOpacity="0.2"
              />
            </svg>
          </span>
        </div>
      </div>
      <div className="flex flex-col bg-white rounded-lg p-5 md:p-10 mt-5">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched }) => (
            <Form>
              <div className="w-full mb-5">
                <p className="text-h28_dark_green mb-1">
                  Amount to invest (Min. ₦
                  {itemIndex?.minimumInvestment != null
                    ? new Intl.NumberFormat().format(
                        parseFloat(itemIndex?.minimumInvestment).toFixed(2)
                      )
                    : parseFloat(0).toFixed(2)}
                  )
                </p>
                <span className="flex flex-row px-3 py-3.5 border-2 border-neutral-300 w-full rounded-md mt-2 space-x-3">
                  <p className="text-lg font-bold text-h28_dark_green">₦</p>

                  <Field
                    type="number"
                    autoComplete="off"
                    name="amount"
                    className={`w-full text-lg font-bold text-h28_dark_green focus:outline-none ${
                      errors.amount && touched.amount ? "border-red-500" : ""
                    } focus:border-h28_dark_green`}
                  />
                </span>
                <ErrorMessage
                  name="amount"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>
              <button className="w-full px-2 py-3.5 bg-h28_dark_green rounded-md text-lg text-white font-bold">
                Invest now
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default Details;
