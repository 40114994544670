import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//components
import Dashboard from "./pages/Dashboard";
import Settings from "./pages/Settings";
import Login from "./components/auth/login/Login";
import Register from "./components/auth/register/Register";
import MainLayout from "./layouts/MainLayout";
import AuthLayout from "./layouts/AuthLayout";
import Protected from "./Protected";
import Payments from "./pages/Payments";
import ForgotPassword from "./components/auth/password/ForgotPassword";
import Plans from "./pages/Plans";
import NewPlan from "./pages/NewPlan";
import Portfolio from "./pages/Portfolio";
import Invest from "./pages/Invest";
import Activate from "./components/auth/account/Activate";
import Otp from "./components/auth/account/Otp";
import Alert from "./components/common/Alert";
import { history } from "./utils/helpers";
import Onboarding from "./pages/Onboarding";
import OnboardingLayout from "./layouts/OnboardingLayout";
import Details from "./components/invest/Details";
import PersonalDetails from "./pages/settings/PersonalDetails";
import BankDetails from "./pages/settings/BankDetails";
import Review from "./components/invest/Review";
import UserOnboarding from "./pages/UserOnboarding";
import BusinessOnboarding from "./pages/BusinessOnboarding";
import ExistingIndividual from "./components/auth/register/ExistingIndividual";
import ExistingCorporate from "./components/auth/register/ExistingCorporate";
import Transactions from "./pages/Transactions";
import PortfolioDetails from "./pages/PortfolioDetails";
import ResetPassword from "./components/auth/password/ResetPassword";

function App() {
  return (
    <BrowserRouter>
      <ToastContainer />
      <Alert />

      <Routes>
        <Route path="/" element={<MainLayout />}>
          <Route
            index
            element={
              <Protected>
                <Dashboard />
              </Protected>
            }
          />

          <Route
            path="/portfolio"
            element={
              <Protected>
                <Portfolio />
              </Protected>
            }
          />
          <Route
            path="/portfolio/:id"
            element={
              <Protected>
                <PortfolioDetails />
              </Protected>
            }
          />
          <Route
            path="/invest"
            element={
              <Protected>
                <Invest />
              </Protected>
            }
          />
          <Route
            path="/transactions"
            element={
              <Protected>
                <Transactions />
              </Protected>
            }
          />
          <Route
            path="/settings"
            element={
              <Protected>
                <Settings />
              </Protected>
            }
          />
          <Route
            path="/settings/details"
            element={
              <Protected>
                <PersonalDetails />
              </Protected>
            }
          />
          <Route
            path="/settings/bank"
            element={
              <Protected>
                <BankDetails />
              </Protected>
            }
          />
          <Route
            path="/payments"
            element={
              <Protected>
                <Payments />
              </Protected>
            }
          />
          <Route
            path="/plans"
            element={
              <Protected>
                <Plans />
              </Protected>
            }
          />
          <Route
            path="/invest/plans/:id"
            element={
              <Protected>
                <Details />
              </Protected>
            }
          />
          <Route
            path="/invest/review"
            element={
              <Protected>
                <Review />
              </Protected>
            }
          />
          <Route
            path="/plan/create"
            element={
              <Protected>
                <NewPlan />
              </Protected>
            }
          />
        </Route>
        <Route element={<OnboardingLayout />}>
          <Route
            path="/onboarding"
            element={
              <Protected>
                <UserOnboarding />
              </Protected>
            }
          />
          <Route
            path="/onboarding/user"
            element={
              <Protected>
                <UserOnboarding />
              </Protected>
            }
          />
          <Route
            path="/onboarding/business"
            element={
              <Protected>
                <BusinessOnboarding />
              </Protected>
            }
          />
        </Route>

        <Route element={<AuthLayout />}>
          <Route path="/login" element={<Login />} />
          <Route path="/login/otp" element={<Otp />} />
          <Route path="/register" element={<Register />} />
          <Route path="/existing/individual" element={<ExistingIndividual />} />
          <Route path="/existing/company" element={<ExistingCorporate />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/passwordreset/:token" element={<ResetPassword />} />
          <Route path="/account/activate" element={<Activate />} />
        </Route>
        <Route path="*" element={<p>There's nothing here: 404!</p>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
