import axios from "axios";

const BaseUrl = process.env.REACT_APP_BASE_API_URL;

//Get products from api
const initiateWithdrawal = async ({ token, formData }) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    `${BaseUrl}/mutualfundredemption`,
    formData,
    config
  );

  return response.data;
};

const walletWithdrawal = async ({ token, formData }) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(`${BaseUrl}/debitwallet`, formData, config);

  return response.data;
};

const withdrawalService = {
  initiateWithdrawal,
  walletWithdrawal,
};

export default withdrawalService;
