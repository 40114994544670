import React, { useLayoutEffect } from "react";
import { Link } from "react-router-dom";
import seclogo from "../../../assets/images/seclogo.svg";
import { useDispatch, useSelector } from "react-redux";
import { getProducts, reset } from "../../../features/products/productSlice";

const InvestmentPlans = () => {
  const dispatch = useDispatch();
  const { products, isError, message } = useSelector((state) => state.products);

  const mutualFunds = products.filter(
    (product) => product.portfolioTypeName === "liability"
  );
  useLayoutEffect(() => {
    dispatch(getProducts());
    return () => {
      dispatch(reset());
    };
  }, [isError, message, dispatch]);
  return (
    <div className="bg-white rounded-md p-10 space-y-3">
      {/* <Link to="/plan/create" className="flex flex-row">
        <span className="w-24 h-24 bg-h28_light_green rounded-2xl flex items-center justify-center">
          <svg
            width="60"
            height="60"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M33 18H27"
              stroke="#44C3D2"
              strokeWidth="4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9 18H3"
              stroke="#44C3D2"
              strokeWidth="4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M18 9V3"
              stroke="#44C3D2"
              strokeWidth="4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M18 33V27"
              stroke="#44C3D2"
              strokeWidth="4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M18.1035 33.1035C26.3877 33.1035 33.1035 26.3877 33.1035 18.1035C33.1035 9.81918 26.3877 3.10345 18.1035 3.10345C9.81918 3.10345 3.10345 9.81918 3.10345 18.1035C3.10345 26.3877 9.81918 33.1035 18.1035 33.1035Z"
              stroke="#44C3D2"
              strokeWidth="4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <circle cx="18" cy="18" r="3.72414" fill="#1E4265" />
          </svg>
        </span>
        <div className="flex flex-row justify-between w-3/4 ml-10 items-center">
          <span className="flex flex-col">
            <h3 className="text-xl font-bold text-h28_dark_green">
              1Click Target Plan
            </h3>
            <span className="flex flex-row items-center justify-between mt-3 space-x-3">
              <p>Investment Plan</p>
              <span className="w-2 h-2 rounded-full bg-h28_dark_green "></span>
              <span className="flex flex-row items-center space-x-5">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.0011 17.3498C12.9013 17.3498 13.6311 16.6201 13.6311 15.7198C13.6311 14.8196 12.9013 14.0898 12.0011 14.0898C11.1009 14.0898 10.3711 14.8196 10.3711 15.7198C10.3711 16.6201 11.1009 17.3498 12.0011 17.3498Z"
                    fill="#44C3D2"
                  />
                  <path
                    d="M18.28 9.53V8.28C18.28 5.58 17.63 2 12 2C6.37 2 5.72 5.58 5.72 8.28V9.53C2.92 9.88 2 11.3 2 14.79V16.65C2 20.75 3.25 22 7.35 22H16.65C20.75 22 22 20.75 22 16.65V14.79C22 11.3 21.08 9.88 18.28 9.53ZM12 18.74C10.33 18.74 8.98 17.38 8.98 15.72C8.98 14.05 10.34 12.7 12 12.7C13.66 12.7 15.02 14.06 15.02 15.72C15.02 17.39 13.67 18.74 12 18.74ZM7.35 9.44C7.27 9.44 7.2 9.44 7.12 9.44V8.28C7.12 5.35 7.95 3.4 12 3.4C16.05 3.4 16.88 5.35 16.88 8.28V9.45C16.8 9.45 16.73 9.45 16.65 9.45H7.35V9.44Z"
                    fill="#44C3D2"
                  />
                </svg>
                <p>30 days</p>
              </span>
            </span>
          </span>
          <span>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.91003 19.9201L15.43 13.4001C16.2 12.6301 16.2 11.3701 15.43 10.6001L8.91003 4.08008"
                stroke="#44C3D2"
                strokeWidth="2"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
        </div>
      </Link>
      <hr /> */}
      {mutualFunds.map((product) => (
        <Link
          to={`/invest/plans/${product.portfolioId}`}
          className="flex flex-row"
        >
          <span className="w-24 h-24 bg-h28_light_green rounded-2xl flex items-center justify-center">
            <svg
              width="60"
              height="60"
              viewBox="0 0 36 36"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18 33C26.2843 33 33 26.2843 33 18C33 9.71573 26.2843 3 18 3C9.71573 3 3 9.71573 3 18C3 26.2843 9.71573 33 18 33Z"
                stroke="#1D4165"
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M18 27C22.9706 27 27 22.9706 27 18C27 13.0294 22.9706 9 18 9C13.0294 9 9 13.0294 9 18C9 22.9706 13.0294 27 18 27Z"
                stroke="#44C3D2"
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M18 21C19.6569 21 21 19.6569 21 18C21 16.3431 19.6569 15 18 15C16.3431 15 15 16.3431 15 18C15 19.6569 16.3431 21 18 21Z"
                stroke="#F3E981"
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <div className="flex flex-row justify-between w-3/4 ml-10 items-center">
            <span className="flex flex-col">
              <h3 className="text-xl font-bold text-h28_dark_green">
                {product.portfolioName}
              </h3>
              <span className="flex flex-row items-center justify-between mt-3">
                <p>
                  {product.portfolioTypeName === "mutualfund"
                    ? "Mutual Fund"
                    : "Investment Plan"}
                </p>
                <span className="w-2 h-2 rounded-full bg-h28_dark_green "></span>
                <span className="flex flex-row items-center space-x-5">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.0011 17.3498C12.9013 17.3498 13.6311 16.6201 13.6311 15.7198C13.6311 14.8196 12.9013 14.0898 12.0011 14.0898C11.1009 14.0898 10.3711 14.8196 10.3711 15.7198C10.3711 16.6201 11.1009 17.3498 12.0011 17.3498Z"
                      fill="#44C3D2"
                    />
                    <path
                      d="M18.28 9.53V8.28C18.28 5.58 17.63 2 12 2C6.37 2 5.72 5.58 5.72 8.28V9.53C2.92 9.88 2 11.3 2 14.79V16.65C2 20.75 3.25 22 7.35 22H16.65C20.75 22 22 20.75 22 16.65V14.79C22 11.3 21.08 9.88 18.28 9.53ZM12 18.74C10.33 18.74 8.98 17.38 8.98 15.72C8.98 14.05 10.34 12.7 12 12.7C13.66 12.7 15.02 14.06 15.02 15.72C15.02 17.39 13.67 18.74 12 18.74ZM7.35 9.44C7.27 9.44 7.2 9.44 7.12 9.44V8.28C7.12 5.35 7.95 3.4 12 3.4C16.05 3.4 16.88 5.35 16.88 8.28V9.45C16.8 9.45 16.73 9.45 16.65 9.45H7.35V9.44Z"
                      fill="#44C3D2"
                    />
                  </svg>
                  <p>{product.minimumHoldingPeriod} Days</p>
                </span>
              </span>
            </span>
            <span>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.91003 19.9201L15.43 13.4001C16.2 12.6301 16.2 11.3701 15.43 10.6001L8.91003 4.08008"
                  stroke="#44C3D2"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
          </div>
        </Link>
      ))}
      <hr />
      <div className="flex flex-row justify-center items-center space-x-3">
        <img src={seclogo} alt="seclogo" />
        <span className="w-2/3">
          <p className="text-gray-500">
            Registered with and Supervised by the Securities and Exchange
            Commision (SEC)
          </p>
        </span>
      </div>
    </div>
  );
};

export default InvestmentPlans;
