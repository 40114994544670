import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { alertActions } from "../../../app/store";
import { forgotPassword } from "../../../features/forgotPassword/forgotPasswordSlice";

const ForgotPassword = () => {
  const initialValues = {
    username: "",
  };

  const validationSchema = Yup.object().shape({
    username: Yup.string()
      .required("This field is required!")
      .email("Please enter a valid email"),
  });

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = async (formValue) => {
    const { username } = formValue;

    dispatch(alertActions.clear());

    try {
      setLoading(true);

      await dispatch(
        forgotPassword({ username: username, emailAddress: username })
      ).unwrap();
      navigate("/login");
      dispatch(
        alertActions.success({
          message:
            "Request was successful. Please check your email for further instructions.",
          showAfterRedirect: true,
        })
      );
      setLoading(false);
    } catch (error) {
      dispatch(alertActions.error(error));
      setLoading(false);
    }
  };

  return (
    <div className="bg-white rounded-lg w-[420px] md:w-[440px] p-8 ">
      <h3 className="text-4xl font-bold text-h28_dark_green mb-2">
        Forgot Password
      </h3>
      <p className="mt-1 text-sm text-neutral-500 ">
        Remembered Password?
        <Link to="/login" className="text-h28_dark_green">
          <span> Back to login</span>
        </Link>
      </p>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched }) => (
          <Form>
            <div className="mt-4">
              <div className="mt-4">
                <label className="block text-h28_dark_green text-sm font-semibold">
                  Email
                </label>
                <Field
                  type="text"
                  id="email"
                  placeholder="Email"
                  autoComplete="off"
                  name="username"
                  className={`w-full px-4 py-3 mt-1 border text-neutral-500 text-sm rounded-md focus:outline-none ${
                    errors.username && touched.username ? "border-red-500" : ""
                  } focus:border-h28_dark_green`}
                />
                <ErrorMessage
                  name="username"
                  component="div"
                  className="text-red-500 text-sm"
                />
                {/* {errors.email && (
                <p className="text-red-500 text-sm">{errors.email}</p>
              )} */}
              </div>

              <div className="flex items-baseline justify-between mt-3">
                {loading ? (
                  <button
                    type="submit"
                    className="w-full px-4 py-3 font-bold text-white bg-h28_green rounded-md flex items-center justify-center"
                    disabled={loading}
                  >
                    <span
                      className="mr-5 inline-block h-5 w-5 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                      role="status"
                    >
                      <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                        Loading...
                      </span>
                    </span>
                    Loading...
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="w-full px-4 py-3 font-bold text-white bg-h28_dark_green rounded-md"
                  >
                    Submit
                  </button>
                )}
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ForgotPassword;
