import React, { useEffect, useState } from "react";
import * as BsIcon from "react-icons/bs";
import * as FaIcon from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { getPortfolioDetail, reset as portfolioDetailReset } from "../features/portfolioBalance/portfolioDetailSlice";
import axios from "axios";
import { prettyDate, truncateString } from "../utils/helpers";

const BaseUrl = process.env.REACT_APP_BASE_API_URL;

const PortfolioDetails = () => {
  const dispatch = useDispatch();
  const [portfolioDetail, setPortfolioDetail] = useState({});
  const { id } = useParams();

  const { user } = useSelector((state) => state.auth);

  const date = new Date();
  

  const getPortfolioDetail = async (id) => {
    const config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };
  
    
   
    date.setDate(date.getDate() - 1);
  
    const response = await axios.get(
      `${BaseUrl}/getmutualfundstatement/${id}/${date.toISOString()}`,
      config
    );
  
    setPortfolioDetail(response.data);
  
  }
 

  useEffect(() => {
    getPortfolioDetail(id);
  
  }, []);

  const result = portfolioDetail.object && portfolioDetail?.object.reduce(function (acc, obj) {
    return acc + obj.amount;
  }, 0);

const lastElement = portfolioDetail.object && portfolioDetail?.object.length - 1;

  return (
    <>
      <div>
        <h4 className="text-2xl font-bold text-h28_dark_green mb-5">
          Statement
        </h4>
      </div>
      <div className="flex-1 flex-col bg-white rounded-lg p-5 md:p-10 mb-5">
        <div className="flex flex-row mb-5">
          <div
            className="flex flex-row items-center gap-1 text-gray-500 hover:text-h28_dark_green cursor-pointer"
            onClick={() => window.history.back()}
          >
            <span>
              <FaIcon.FaAngleLeft size={13} className="" />
            </span>
            <h4>Back</h4>
          </div>
        </div>
        <div className="flex flex-col">
          <div className="flex flex-row justify-between"><h4 className="text-h28_dark_green font-semibold text-xl">{portfolioDetail.scheduleType}</h4>
          <svg
              width="60"
              height="60"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M24.6372 16.7001C24.3122 16.3251 23.8372 16.1126 23.3497 16.1126H18.8747C16.6747 16.1126 14.8872 14.3251 14.8872 12.1251V7.65007C14.8872 7.16257 14.6747 6.68757 14.2997 6.36257C13.9372 6.03757 13.4372 5.88757 12.9622 5.95007C10.0247 6.32507 7.32469 7.93757 5.56219 10.3626C3.78719 12.8001 3.13719 15.7751 3.69969 18.7501C4.51219 23.0501 7.94969 26.4876 12.2622 27.3001C12.9497 27.4376 13.6372 27.5001 14.3247 27.5001C16.5872 27.5001 18.7747 26.8001 20.6372 25.4376C23.0622 23.6751 24.6747 20.9751 25.0497 18.0376C25.1122 17.5501 24.9622 17.0626 24.6372 16.7001Z"
                fill="#1D4165"
              />
              <path
                d="M26 7.92188V8.70313H18V7.92188H26ZM26 9.60938V10.3906H18V9.60938H26ZM25.3608 5V13H23.8941L20.4039 7.96484H20.3412V13H18.6471V5H20.1333L23.6039 10.0312H23.6706V5H25.3608Z"
                fill="#44C3D2"
              />
            </svg>
          </div>
          <div><p>{date.toDateString()}</p></div>
        </div>
      </div>
      <div className="flex flex-col bg-white rounded-lg p-5 md:p-10 gap-5">
          {portfolioDetail.object && portfolioDetail.object.map((item, index) => (
          <div className="flex flex-col gap-1" key={index}>
            <div className="flex flex-row items-center justify-between">
              <span>
                <p className="text-h28_dark_green text-md md:text-lg font-semibold">
                  {truncateString(item.narration, 20)}
                </p>
              </span>
              <span>
                <h4 className="text-gray-500 text-xs md:text-sm">{prettyDate(item.transDate)}</h4>
              </span>
            </div>
           
            <div className="flex flex-row items-center justify-between">
              <span>
                <h4 className="text-gray-500 text-xs md:text-sm"> {new Intl.NumberFormat().format(parseFloat(item.units != null ? item.units : 0).toFixed(2))} Units @ ₦{new Intl.NumberFormat().format(parseFloat(item.price != null ? item.price : 0).toFixed(2))}</h4>
              </span>
              <span>
                <p className="text-h28_dark_green text-md md:text-lg font-semibold">
                ₦
                {new Intl.NumberFormat().format(parseFloat(item.amount != null ? item.amount : 0).toFixed(2)  )}
                </p>
              </span>
            </div>
            <hr className={`bg-white mt-3 ${lastElement == index ? 'hidden' : 'block' }`} />
          </div>
          ))}
      </div>
      <div className="flex flex-col items-center bg-white rounded-lg p-5 md:p-10 mt-5">
        <div className="flex flex-row  mb-2">
          <h4>Closing balance</h4>
        </div>
        <div className="flex flex-col">
          <div><h4 className="text-h28_dark_green font-semibold text-xl">₦ {new Intl.NumberFormat().format(parseFloat(result != null ? result : 0).toFixed(2))}</h4></div>
        </div>
      </div>
    </>
  );
};

export default PortfolioDetails;
