import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { history } from "./utils/helpers";

const Protected = ({ children }) => {
  const auth = useAuth();
  return auth ? (
    children
  ) : (
    <Navigate to="/login" state={{ from: history.location }} />
  );
};

function useAuth() {
  if (useSelector((x) => x.auth.user)) {
    return true;
  } else {
    return false;
  }
}

export default Protected;
