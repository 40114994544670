import axios from "axios";

const BaseUrl = process.env.REACT_APP_BASE_API_URL;

//Get products from api
const getBankAccount = async ({ token }) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(`${BaseUrl}/getclientbankaccounts`, config);

  return response.data;
};

const saveBankAccount = async ({ token, formData }) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json; charset=utf-8",
    },
  };

  await axios.post(`${BaseUrl}/createbankaccount`, formData, config);

  //return response.data.products;
};

const bankAccountService = {
  getBankAccount,
  saveBankAccount,
};

export default bankAccountService;
