import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/auth/authSlice";
import { alertReducer } from "../features/alert/alertSlice";
import kycReducer from "../features/kyc/kycSlice";
import productReducer from "../features/products/productSlice";
import bankReducer from "../features/banks/bankSlice";
import countryReducer from "../features/countries/countrySlice";
import profileReducer from "../features/profile/profileSlice";
import virtualAccountReducer from "../features/virtualAccount/virtualAccountSlice";
import walletBalanceReducer from "../features/walletBalance/walletBalanceSlice";
import portfolioBalanceReducer from "../features/portfolioBalance/portfolioBalanceSlice";
import bankAccountReducer from "../features/bankAccount/bankAccountSlice";
import transactionsReducer from "../features/transactions/transactionsSlice";
import qoreidReducer from "../features/qoreid/qoreidSlice";
import requiredDocumentsReducer from "../features/requiredDocuments/requiredDocumentsSlice";
import nokReducer from "../features/nok/nokSlice";
import withdrawalReducer from "../features/withdrawal/withdrawalSlice";
import porfolioDetailReducer from "../features/portfolioBalance/portfolioDetailSlice"

export * from "../features/alert/alertSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    alert: alertReducer,
    kyc: kycReducer,
    products: productReducer,
    banks: bankReducer,
    countries: countryReducer,
    profile: profileReducer,
    virtual_account: virtualAccountReducer,
    wallet_balance: walletBalanceReducer,
    portfolio_balance: portfolioBalanceReducer,
    bank_accounts: bankAccountReducer,
    recent_transactions: transactionsReducer,
    qoreid_token: qoreidReducer,
    required_documents: requiredDocumentsReducer,
    nok: nokReducer,
    withdrawal: withdrawalReducer,
    porfolio_detail: porfolioDetailReducer
  },
});
