import axios from "axios";

const BaseUrl = process.env.REACT_APP_BASE_API_URL;

//Get products from api
const getCountries = async ({ token }) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(`${BaseUrl}/getcountries`, config);

  localStorage.setItem("countries", JSON.stringify(response.data));

  return response.data;
};

const countryService = {
  getCountries,
};

export default countryService;
