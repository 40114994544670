import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Icons from "react-icons/fa6";

import logo_2 from "../../../assets/images/logo_2.svg";

import { register, reset } from "../../../features/auth/authSlice";
import Corporate from "./forms/Corporate";
import Individual from "./forms/Individual";

const Register = () => {
  const [accountType, setAccountType] = useState("individual");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const active =
    "w-1/2 flex items-center justify-center bg-white rounded-lg p-2 border border-h28_dark_green cursor-pointer text-h28_dark_green";
  const normal =
    "w-1/2 text-gray-400 flex items-center justify-center rounded-lg p-2 border cursor-pointer";

  return (
    <div className="md:w-[470px]  bg-white p-8 rounded-lg ">
      {/* <img src={logo_2} alt="logo" className="w-10 mb-2" /> */}
      <h3 className="md:text-2xl text-xl font-bold text-h28_dark_green mb-2">
        Start your journey...
      </h3>

      <div>
        <div className="mt-4">
          <div className="mt-4 flex flex-col">
            <label className=" text-h28_dark_green text-sm font-semibold">
              Select Account Type
            </label>
            <div className="flex flex-row space-x-5 mt-2 mb-5 w-full">
              <div
                className={accountType === "individual" ? active : normal}
                onClick={() => setAccountType("individual")}
              >
                <span className="flex flex-row items-center gap-2">
                  <Icons.FaUser className="w-5 h-5" />
                  <h4>Individual</h4>
                </span>
              </div>
              <div
                className={accountType === "corporate" ? active : normal}
                onClick={() => setAccountType("corporate")}
              >
                <span className="flex flex-row items-center gap-2">
                  <Icons.FaBuildingUser className="w-7 h-7" />
                  <h4>Corporate</h4>
                </span>
              </div>
            </div>
          </div>
          {accountType === "individual" ? <Individual /> : <Corporate />}
          <div className="flex items-center justify-center mt-2 ">
            <p className="mt-1 text-sm text-neutral-500">
              Already a member?
              <Link to="/login" className="text-h28_dark_green">
                <span> Sign in</span>
              </Link>
            </p>
          </div>
          <div className="flex items-center justify-center mt-2 ">
            <p className="mt-1 text-sm text-neutral-500">
              Existing user?
              <Link to="/existing/individual" className="text-h28_dark_green">
                <span> Register again here</span>
              </Link>
            </p>
          </div>
          <div className="flex items-center justify-center mt-2 ">
            <p className="mt-1 text-sm text-neutral-500">
              Existing business?
              <Link to="/existing/company" className="text-h28_dark_green">
                <span> Register again here</span>
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
