import React from "react";
import { createPortal } from "react-dom";

const AddCard = ({ setIsOpen, open, children }) => {
  if (typeof document !== "undefined") {
    return createPortal(
      <>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="fixed inset-0 w-full h-full bg-black opacity-40"></div>
          <div className="flex items-center min-h-screen px-4 py-4">
            <div className="relative w-full max-w-xl p-10 mx-auto font-lexend bg-white rounded-md shadow-lg">
              <div className="w-full">
                <div className="flex flex-col justify-center">
                  <div className="flex justify-between">
                    <h3 className="text-xl font-bold text-h28_dark_green">
                      Add Card
                    </h3>
                    <h4
                      className="text-lg font-medium text-gray-500 hover:cursor-pointer"
                      onClick={() => setIsOpen(false)}
                    >
                      X
                    </h4>
                  </div>

                  <div className="mt-5">
                    <p className="text-neutral-500">
                      To add and verify your card ₦ 100 will be charged and
                      saved into your plan
                    </p>
                  </div>
                  <div className="w-full items-center gap-2 flex flex-row mt-5 justify-end">
                    <button
                      className=" mt-2 p-2.5 text-h28_dark_green"
                      onClick={() => setIsOpen(false)}
                    >
                      cancel
                    </button>
                    <button
                      className=" mt-2 p-2.5 w-28 text-white bg-h28_dark_green hover:bg-emerald-900 rounded-md outline-none ring-offset-2 ring-green-600 focus:ring-2"
                      onClick={() => setIsOpen(false)}
                    >
                      Proceed
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>,
      document.body
    );
  } else {
    return null;
  }
};

export default AddCard;
