import React from "react";
import { createPortal } from "react-dom";

const KycAlert = ({ setOpenKycAlert, open, children }) => {
  if (typeof document !== "undefined") {
    return createPortal(
      <>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="fixed inset-0 w-full h-full bg-black opacity-40"></div>
          <div className="flex items-center min-h-screen px-4 py-4">
            <div className="relative w-full max-w-xl p-10 mx-auto bg-white rounded-md shadow-lg font-gabarito">
              <div className="w-full">
                <div className="flex flex-col justify-center">
                  <div className="flex justify-end">
                    <h4
                      className="text-lg font-medium text-gray-500 hover:cursor-pointer"
                      onClick={() => setOpenKycAlert(false)}
                    >
                      X
                    </h4>
                  </div>

                  <div className="mt-5">
                    <h3>You documents have been submitted successfully</h3>
                  </div>

                  <div className="items-center gap-2 sm:flex flex-1 mt-5">
                    <button
                      className="w-full mt-2 p-2.5 flex-1 text-white bg-h28_dark_green hover:bg-h28_green rounded-md outline-none ring-offset-2 ring-green-600 focus:ring-2"
                      onClick={() => setOpenKycAlert(false)}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>,
      document.body
    );
  } else {
    return null;
  }
};

export default KycAlert;
