import React, { useEffect, useState } from "react";
import "@smile_identity/smart-camera-web";
import { createPortal } from "react-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { setKYC, reset } from "../../features/kyc/kycSlice";

//Components
import About from "./steps/About";
import Selfie from "./steps/Selfie";
import Identity from "./steps/Identity";

const KYC = ({ setOpenKYC }) => {
  const [page, setPage] = useState(0);
  const [formData, setFormData] = useState({
    nationality: "",
    id_number: "",
    selfie: null,
    governmentId: null,
    bvn: "",
    idType: "",
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);
    dispatch(setKYC(formData));
    setOpenKYC(false);
  };

  const FormTitles = ["Nationality", "Selfie", "Identity"];

  const PageDisplay = () => {
    if (page === 0) {
      return <About formData={formData} setFormData={setFormData} />;
    } else if (page === 1) {
      return <Selfie formData={formData} setFormData={setFormData} />;
    } else if (page === 2) {
      return <Selfie formData={formData} setFormData={setFormData} />;
    } else {
      return <Identity formData={formData} setFormData={setFormData} />;
    }
  };

  if (typeof document !== "undefined") {
    return createPortal(
      <>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div
            className="fixed inset-0 w-full h-full bg-black opacity-40"
            onClick={() => setOpenKYC(false)}
          ></div>
          <div className="flex items-center min-h-screen px-4 py-4">
            <div className="relative w-full max-w-xl p-10 mx-auto bg-white rounded-md shadow-lg font-lexend">
              <div className="flex ">
                <div className="flex-1 flex-col justify-center sm:text-left">
                  <h4 className="text-2xl font-bold text-h28_dark_green mb-1">
                    KYC - {FormTitles[page]}
                  </h4>
                  <div className="w-full bg-slate-100 h-1">
                    <div
                      className={`${
                        page === 0 ? "w-1/3" : page === 1 ? "w-2/3" : "w-3/3"
                      } bg-green-400 h-1`}
                    ></div>
                  </div>
                  <div>
                    <form>{PageDisplay()}</form>
                  </div>
                  <div className="mt-5 items-center gap-10 sm:flex w-full">
                    {page !== 0 ? (
                      <button
                        className="w-1/2 mt-2 p-2.5 flex-1 text-neutral-400 rounded-md border border-neutral-400 ring-offset-2 ring-h28_light_green_2 focus:ring-2"
                        disabled={page === 0}
                        onClick={() => {
                          setPage((currPage) => currPage - 1);
                        }}
                      >
                        Back
                      </button>
                    ) : (
                      <div className="w-1/2"></div>
                    )}
                    {page === FormTitles.length - 1 ? (
                      <button
                        className="w-44 mt-2 p-2.5 h-12 flex-1 text-white rounded-md bg-h28_dark_green outline-none border ring-offset-2 ring-h28_light_green_2 focus:ring-2"
                        onClick={handleSubmit}
                      >
                        <span className="text-md font-bold">Submit</span>
                      </button>
                    ) : (
                      <button
                        className="w-1/2 mt-2 p-2.5 flex-1 text-white rounded-md bg-h28_dark_green outline-none border ring-offset-2 ring-h28_light_green_2 focus:ring-2"
                        disabled={page === FormTitles.length - 1}
                        onClick={() => {
                          setPage((currPage) => currPage + 1);
                        }}
                      >
                        Next
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>,
      document.body
    );
  } else {
    return null;
  }
};

export default KYC;
