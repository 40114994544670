import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import transactionsService from "./transactionsService";

//const banks = JSON.parse(localStorage.getItem("banks"));

const initialState = {
  recent_transactions: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

//Get products

export const getRecentTransactions = createAsyncThunk(
  "api/get_recent_transactions",
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      //const user_id = thunkAPI.getState().auth.user.user_id;
      return await transactionsService.getRecentTransactions({ token });
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// export const saveBankAccount = createAsyncThunk(
//   "api/save_bank_account",
//   async (formData, thunkAPI) => {
//     try {
//       const token = thunkAPI.getState().auth.user.token;
//       return await bankAccountService.saveBankAccount({
//         token,
//         formData,
//       });
//     } catch (error) {
//       const message =
//         (error.response &&
//           error.response.data &&
//           error.response.data.message) ||
//         error.message ||
//         error.toString();
//       return thunkAPI.rejectWithValue(message);
//     }
//   }
// );

export const transactionsSlice = createSlice({
  name: "recent_transactions",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder

      .addCase(getRecentTransactions.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getRecentTransactions.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.recent_transactions = action.payload;
      })
      .addCase(getRecentTransactions.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.recent_transactions = [];
      });
  },
});

export const { reset } = transactionsSlice.actions;
export default transactionsSlice.reducer;
