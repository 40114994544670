import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createPortal } from "react-dom";
import { toast } from "react-toastify";
import {
  getVirtualAccount,
  reset as virtualAccountsReset,
} from "../../features/virtualAccount/virtualAccountSlice";

const QuickSave = ({ setIsOpen, open, children }) => {
  const { virtual_account, isError, message } = useSelector(
    (state) => state.virtual_account
  );

  useEffect(() => {
    getVirtualAccount();
    return () => {
      virtualAccountsReset();
    };
  }, [message]);

  const copy = async (accountText) => {
    await navigator.clipboard.writeText(accountText);
    toast.success("Copied successfully");
  };

  if (typeof document !== "undefined") {
    return createPortal(
      <>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="fixed inset-0 w-full h-full bg-black opacity-40"></div>
          <div className="flex items-center min-h-screen px-4 py-4">
            <div className="relative w-full max-w-xl p-10 mx-auto bg-white rounded-md shadow-lg font-lexend">
              <div className="w-full">
                <div className="flex flex-col justify-center">
                  <div className="flex justify-end">
                    <h4
                      className="text-lg font-medium text-gray-500 hover:cursor-pointer"
                      onClick={() => setIsOpen(false)}
                    >
                      X
                    </h4>
                  </div>

                  <div className="mt-5 flex flex-col justify-center items-center">
                    <span className="w-24 h-24 bg-h28_light_green rounded-full mb-5 flex items-center justify-center">
                      <svg
                        width="60"
                        height="60"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M22 19V22H2V19C2 18.45 2.45 18 3 18H21C21.55 18 22 18.45 22 19Z"
                          fill="#1D4165"
                          stroke="#1D4165"
                          stroke-width="1.5"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path d="M7 11H5V18H7V11Z" fill="#1D4165" />
                        <path d="M11 11H9V18H11V11Z" fill="#44C3D2" />
                        <path d="M15 11H13V18H15V11Z" fill="#1D4165" />
                        <path d="M19 11H17V18H19V11Z" fill="#44C3D2" />
                        <path
                          d="M23 22.75H1C0.59 22.75 0.25 22.41 0.25 22C0.25 21.59 0.59 21.25 1 21.25H23C23.41 21.25 23.75 21.59 23.75 22C23.75 22.41 23.41 22.75 23 22.75Z"
                          fill="#1D4165"
                        />
                        <path
                          d="M21.37 5.74984L12.37 2.14984C12.17 2.06984 11.83 2.06984 11.63 2.14984L2.63 5.74984C2.28 5.88984 2 6.29984 2 6.67984V9.99984C2 10.5498 2.45 10.9998 3 10.9998H21C21.55 10.9998 22 10.5498 22 9.99984V6.67984C22 6.29984 21.72 5.88984 21.37 5.74984ZM12 8.49984C11.17 8.49984 10.5 7.82984 10.5 6.99984C10.5 6.16984 11.17 5.49984 12 5.49984C12.83 5.49984 13.5 6.16984 13.5 6.99984C13.5 7.82984 12.83 8.49984 12 8.49984Z"
                          fill="#44C3D2"
                        />
                      </svg>
                    </span>
                    <span className="block text-h28_dark_green text-xl font-semibold">
                      Bank Transfer
                    </span>
                    <span className="flex flex-col items-center">
                      <p className="text-gray-500 text-sm">
                        Send money to the bank account details below.
                      </p>
                      <p className="text-gray-500 text-sm">
                        Please note that there is a N100.00 charge for Wallet
                        Top-up.
                      </p>
                    </span>
                  </div>

                  {virtual_account.map((account) => (
                    <div>
                      <div className="mt-5 flex flex-row justify-between items-center">
                        <span className="text-gray-500 text-md">Bank</span>
                        <span>
                          <p className="text-h28_dark_green text-md">
                            Rand Merchant Bank
                          </p>
                        </span>
                      </div>
                      <div className="mt-5 flex flex-row justify-between items-center">
                        <span className="text-gray-500 text-md">
                          Account Name
                        </span>
                        <span>
                          <p className="text-h28_dark_green text-md">
                            {account.virtualAccountName}
                          </p>
                        </span>
                      </div>
                      <div
                        className="mt-5 flex flex-row justify-between items-center"
                        onClick={() => copy(account.virtualAccountNo)}
                      >
                        <span className="text-gray-500 text-md">
                          Account Number
                        </span>
                        <span className="flex flex-row space-x-2 cursor-pointer">
                          <input
                            type="text"
                            className="text-h28_light_green_2 text-md w-24 outline-none cursor-pointer"
                            value={account.virtualAccountNo}
                          />

                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M16 12.9V17.1C16 20.6 14.6 22 11.1 22H6.9C3.4 22 2 20.6 2 17.1V12.9C2 9.4 3.4 8 6.9 8H11.1C14.6 8 16 9.4 16 12.9Z"
                              stroke="#44C3D2"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M22 6.9V11.1C22 14.6 20.6 16 17.1 16H16V12.9C16 9.4 14.6 8 11.1 8H8V6.9C8 3.4 9.4 2 12.9 2H17.1C20.6 2 22 3.4 22 6.9Z"
                              stroke="#44C3D2"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </span>
                      </div>
                      <div className="mt-5">
                        <p className="text-sm"></p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>,
      document.body
    );
  } else {
    return null;
  }
};

export default QuickSave;
