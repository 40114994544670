import axios from "axios";

const BaseUrl = process.env.REACT_APP_BASE_API_URL;

//Get products from api
const getRecentTransactions = async ({ token }) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  let count = 20;

  const response = await axios.get(
    `${BaseUrl}/getrecenttransactions/${count}`,
    config
  );

  return response.data;
};

const saveBankAccount = async ({ token, formData }) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json; charset=utf-8",
    },
  };

  await axios.post(`${BaseUrl}/createbankaccount`, formData, config);

  //return response.data.products;
};

const transactionsService = {
  getRecentTransactions,
  saveBankAccount,
};

export default transactionsService;
