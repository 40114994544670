import React, { useState } from "react";
import AddCard from "../components/modals/AddCard";

const Payments = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div className="flex-1 flex-col space-y-10">
        <div>
          <div className="flex flex-row gap-44 mb-10">
            <div>
              <h3 className="font-bold text-h28_dark_green text-lg">Cards</h3>
            </div>
            <div className="flex flex-col w-96">
              <div className="flex flex-col bg-neutral-100 rounded-md p-5 w-full border">
                <div className="flex flex-row justify-between">
                  <h3 className="text-xl text-neutral-500">
                    **** **** **** 1234
                  </h3>
                  <span className="flex bg-red-200 rounded-md justify-center items-center p-1 hover:cursor-pointer">
                    <p className="text-sm font-normal text-red-700 ">Remove</p>
                  </span>
                </div>
                <div className="flex mt-5">
                  <h3 className="text-lg text-neutral-500">
                    Master Card - Dec/2029
                  </h3>
                </div>
              </div>
              <div className="mt-5">
                <span
                  className="text-emerald-500 hover:cursor-pointer"
                  onClick={() => setIsOpen(true)}
                >
                  + Add Card
                </span>
              </div>
            </div>
          </div>
          <hr />
          <div className="flex flex-row gap-44 my-10">
            <div>
              <h3 className="font-bold text-h28_dark_green text-lg">Bank</h3>
            </div>
            <div className="flex flex-col w-96">
              <div className="flex flex-col bg-neutral-100 rounded-md p-5 w-full border">
                <div className="flex flex-row justify-between">
                  <h3 className="text-xl text-neutral-500">3010796623</h3>
                  <span className="flex bg-red-200 rounded-md justify-center items-center p-1 hover:cursor-pointer">
                    <p className="text-sm font-normal text-red-700 ">Remove</p>
                  </span>
                </div>
                <div className="flex mt-5">
                  <h3 className="text-lg text-neutral-500">Zenith Bank</h3>
                </div>
              </div>
              <div className="mt-5">
                <span className="text-emerald-500 hover:cursor-pointer">
                  Update Bank
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isOpen && <AddCard open={isOpen} setIsOpen={setIsOpen} />}
    </>
  );
};

export default Payments;
