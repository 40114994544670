import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import portfolioBalanceService from "./portfolioBalanceService";

const initialState = {
  portfolio_detail: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

//Get products

export const getPortfolioDetail = createAsyncThunk(
  "api/get_portfolio_detail",
  async (formData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      //const user_id = thunkAPI.getState().auth.user.user_id;
      console.log(formData)
      return await portfolioBalanceService.getPortfolioDetail({ token, formData });
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const portfolioDetailSlice = createSlice({
  name: "portfolio_detail",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder

      .addCase(getPortfolioDetail.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPortfolioDetail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.portfolio_detail = action.payload;
      })
      .addCase(getPortfolioDetail.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.portfolio_detail = [];
      });
  },
});

export const { reset } = portfolioDetailSlice.actions;
export default portfolioDetailSlice.reducer;
