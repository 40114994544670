import axios from "axios";

const BaseUrl = "https://api.qoreid.com/token";
const clientId = process.env.REACT_APP_QOREID_CLIENT;
const secret = process.env.REACT_APP_QOREID_SECRET;

const getQoreidToken = async () => {
  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
  };
  const response = await axios.post(`${BaseUrl}`, { clientId, secret }, config);
  return response.data;
};

const qoreidService = {
  getQoreidToken,
};

export default qoreidService;
