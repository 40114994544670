import axios from "axios";

const BaseUrl = process.env.REACT_APP_BASE_API_URL;

//Get products from api
const getKYC = async ({ token }) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(
    `${BaseUrl}/getkycregistrationstatus`,
    config
  );

  localStorage.setItem("kyc", JSON.stringify(response.data));

  return response.data;
};

const setKYC = async ({ token, formData }) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": `*`,
    },
  };
  console.log(config);
  const response = await axios.post(
    `${BaseUrl}/updateindividualclientaccountinfo`,
    formData,
    config
  );
  return response.data;
};

const kycService = {
  getKYC,
  setKYC,
};

export default kycService;
