import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { maskEmail } from "../../../utils/helpers/index.js";

import OtpInput from "react-otp-input";

import { verify, reset } from "../../../features/auth/authSlice";
import { alertActions } from "../../../app/store.js";
import { getKYC } from "../../../features/kyc/kycSlice.js";
const Otp = () => {
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);

  const email = localStorage.getItem("email");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  if (!email) {
    return <Navigate to="/login" />;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const userData = {
      username: email,
      securityCode: otp,
    };

    try {
      setLoading(true);

      await dispatch(verify(userData)).unwrap();
      navigate("/");
      dispatch(
        alertActions.success({
          message: "Login successful",
          showAfterRedirect: true,
        })
      );
      dispatch(getKYC());
      setLoading(false);
    } catch (error) {
      dispatch(alertActions.error(error));
      setLoading(false);
    }
  };

  return (
    <div className="w-[420px] md:w-[440px] bg-white p-8 rounded-lg">
      <h3 className="text-4xl font-bold text-h28_dark_green mb-2">PIN</h3>
      <p className="mt-1 text-sm text-neutral-500 mb-4">
        Please enter the 6-digit code sent to {maskEmail(email)}
      </p>

      <form onSubmit={handleSubmit}>
        <div className="mt-4">
          <div className="mt-4 flex flex-row">
            <OtpInput
              value={otp}
              onChange={setOtp}
              numInputs={6}
              renderSeparator={<span></span>}
              renderInput={(props) => <input {...props} />}
              containerStyle=""
              inputType="tel"
              inputStyle={{
                width: 50,
                height: 50,
                border: "2px solid #7c878b",
                marginRight: "11px",
                color: "#1D4165",
                borderRadius: "10px",
                fontSize: "24px",
                fontWeight: "bold",
                outlineColor: "#1D4165",
              }}
            />
          </div>
          <div className="flex items-center justify-center mt-2 ">
            <p className="mt-1 text-sm text-neutral-500">
              Code expired?
              <Link to="/login" className="text-h28_dark_green">
                <span> Sign in again</span>
              </Link>
            </p>
          </div>

          <div className="flex items-baseline pb-3 justify-between">
            {loading ? (
              <button
                type="submit"
                className="w-full px-4 py-3 mt-4 font-bold text-white bg-h28_dark_green/25 rounded-md flex items-center justify-center"
              >
                <span
                  className="mr-5 inline-block h-5 w-5 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                  role="status"
                >
                  <span class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                    Loading...
                  </span>
                </span>
                Loading
              </button>
            ) : (
              <button
                type="submit"
                className="w-full px-4 py-3 mt-8 font-bold text-white bg-h28_dark_green rounded-md"
              >
                Submit
              </button>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};
export default Otp;
