import React, { useEffect, useLayoutEffect, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { getCountries, reset } from "../../../features/countries/countrySlice";
import {
  getRequiredDocuments,
  reset as getRequiredDocumentsReset,
} from "../../../features/requiredDocuments/requiredDocumentsSlice";
import { getProfile } from "../../../features/profile/profileSlice";
import { alertActions } from "../../../app/store";

const CustomInputComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => (
  <div>
    <textarea type="text" {...field} {...props}></textarea>
  </div>
);

const About = ({ errors, touched, values, uploadFile }) => {
  const [files, setFiles] = useState([]);

  const removeFile = (filename) => {
    setFiles(files.filter((file) => file.name !== filename));
  };
  const token = useSelector((state) => state.auth.user.token);

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { profile } = useSelector((state) => state.profile);

  const { required_documents } = useSelector(
    (state) => state.required_documents
  );

  const documentIndex = required_documents.find(
    (required_document) =>
      required_document.document === "PHOTOCOPY OF PROOF OF ADDRESS"
  );

  const BaseUrl = process.env.REACT_APP_BASE_API_URL;

  useEffect(() => {
    dispatch(getRequiredDocuments());
  }, [dispatch]);
  const { countries, isError, message } = useSelector(
    (state) => state.countries
  );
  useLayoutEffect(() => {
    dispatch(getCountries());
  }, [isError, message, dispatch]);
  useEffect(() => {
    dispatch(getProfile());
  }, [dispatch]);

  const uploadHandler = (event) => {
    // event.preventDefault();
    const file = event.target.files[0];
    if (!file) return;
    if (file.size > 1024 * 1024) {
      dispatch(
        alertActions.error({
          message: "File size should not exceed 1MB",
          showAfterRedirect: true,
        })
      );
      return;
    }
    file.isUploading = true;
    setFiles([...files, file]);
    // upload file
    const formData = new FormData();

    formData.append("Files", file);
    formData.append("DocumentId", documentIndex.documentId);
    formData.append("Comment", "PHOTOCOPY OF PROOF OF ADDRESS");

    axios
      .post(`${BaseUrl}/uploadclientdocument`, formData, {
        mode: "cors",
        headers: {
          "Content-Type": "multipart/form-data",
          accept: "*/*",
          Authorization: `Bearer ${user.token}`,
        },
      })
      .then((res) => {
        file.isUploading = false;
        console.log(res.data);
        dispatch(
          alertActions.success({
            message: "Uploaded Successfully",
            showAfterRedirect: true,
          })
        );
        setFiles([...files, file]);
      })
      .catch((err) => {
        // inform the user
        dispatch(alertActions.error(err));
        console.log(err);
        removeFile(file.name);
      });
  };

  return (
    <div className="mt-5">
      <div className="mt-4">
        <label className="block text-h28_dark_green text-md font-semibold mb-1">
          Title
        </label>
        <Field
          as="select"
          name="title"
          className={`w-full px-4 py-3 mt-1 border text-neutral-500 text-sm rounded-md focus:outline-none ${
            errors.title && touched.title ? "border-red-500" : ""
          } focus:border-nelsa_primary`}
        >
          <option value="">Select</option>
          <option value="Mr">Mr</option>
          <option value="Mrs">Mrs</option>
          <option value="Dr">Dr</option>
          <option value="Prof">Prof</option>
          <option value="Miss">Miss</option>
          <option value="Others">Others</option>
        </Field>
        <ErrorMessage
          name="title"
          component="div"
          className="text-red-500 text-sm"
        />
      </div>
      <div className="flex md:flex-row flex-col md:space-x-4">
        <div className="mt-4 md:w-1/2">
          <label className="block text-h28_dark_green text-sm font-semibold">
            Gender
          </label>
          <Field
            as="select"
            name="gender"
            className={`w-full px-4 py-3 mt-1 border text-neutral-500 text-sm rounded-md focus:outline-none ${
              errors.gender && touched.gender ? "border-red-500" : ""
            } focus:border-nelsa_primary`}
          >
            <option value="">Select</option>
            <option value="M">Male</option>
            <option value="F">Female</option>
            <option value="O">Other</option>
          </Field>
          <ErrorMessage
            name="gender"
            component="div"
            className="text-red-500 text-sm"
          />
        </div>
        <div className="mt-4 md:w-1/2">
          <label className="block text-h28_dark_green text-sm font-semibold">
            Marital Status
          </label>
          <Field
            as="select"
            name="marital_status"
            className={`w-full px-4 py-3 mt-1 border text-neutral-500 text-sm rounded-md focus:outline-none ${
              errors.marital_status && touched.marital_status
                ? "border-red-500"
                : ""
            } focus:border-nelsa_primary`}
          >
            <option value="">Select</option>
            <option value="M">Married</option>
            <option value="S">Single</option>
            <option value="D">Divorced</option>
            <option value="O">Other</option>
          </Field>
          <ErrorMessage
            name="marital_status"
            component="div"
            className="text-red-500 text-sm"
          />
        </div>
      </div>

      {/* <div className="mt-4">
        <label className="block text-h28_dark_green text-md font-semibold mb-1">
          Religion
        </label>
        <Field
          type="text"
          name="religion"
          placeholder="Religion"
          autoComplete="off"
          className={`w-full px-4 py-3 mt-1 border text-neutral-500 text-sm rounded-md focus:outline-none ${
            errors.religion && touched.religion ? "border-red-500" : ""
          } focus:border-nelsa_primary `}
        />
        <ErrorMessage
          name="religion"
          component="div"
          className="text-red-500 text-sm"
        />
      </div>
      <div className="mt-4">
        <label className="block text-h28_dark_green text-md font-semibold mb-1">
          Occupation
        </label>
        <Field
          type="text"
          name="occupation"
          placeholder="Occupation"
          autoComplete="off"
          className={`w-full px-4 py-3 mt-1 border text-neutral-500 text-sm rounded-md focus:outline-none ${
            errors.occupation && touched.occupation ? "border-red-500" : ""
          } focus:border-nelsa_primary `}
        />
        <ErrorMessage
          name="occupation"
          component="div"
          className="text-red-500 text-sm"
        />
      </div> */}
      <div className="mt-4">
        <label className="block text-h28_dark_green text-md font-semibold mb-1">
          Address
        </label>
        <Field
          name="address"
          className={`w-full px-4 py-3 mt-1 border text-neutral-500 text-sm rounded-md focus:outline-none ${
            errors.address && touched.address ? "border-red-500" : ""
          } focus:border-nelsa_primary`}
          component={CustomInputComponent}
          placeholder="Address"
        />
        <ErrorMessage
          name="address"
          component="div"
          className="text-red-500 text-sm"
        />
      </div>
      <div className="flex md:flex-row flex-col md:space-x-4">
        <div className="mt-4 md:w-1/2">
          <label className="block text-h28_dark_green text-sm font-semibold">
            City
          </label>
          <Field
            type="text"
            name="city"
            placeholder="City"
            autoComplete="off"
            className={`w-full px-4 py-3 mt-1 border text-neutral-500 text-sm rounded-md focus:outline-none ${
              errors.city && touched.city ? "border-red-500" : ""
            } focus:border-nelsa_primary `}
          />
          <ErrorMessage
            name="city"
            component="div"
            className="text-red-500 text-sm"
          />
        </div>
        <div className="mt-4 md:w-1/2">
          <label className="block text-h28_dark_green text-sm font-semibold">
            State
          </label>
          <Field
            type="text"
            name="state"
            placeholder="State"
            autoComplete="off"
            className={`w-full px-4 py-3 mt-1 border text-neutral-500 text-sm rounded-md focus:outline-none ${
              errors.state && touched.state ? "border-red-500" : ""
            } focus:border-nelsa_primary `}
          />
          <ErrorMessage
            name="state"
            component="div"
            className="text-red-500 text-sm"
          />
        </div>
      </div>
      <div className="mt-4">
        <label className="block text-h28_dark_green text-md font-semibold mb-1">
          Country
        </label>
        <Field
          as="select"
          name="country"
          className={`w-full px-4 py-3 mt-1 border text-neutral-500 text-sm rounded-md focus:outline-none ${
            errors.country && touched.country ? "border-red-500" : ""
          } focus:border-nelsa_primary`}
        >
          <option value="">Select</option>
          {countries.map((country) => (
            <option value={country.code} key={country.code}>
              {country.name}
            </option>
          ))}
        </Field>
        <ErrorMessage
          name="country"
          component="div"
          className="text-red-500 text-sm"
        />
      </div>
      <div className="mt-4">
        <label className="block text-h28_dark_green text-ms font-semibold mb-1">
          Proof of address
        </label>
        {/* <input
          id="file"
          name="governmentId"
          type="file"
          accept={"image/jpeg,image/png"}
          className="w-full px-3 py-2.5 mt-1 border text-black text-sm rounded-lg focus:border-nelsa_primary focus:outline-none"
          onChange={(e) => {
            setFieldValue("governmentId", e.currentTarget.files[0]);
          }}
          multiple
        /> */}

        <input
          type={"file"}
          accept={"image/jpeg,image/png,application/pdf"}
          required
          onChange={uploadHandler}
          className={`w-full px-3 py-2.5 mt-1 border text-black text-sm rounded-md focus:border-nelsa_primary focus:outline-none`}
        />
      </div>
    </div>
  );
};

export default About;
