import React, { useLayoutEffect, useState } from "react";
import { createPortal } from "react-dom";

import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  Formik,
  Field,
  Form,
  ErrorMessage,
  useField,
  useFormikContext,
} from "formik";
import * as Yup from "yup";
import { alertActions } from "../../app/store";
import {
  getBankAccount,
  saveBankAccount,
} from "../../features/bankAccount/bankAccountSlice";
import { getBanks, reset } from "../../features/banks/bankSlice";

const AddBank = ({ setOpenAddBank, open, children }) => {
  const [loading, setLoading] = useState(false);

  const { banks, isError, message } = useSelector((state) => state.banks);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    dispatch(getBanks());
    return () => {
      dispatch(reset());
    };
  }, [message]);

  const initialValues = {
    beneficiaryAccountNo: "",
    beneficiaryName: "",
    beneficiaryCompanyId: "",
  };

  const validationSchema = Yup.object().shape({
    beneficiaryCompanyId: Yup.string().required("Bank is required!"),
    beneficiaryAccountNo: Yup.string().required("Account number is required!"),
    beneficiaryName: Yup.string().required("Account name is required!"),
  });

  const handleSubmit = async (formValue) => {
    const { beneficiaryCompanyId, beneficiaryAccountNo, beneficiaryName } =
      formValue;

    dispatch(alertActions.clear());
    try {
      setLoading(true);

      await dispatch(
        saveBankAccount({
          beneficiaryCompanyId: beneficiaryCompanyId,
          beneficiaryAccountNo: beneficiaryAccountNo,
          beneficiaryName: beneficiaryName,
          currencyCode: "NGN",
        })
      ).unwrap();
      //localStorage.setItem("email", JSON.stringify(email));

      dispatch(
        alertActions.success({
          message: "Bank account successfully added.",
          showAfterRedirect: true,
        })
      );
      // navigate("/suppliers");
      // window.location.reload(true);
      dispatch(getBankAccount());
      setLoading(false);

      setOpenAddBank(false);
    } catch (error) {
      dispatch(alertActions.error(error));
      setLoading(false);
    }
  };

  if (typeof document !== "undefined") {
    return createPortal(
      <>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="fixed inset-0 w-full h-full bg-black opacity-40"></div>
          <div className="flex mt-20 px-4 py-4">
            <div className="relative w-full max-w-xl p-10 mx-auto font-lexend bg-white rounded-xl shadow-lg">
              <div className="w-full">
                <div className="flex flex-col justify-center">
                  <div className="flex justify-between">
                    <h3 className="text-xl font-bold text-h28_dark_green">
                      Add Bank
                    </h3>
                    <h4
                      className="text-lg font-medium text-gray-500 hover:cursor-pointer"
                      onClick={() => setOpenAddBank(false)}
                    >
                      X
                    </h4>
                  </div>

                  <div className="mt-5 flex flex-col justify-center items-center">
                    <Formik
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      onSubmit={handleSubmit}
                    >
                      {({ errors, touched }) => (
                        <Form className="w-full">
                          <div className="mt-4">
                            <label className="block text-h28_dark_green text-sm font-semibold">
                              Bank
                            </label>
                            <Field
                              as="select"
                              name="beneficiaryCompanyId"
                              className={`w-full px-4 py-3 mt-1 border text-neutral-500 text-sm rounded-md focus:outline-none ${
                                errors.beneficiaryCompanyId &&
                                touched.beneficiaryCompanyId
                                  ? "border-red-500"
                                  : ""
                              } focus:border-nelsa_primary`}
                            >
                              <option value="">Select</option>
                              {banks.map((bank) => (
                                <option
                                  key={bank.companyId}
                                  value={bank.companyId}
                                >
                                  {bank.bankName}
                                </option>
                              ))}
                            </Field>

                            <ErrorMessage
                              name="beneficiaryCompanyId"
                              component="div"
                              className="text-red-500 text-sm"
                            />
                          </div>
                          <div className="mt-5">
                            <label className="block text-h28_dark_green text-sm font-semibold mb-1">
                              Account Name
                            </label>
                            <Field
                              type="text"
                              name="beneficiaryName"
                              placeholder="Enter account name"
                              autoComplete="off"
                              className={`w-full px-4 py-3 mt-1 border text-neutral-500 text-sm rounded-md focus:outline-none ${
                                errors.beneficiaryName &&
                                touched.beneficiaryName
                                  ? "border-red-500"
                                  : ""
                              } focus:border-nelsa_primary `}
                            />
                            <ErrorMessage
                              name="beneficiaryName"
                              component="div"
                              className="text-red-500 text-sm"
                            />
                          </div>
                          <div className="mt-5">
                            <label className="block text-h28_dark_green text-sm font-semibold mb-1">
                              Account Number
                            </label>
                            <Field
                              type="text"
                              name="beneficiaryAccountNo"
                              placeholder="Enter account number"
                              autoComplete="off"
                              className={`w-full px-4 py-3 mt-1 border text-neutral-500 text-sm rounded-md focus:outline-none ${
                                errors.beneficiaryAccountNo &&
                                touched.beneficiaryAccountNo
                                  ? "border-red-500"
                                  : ""
                              } focus:border-nelsa_primary `}
                            />
                            <ErrorMessage
                              name="beneficiaryAccountNo"
                              component="div"
                              className="text-red-500 text-sm"
                            />
                          </div>

                          <div className="flex items-baseline justify-between">
                            {loading ? (
                              <button
                                type="submit"
                                className="w-full px-4 py-3 mt-4 font-bold bg-h28_dark_green/25 text-[#ffffff] rounded-md flex items-center justify-center"
                                disabled={loading}
                              >
                                <span
                                  className="mr-5 inline-block h-5 w-5 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                                  role="status"
                                >
                                  <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                                    Loading...
                                  </span>
                                </span>
                                Loading...
                              </button>
                            ) : (
                              <button
                                type="submit"
                                className="w-full px-4 py-3 mt-4 font-bold bg-h28_dark_green text-[#ffffff] rounded-md"
                              >
                                Submit
                              </button>
                            )}
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>,
      document.body
    );
  } else {
    return null;
  }
};

export default AddBank;
