import React from "react";
import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import { store } from "./app/store";
import { getKYC } from "./features/kyc/kycSlice";
import { getProducts } from "./features/products/productSlice";
import { getBanks } from "./features/banks/bankSlice";
import { getProfile } from "./features/profile/profileSlice";
import { getVirtualAccount } from "./features/virtualAccount/virtualAccountSlice";
import { getWalletBalance } from "./features/walletBalance/walletBalanceSlice";
import { getPortfolioBalance } from "./features/portfolioBalance/portfolioBalanceSlice";
import { getRequiredDocuments } from "./features/requiredDocuments/requiredDocumentsSlice";
import { getNok } from "./features/nok/nokSlice";

document.title = "1Click App";

Sentry.init({
  dsn: "https://3ef6031b5d564b07b96f66f57abe2e57@o1114643.ingest.us.sentry.io/6145961",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(document.getElementById("root"));

store.dispatch(getBanks());
store.dispatch(getProfile());
store.dispatch(getVirtualAccount());
store.dispatch(getWalletBalance());
store.dispatch(getPortfolioBalance());
store.dispatch(getRequiredDocuments());
store.dispatch(getNok());

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
