import React, { useEffect, useLayoutEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import * as FaIcon from "react-icons/fa6";
import { getProfile, reset } from "../../features/profile/profileSlice";
import KycAlert from "../../components/modals/KycAlert";
import { getNok, setNok } from "../../features/nok/nokSlice";
import { alertActions } from "../../app/store";
import { Form, Formik } from "formik";
import Nok from "./Nok";

const PersonalDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { profile, message } = useSelector((state) => state.profile);

  useEffect(() => {
    dispatch(getProfile());
    return () => {
      dispatch(reset());
    };
  }, [dispatch, message]);

  return (
    <>
      <div className="bg-white rounded-lg flex flex-col p-10">
        <div className="flex flex-row mb-5">
          <Link
            className="flex flex-row items-center gap-1 text-gray-500 hover:text-h28_dark_green"
            to="/settings"
          >
            <span>
              <FaIcon.FaAngleLeft size={13} className="" />
            </span>
            <h4>Back</h4>
          </Link>
        </div>
        <div className="flex flex-col">
          <div className="flex flex-row">
            <h3 className="text-zinc-400 text-2xl font-semibold">Personal</h3>
          </div>
          <hr className="my-5" />
          <div className="flex flex-row space-x-5 items-center">
            <div className="bg-h28_dark_green w-16 h-16 rounded-full flex items-center justify-center text-white text-2xl font-bold">
              {/* {profile && profile.firstname.charAt(0)}
              {profile && profile.surname.charAt(0)} */}
            </div>
            <div>
              <h3 className="text-h28_dark_green text-2xl font-semibold">
                {profile && profile.firstname} {profile && profile.surname}
              </h3>
            </div>
          </div>
          <div className="flex flex-col mt-10 gap-5">
            <div className="flex flex-col md:flex-row w-full">
              <div className="flex flex-col w-full md:w-1/3">
                <h3 className="text-h28_dark_green text-xl font-medium">
                  Fullname
                </h3>
                <p className="mt-1 text-sm text-neutral-500 mb-2">
                  Update your name
                </p>
              </div>
              <div className="flex flex-col md:flex-row gap-5 w-full md:w-1/3">
                <div className="w-full md:w-1/2">
                  <input
                    type="text"
                    id="email"
                    placeholder="Firstname"
                    autoComplete="off"
                    value={profile.firstname}
                    required
                    className="w-full px-4 py-3 mt-1 border text-neutral-500 text-sm rounded-md focus:outline-none focus:border-h28_dark_green"
                  />
                </div>
                <div className="w-full md:w-1/2">
                  <input
                    type="text"
                    id="email"
                    placeholder="Firstname"
                    autoComplete="off"
                    value={profile.surname}
                    required
                    className="w-full px-4 py-3 mt-1 border text-neutral-500 text-sm rounded-md focus:outline-none focus:border-h28_dark_green"
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-col md:flex-row w-full">
              <div className="flex flex-col w-full md:w-1/3">
                <h3 className="text-h28_dark_green text-xl font-medium">
                  Phone Number
                </h3>
                <p className="mt-1 text-sm text-neutral-500 mb-2">
                  Update your phone number
                </p>
              </div>
              <div className="flex flex-row gap-5 w-full md:w-2/3">
                <div className="w-full md:w-1/2">
                  <input
                    type="text"
                    id="email"
                    placeholder="Phone number"
                    autoComplete="off"
                    value={profile.mobileNumber}
                    required
                    className="w-full px-4 py-3 border text-neutral-500 text-sm rounded-md focus:outline-none focus:border-h28_dark_green"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-row mt-10">
            <h3 className="text-zinc-400 text-2xl font-semibold">
              Next of kin
            </h3>
          </div>
          <hr className="my-5" />

          <Nok />
        </div>
      </div>
    </>
  );
};

export default PersonalDetails;
