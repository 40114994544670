import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import forgotPasswordService from "./forgotPasswordService";

const initialState = {
  forgot: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

//Get products

export const resetPassord = createAsyncThunk(
  "api/reset_password_save",
  async (userData, thunkAPI) => {
    try {
      return await forgotPasswordService.resetPassword(userData);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.object) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const forgotPassword = createAsyncThunk(
  "api/reset_password",
  async (userData, thunkAPI) => {
    try {
      return await forgotPasswordService.resetPasswordRequest(userData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const forgotPasswordSlice = createSlice({
  name: "forgot password",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(forgotPassword.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.status = action.payload;
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(resetPassord.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(resetPassord.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.status = action.payload;
      })
      .addCase(resetPassord.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset } = forgotPasswordSlice.actions;
export default forgotPasswordSlice.reducer;
