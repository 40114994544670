import axios from "axios";
import React, { useRef, useState } from "react";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import Webcam from "react-webcam";
import { alertActions } from "../../../app/store";

const videoConstraints = {
  width: 580,
  facingMode: "user",
};

const Selfie = ({ selfie, setSelfie }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const webcamRef = useRef(null);
  const { profile } = useSelector((state) => state.profile);
  const { user } = useSelector((state) => state.auth);

  const BaseUrl = process.env.REACT_APP_BASE_API_URL;

  const capturePhoto = useCallback(async () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setSelfie(imageSrc);
  }, [webcamRef, setSelfie]);

  const reset = () => {
    setSelfie("");
  };

  const onUserMedia = (selfie) => {};

  const uploadSelfy = async () => {
    setLoading(true);

    const blobData = await fetch(selfie).then((res) => res.blob());

    const formData = new FormData();

    console.log(selfie);

    formData.append("base64", selfie);
    formData.append(
      "filename",
      `${profile?.firstname + Math.random().toString(36).substring(2, 7)}.jpg`
    );

    axios
      .post(
        `${BaseUrl}/uploadclientphoto`,
        {
          base64: selfie,
          filename: `${
            profile?.firstname + Math.random().toString(36).substring(2, 7)
          }.jpg`,
        },
        {
          mode: "cors",
          headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      )
      .then((res) => {
        setLoading(false);
        setSelfie("");
        dispatch(alertActions.success("Saved successfully"));
        console.log(res);
      })
      .catch((err) => {
        // inform the user
        console.log(err);
        setLoading(false);
        setSelfie("");
        dispatch(alertActions.error(err.message));
      });
  };

  return (
    <div className="p-2 rounded-md mt-10">
      <Webcam
        ref={webcamRef}
        audio={false}
        screenshotFormat="image/jpeg"
        videoConstraints={videoConstraints}
        mirrored={true}
        onUserMedia={onUserMedia}
        style={{
          borderRadius: 10,
          width: "auto",
          height: "100%",
        }}
      />
      <div className="absolute md:top-[440px] md:left-[1050px] md:w-[140px] w-20 left-[40px] top-[210px] rounded-lg">
        <img className="rounded-lg" src={selfie} alt="" />
      </div>
      <div className="mt-5 flex gap-3">
        <button
          className=" text-white bg-h28_dark_green text-sm font-bold px-3 py-2.5 rounded-md"
          type="button"
          onClick={capturePhoto}
        >
          Capture
        </button>
        {selfie && (
          <>
            <button
              className="outline outline-red-500 text-red-500  px-3 py-2.5 text-sm font-bold rounded-md"
              type="button"
              onClick={reset}
            >
              Reset
            </button>
            {loading ? (
              <button
                type="button"
                className="text-white outline bg-green-500  px-3 py-2.5 text-sm font-bold rounded-md"
              >
                <span
                  className="mr-5 inline-block h-5 w-5 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                  role="status"
                >
                  <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                    saving...
                  </span>
                </span>
                Saving
              </button>
            ) : (
              <button
                className=" text-white outline bg-green-500  px-3 py-2.5 text-sm font-bold rounded-md"
                type="button"
                onClick={() => uploadSelfy(selfie)}
              >
                Save
              </button>
            )}
          </>
        )}
      </div>
      <span>
        <p className="text-sm mt-3">
          Once you capture click on save before you submit.
        </p>
      </span>
    </div>
  );
};

export default Selfie;
