import React from "react";
import { Outlet, NavLink, useNavigate, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout, reset } from "../features/auth/authSlice";
import { alertActions } from "../app/store";
import authImage from "../assets/images/Black.svg";

const OnboardingLayout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const status = useSelector((x) => x.kyc);
  console.log(status);

  setTimeout(() => {
    dispatch(reset());
    dispatch(logout());
    dispatch(
      alertActions.error({
        message: "Session ended. Please login again.",
        showAfterRedirect: true,
      })
    );
    navigate("/login");
  }, 60 * 60 * 500);

  if (status.status === true) {
    return <Navigate to="/" />;
  }
  return (
    <div className="min-h-screen font-lexend antialiased lg:flex bg-white text-gray-800 overflow-x-0">
      <Outlet />
    </div>
  );
};

export default OnboardingLayout;
