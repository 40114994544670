import React, { useEffect, useLayoutEffect, useState } from "react";
import { createPortal } from "react-dom";

import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  Formik,
  Field,
  Form,
  ErrorMessage,
  useField,
  useFormikContext,
} from "formik";
import * as Yup from "yup";
import { alertActions } from "../../app/store";
import {
  getWalletBalance,
  reset as balanceReset,
} from "../../features/walletBalance/walletBalanceSlice";
import { walletWithdrawal } from "../../features/withdrawal/withdrawalSlice";
import { getBankAccount } from "../../features/bankAccount/bankAccountSlice";
import { getRecentTransactions } from "../../features/transactions/transactionsSlice";

const QuickWithdraw = ({ setOpenWithdraw, open, children }) => {
  const [loading, setLoading] = useState(false);

  const { wallet_balance, isError, message } = useSelector(
    (state) => state.wallet_balance
  );

  const { bank_accounts } = useSelector((state) => state.bank_accounts);

  console.log(wallet_balance[0].walletAccountNo);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getWalletBalance());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getBankAccount());
  }, [dispatch]);

  const initialValues = {
    amount: "",
  };

  const validationSchema = Yup.object().shape({
    amount: Yup.string().required("Bank is required!"),
  });

  const handleSubmit = async (formValue) => {
    const { amount } = formValue;

    dispatch(alertActions.clear());
    try {
      setLoading(true);

      await dispatch(
        walletWithdrawal({
          amount: amount,
          walletBankAccountNo: wallet_balance[0]?.walletAccountNo,
          beneficiaryBankAccountNo: bank_accounts[0]?.beneficiaryAccountNo,
          currencyCode: "NGN",
        })
      ).unwrap();
      //localStorage.setItem("email", JSON.stringify(email));
      dispatch(getRecentTransactions());
      dispatch(
        alertActions.success({
          message: "Withdrawal request sent successfully.",
          showAfterRedirect: true,
        })
      );
      // navigate("/suppliers");
      // window.location.reload(true);
      dispatch(getWalletBalance());
      setLoading(false);

      setOpenWithdraw(false);
    } catch (error) {
      dispatch(alertActions.error(error));
      setLoading(false);
    }
  };

  if (typeof document !== "undefined") {
    return createPortal(
      <>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="fixed inset-0 w-full h-full bg-black opacity-40"></div>
          <div className="flex items-center min-h-screen px-4 py-4">
            <div className="relative w-full max-w-xl p-10 mx-auto bg-white rounded-md shadow-lg font-gabarito">
              <div className="w-full">
                <div className="flex flex-col justify-center">
                  <div className="flex justify-end">
                    <h4
                      className="text-lg font-medium text-gray-500 hover:cursor-pointer"
                      onClick={() => setOpenWithdraw(false)}
                    >
                      X
                    </h4>
                  </div>

                  <div className="mt-5 flex flex-col justify-center items-center">
                    <Formik
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      onSubmit={handleSubmit}
                    >
                      {({ errors, touched }) => (
                        <Form className="w-full">
                          <div className="mt-5">
                            <label className="block text-h28_dark_green text-sm font-semibold mb-1">
                              Amount
                            </label>
                            <Field
                              type="number"
                              name="amount"
                              placeholder="Enter amount"
                              autoComplete="off"
                              className={`w-full px-4 py-3 mt-1 border text-neutral-500 text-sm rounded-md focus:outline-none ${
                                errors.amount && touched.amount
                                  ? "border-red-500"
                                  : ""
                              } focus:border-nelsa_primary `}
                            />
                            <ErrorMessage
                              name="amount"
                              component="div"
                              className="text-red-500 text-sm"
                            />
                          </div>

                          <div className="flex items-baseline justify-between">
                            {loading ? (
                              <button
                                type="submit"
                                className="w-full px-4 py-3 mt-4 font-bold bg-h28_dark_green/25 text-[#ffffff] rounded-md flex items-center justify-center"
                                disabled={loading}
                              >
                                <span
                                  className="mr-5 inline-block h-5 w-5 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                                  role="status"
                                >
                                  <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                                    Loading...
                                  </span>
                                </span>
                                Loading...
                              </button>
                            ) : (
                              <button
                                type="submit"
                                className="w-full px-4 py-3 mt-4 font-bold bg-h28_dark_green text-[#ffffff] rounded-md"
                              >
                                Withdraw
                              </button>
                            )}
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>,
      document.body
    );
  } else {
    return null;
  }
};

export default QuickWithdraw;
