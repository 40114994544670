import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as BsIcon from "react-icons/bs";

import QuickSave from "../components/modals/QuickSave";
import { prettyDate } from "../utils/helpers";
import {
  getPortfolioBalance,
  reset as portfolioBalanceReset,
} from "../features/portfolioBalance/portfolioBalanceSlice";
import { Link } from "react-router-dom";
import {
  getWalletBalance,
  reset as walletBalanceReset,
} from "../features/walletBalance/walletBalanceSlice";
import Withdrawal from "../components/modals/Withdrawal";
import { getProducts } from "../features/products/productSlice";

const Portfolio = () => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [openWithdraw, setOpenWithdraw] = useState(false);

  const { wallet_balance } = useSelector((state) => state.wallet_balance);

  const { recent_transactions } = useSelector(
    (state) => state.recent_transactions
  );

  const balanceIndex = wallet_balance.find(
    (wallet) => wallet.currencyCode === "NGN"
  );

  const { portfolio_balance } = useSelector((state) => state.portfolio_balance);

  const { products } = useSelector((state) => state.products);

  const result = products.reduce(function (acc, obj) {
    return acc + obj.currentValue;
  }, 0);

  const date = new Date();
  date.setDate(date.getDate() - 1);
  console.log(date.toISOString());

  useEffect(() => {
    dispatch(getWalletBalance());
    return () => {
      dispatch(walletBalanceReset());
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(getProducts());
    return () => {
      dispatch(getProducts());
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(getPortfolioBalance());
    return () => {
      dispatch(portfolioBalanceReset());
    };
  }, []);

  return (
    <>
      <div className="flex flex-col gap-5 rounded-md">
        <div className="flex flex-row justify-between">
          <div>
            <h4 className="text-2xl font-bold text-h28_dark_green">
              Portfolio
            </h4>
          </div>
          <Link to="/transactions" className="bg-white px-3 py-2 rounded-3xl">
            <span className="flex flex-row gap-2 items-center">
              <h4 className="text-md text-gray-500">Transactions</h4>
              <BsIcon.BsFillBookmarksFill
                className="text-h28_light_green_2"
                size={16}
              />
            </span>
          </Link>
        </div>
        <div className="flex flex-col md:flex-row gap-10">
          <div className="flex flex-col bg-h28_dark_green rounded-lg p-5 w-full md:w-1/2">
            <div className="flex flex-row w-full justify-between">
              <span>
                <p className="text-gray-200">Portfolio balance</p>
                <span className="flex flex-row mt-3">
                  <p className="text-xl font-bold text-white">₦</p>
                  <h3 className="text-4xl font-bold md:ml-1 ml-0 text-white">
                    {result != null
                      ? new Intl.NumberFormat().format(
                          parseFloat(result).toFixed(2)
                        )
                      : parseFloat(0.0).toFixed(2)}
                  </h3>
                </span>
              </span>
            </div>

            <div className="flex flex-row w-full mt-10 gap-5">
              <Link
                to="/invest"
                className="bg-h28_light_green_2 rounded-lg p-3 w-full flex flex-row justify-center"
              >
                <p className="text-h28_dark_green font-semibold">
                  Create New Plan
                </p>
              </Link>
              <button
                type="button"
                onClick={() => setOpenWithdraw(true)}
                className="bg-white rounded-lg p-3 w-full flex flex-row justify-center"
              >
                <p className="text-h28_dark_green font-semibold">
                  Redeem from Product
                </p>
              </button>
            </div>
          </div>
          <div className="flex flex-col w-full md:w-1/2 h-52 p-5 bg-white rounded-lg">
            <div className="flex flex-row justify-between">
              <span>
                <p className="text-sm text-h28_dark_green font-semibold">
                  My Portfolio
                </p>
              </span>
            </div>
            <div className="flex flex-row justify-between items-center mt-6">
              <span>
                <svg height="100" width="100" viewBox="0 0 20 20">
                  <circle r="10" cx="10" cy="10" fill="#1D4165" />
                  <circle
                    r="5"
                    cx="10"
                    cy="10"
                    fill="transparent"
                    stroke="#44C3D2"
                    strokeWidth="10"
                    strokeDasharray="calc(35 * 31.4 / 100) 31.4"
                    transform="rotate(-90) translate(-20)"
                  />
                  <circle r="5" cx="10" cy="10" fill="#fff" />
                </svg>
              </span>
              <div className="flex flex-col space-y-4">
                <div className="flex flex-row items-center">
                  <div className="w-1.5 h-1.5 rounded-full mr-2 bg-h28_dark_green"></div>
                  <p className="text-sm text-neutral-500">Wallet balance</p>
                </div>
                <div className="flex flex-row items-center">
                  <div className="w-1.5 h-1.5 rounded-full mr-2 bg-h28_light_green_2"></div>
                  <p className="text-sm text-neutral-500">Investment</p>
                </div>
              </div>
              <div className="flex flex-col space-y-4">
                <span>
                  <p className="text-sm text-h28_dark_green font-semibold">
                    ₦{" "}
                    {balanceIndex?.amount != null
                      ? new Intl.NumberFormat().format(
                          parseFloat(balanceIndex?.amount).toFixed(2)
                        )
                      : parseFloat(0.0).toFixed(2)}
                  </p>
                </span>
                <span>
                  <p className="text-sm text-h28_light_green_2 font-semibold">
                    ₦{" "}
                    {result != null
                      ? new Intl.NumberFormat().format(
                          parseFloat(result).toFixed(2)
                        )
                      : parseFloat(0.0).toFixed(2)}
                  </p>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-row justify-between">
              <span>
                <p className="text-lg text-h28_dark_green font-semibold">
                  Investment Portfolio
                </p>
              </span>
            </div>
        <div className="flex flex-col p-5 rounded-lg bg-white">
          {/* <div className="flex flex-row">
            {products.map((product, index) => (
              <div  onClick={() => setActiveTab(product.portfolioName)} className={activeTab === product.portfolioName ? active : normal} key={index}>
                <h3 className="text-xs md:text-xl font-medium  mx-5 my-2">
                  {product.portfolioName}
                </h3>
              </div>
            ))}
          </div> */}
          
          <div className="flex flex-col mt-5 gap-5">
            {products.length >= 1 ? (
              <div className="flex flex-col gap-5 cursor-pointer">
                {products.map((product, index) => (
                  <Link
                    to={`/portfolio/${product.portfolioId}`}
                    key={index}
                    className="flex flex-row justify-between gap-2"
                  >
                    <div className="flex flex-row items-center gap-2">
                      <div className="w-10 h-10 bg-blue-50 p-5 rounded-full"></div>
                      <div>
                        <h3 className="text-h28_dark_green font-semibold text-xs md:text-md">
                          {product.portfolioName}
                        </h3>
                       
                      </div>
                    </div>
                    <div className="flex flex-col items-end">
                      <h4 className="text-md text-h28_dark_green font-bold">
                        ₦{" "}
                        {new Intl.NumberFormat().format(
                          parseFloat(product.currentValue).toFixed(2)
                        )}
                      </h4>
                      <h5 className="text-xs md:text-md text-h28_light_green_2">
                        View statement
                      </h5>
                        {/* {prettyDate(p_balance.reportDate)} */}
                      
                    </div>
                  </Link>
                ))}
              </div>
            ) : (
              <div className="flex flex-col justify-center items-center w-full h-full">
                <span>
                  <BsIcon.BsFillCalendarMinusFill
                    size={35}
                    className="text-gray-400"
                  />
                </span>
                <div className="flex items-center ">
                  <h4>No transactions to display yet</h4>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {openWithdraw && (
        <Withdrawal
          open={openWithdraw}
          setOpenWithdraw={setOpenWithdraw}
          portfolioData={portfolio_balance}
        />
      )}
    </>
  );
};

export default Portfolio;
