import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import bankAccountService from "./bankAccountService";

//const banks = JSON.parse(localStorage.getItem("banks"));

const initialState = {
  bank_accounts: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

//Get products

export const getBankAccount = createAsyncThunk(
  "api/get_bank_account",
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      //const user_id = thunkAPI.getState().auth.user.user_id;
      return await bankAccountService.getBankAccount({ token });
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const saveBankAccount = createAsyncThunk(
  "api/save_bank_account",
  async (formData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await bankAccountService.saveBankAccount({
        token,
        formData,
      });
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const bankAccountSlice = createSlice({
  name: "bank_accounts",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder

      .addCase(getBankAccount.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getBankAccount.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.bank_accounts = action.payload;
      })
      .addCase(getBankAccount.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.bank_accounts = [];
      })
      .addCase(saveBankAccount.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(saveBankAccount.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(saveBankAccount.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset } = bankAccountSlice.actions;
export default bankAccountSlice.reducer;
