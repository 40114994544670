import React, { useLayoutEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import * as FaIcon from "react-icons/fa6";
import AddBank from "../../components/modals/AddBank";
import {
  getBankAccount,
  reset,
} from "../../features/bankAccount/bankAccountSlice";

const BankDetails = () => {
  const [openAddBank, setOpenAddBank] = useState(false);

  const { bank_accounts, isError, message } = useSelector(
    (state) => state.bank_accounts
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    dispatch(getBankAccount());
    return () => {
      dispatch(reset());
    };
  }, [message]);

  return (
    <>
      <div className="bg-white rounded-lg flex flex-col p-10">
        <div className="flex flex-row mb-5">
          <Link
            className="flex flex-row items-center gap-1 text-gray-500 hover:text-h28_dark_green"
            to="/settings"
          >
            <span>
              <FaIcon.FaAngleLeft size={13} className="" />
            </span>
            <h4>Back</h4>
          </Link>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
          <div
            onClick={() => setOpenAddBank(true)}
            className="flex flex-col items-center justify-center border-2 hover:text-h28_dark_green border-gray-300 hover:border-h28_dark_green w-full h-[14rem] rounded-xl cursor-pointer"
          >
            <span>+</span>
            <h4>Add bank account</h4>
          </div>
          {bank_accounts.map((bank_account) => (
            <div className="flex flex-row p-5 justify-between bg-h28_dark_green text-white w-full h-[14rem] rounded-xl cursor-pointer">
              <div className="flex flex-row justify-between">
                <span className="flex flex-col justify-end">
                  <h4>{bank_account.bankName}</h4>
                  <h4>{bank_account.beneficiaryAccountNo}</h4>
                  <h4>{bank_account.beneficiaryName}</h4>
                </span>
              </div>
              <div className="flex flex-col justify-between">
                <p className="text-2xl font-bold">₦</p>
                <span>
                  <FaIcon.FaBuildingColumns size={25} className="" />
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
      {openAddBank && (
        <AddBank open={openAddBank} setOpenAddBank={setOpenAddBank} />
      )}
    </>
  );
};

export default BankDetails;
