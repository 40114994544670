import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import withdrawalService from "./withdrawalService";

//const banks = JSON.parse(localStorage.getItem("banks"));

const initialState = {
  withdrawal: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

//Get products

export const initiateWithdrawal = createAsyncThunk(
  "api/initiate_withdrawal",
  async (formData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      //const user_id = thunkAPI.getState().auth.user.user_id;
      return await withdrawalService.initiateWithdrawal({ token, formData });
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.Message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const walletWithdrawal = createAsyncThunk(
  "api/wallet_withdrawal",
  async (formData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      //const user_id = thunkAPI.getState().auth.user.user_id;
      return await withdrawalService.walletWithdrawal({ token, formData });
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.Message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// export const saveBankAccount = createAsyncThunk(
//   "api/save_bank_account",
//   async (formData, thunkAPI) => {
//     try {
//       const token = thunkAPI.getState().auth.user.token;
//       return await bankAccountService.saveBankAccount({
//         token,
//         formData,
//       });
//     } catch (error) {
//       const message =
//         (error.response &&
//           error.response.data &&
//           error.response.data.message) ||
//         error.message ||
//         error.toString();
//       return thunkAPI.rejectWithValue(message);
//     }
//   }
// );

export const withdrawalSlice = createSlice({
  name: "withdrawal",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(walletWithdrawal.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(walletWithdrawal.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.withdrawal = [];
      })
      .addCase(walletWithdrawal.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.withdrawal = [];
      })
      .addCase(initiateWithdrawal.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(initiateWithdrawal.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.withdrawal = action.payload;
      })
      .addCase(initiateWithdrawal.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.withdrawal = [];
      });
  },
});

export const { reset } = withdrawalSlice.actions;
export default withdrawalSlice.reducer;
