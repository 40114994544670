import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as FaIcon from "react-icons/fa6";
import { alertActions } from "../../app/store";
import { addSubscription } from "../../features/subscription/subscriptionSlice";

const Review = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { amount, name, portfolioId, pricePerUnit } = location.state;
  const processing_fee = 0;
  const units = amount / pricePerUnit;
  const total_due = amount + processing_fee;

  const handleSubmit = async () => {
    dispatch(alertActions.clear());

    try {
      setLoading(true);

      await dispatch(
        addSubscription({ amount: total_due, portfolioId })
      ).unwrap();

      navigate("/portfolio");
      dispatch(
        alertActions.success({
          message: "Subscription was successful",
          showAfterRedirect: true,
        })
      );
      setLoading(false);
    } catch (error) {
      dispatch(alertActions.error(error));
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col bg-white rounded-lg p-5 md:p-10 gap-5">
      <div className="flex flex-row">
        <Link
          className="flex flex-row items-center gap-1 text-gray-500 hover:text-h28_dark_green"
          to="/invest"
        >
          <span>
            <FaIcon.FaAngleLeft size={13} className="" />
          </span>
          <h4>Back</h4>
        </Link>
      </div>
      <div className="flex flex-row justify-between items-center">
        <div className="flex flex-row justify-between">
          <div>
            <h3 className="text-2xl font-bold text-h28_dark_green">{name}</h3>
            <p className="text-md font-semibold text-gray-500">
              {new Intl.NumberFormat().format(parseFloat(units).toFixed(2))}{" "}
              units
            </p>
          </div>
        </div>
        <span className="flex items-center justify-center p-1.5 bg-blue-50 rounded-2xl">
          <svg
            width="60"
            height="60"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M24.6372 16.7001C24.3122 16.3251 23.8372 16.1126 23.3497 16.1126H18.8747C16.6747 16.1126 14.8872 14.3251 14.8872 12.1251V7.65007C14.8872 7.16257 14.6747 6.68757 14.2997 6.36257C13.9372 6.03757 13.4372 5.88757 12.9622 5.95007C10.0247 6.32507 7.32469 7.93757 5.56219 10.3626C3.78719 12.8001 3.13719 15.7751 3.69969 18.7501C4.51219 23.0501 7.94969 26.4876 12.2622 27.3001C12.9497 27.4376 13.6372 27.5001 14.3247 27.5001C16.5872 27.5001 18.7747 26.8001 20.6372 25.4376C23.0622 23.6751 24.6747 20.9751 25.0497 18.0376C25.1122 17.5501 24.9622 17.0626 24.6372 16.7001Z"
              fill="#1D4165"
            />
            <path
              d="M26 7.92188V8.70313H18V7.92188H26ZM26 9.60938V10.3906H18V9.60938H26ZM25.3608 5V13H23.8941L20.4039 7.96484H20.3412V13H18.6471V5H20.1333L23.6039 10.0312H23.6706V5H25.3608Z"
              fill="#44C3D2"
            />
          </svg>
        </span>
      </div>
      <div className="flex flex-col bg-blue-50 rounded-xl p-5 mt-5 gap-5">
        <div className="flex flex-row items-center justify-between">
          <span>
            <h4 className="text-gray-500">Amount</h4>
          </span>
          <span>
            <p className="text-h28_dark_green text-lg font-bold">
              ₦{new Intl.NumberFormat().format(parseFloat(amount).toFixed(2))}
            </p>
          </span>
        </div>
        <hr />
        <div className="flex flex-row items-center justify-between">
          <span>
            <h4 className="text-gray-500">Number of units</h4>
          </span>
          <span>
            <p className="text-h28_dark_green text-lg font-bold">
              {new Intl.NumberFormat().format(parseFloat(units).toFixed(2))}
            </p>
          </span>
        </div>
        <hr className="bg-white" />
        <div className="flex flex-row items-center justify-between">
          <span>
            <h4 className="text-gray-500">Total Due</h4>
          </span>
          <span>
            <p className="text-h28_dark_green text-lg font-bold">
              ₦
              {new Intl.NumberFormat().format(parseFloat(total_due).toFixed(2))}
            </p>
          </span>
        </div>
      </div>
      <div className="flex flex-col gap-4">
        <p>
          Redemptions during the Lock-up Period will attract a 20% penalty on
          accrued returns earned over the period.
        </p>
        <p>
          By tapping on the “Make Payment” button, you agree to have the Total
          Due deducted from your Wallet Balance to create this investment plan.
        </p>
      </div>
      <div>
        <div className="flex items-center mb-4">
          <input
            type="checkbox"
            value=""
            className="w-5 h-5 cursor-pointer text-h28_dark_green checked:bg-h28_dark_green bg-gray-100 rounded-xl border-gray-300"
            required
          />
          <label className="ml-2 text-md ">
            Yes, I agree to the terms above
          </label>
        </div>
        <div className="flex items-baseline justify-between">
          {loading ? (
            <button
              type="submit"
              className="w-full px-2 py-3.5 font-bold text-white bg-h28_green rounded-md flex items-center justify-center"
              disabled={loading}
            >
              <span
                className="mr-5 inline-block h-5 w-5 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                role="status"
              >
                <span class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                  Loading...
                </span>
              </span>
              Loading...
            </button>
          ) : (
            <button
              onClick={handleSubmit}
              className="w-full px-2 py-3.5 bg-h28_dark_green rounded-md text-white font-bold flex items-center justify-center"
            >
              Make Payment
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Review;
