import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import nokService from "./nokService";

//const kyc = JSON.parse(localStorage.getItem("kyc"));

const initialState = {
  nok: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

//Get products

export const getNok = createAsyncThunk("api/get_nok", async (_, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token;
    //const user_id = thunkAPI.getState().auth.user.user_id;
    return await nokService.getNok({ token });
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const setNok = createAsyncThunk(
  "api/nok_save",
  async (formData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await nokService.setNok({ token, formData });
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const nokSlice = createSlice({
  name: "nok",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(setNok.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(setNok.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.nok = action.payload;
      })
      .addCase(setNok.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.nok = null;
      })
      .addCase(getNok.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getNok.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.nok = action.payload;
      })
      .addCase(getNok.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.nok = [];
      });
  },
});

export const { reset } = nokSlice.actions;
export default nokSlice.reducer;
