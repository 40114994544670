import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import bankService from "./bankService";

const banks = JSON.parse(localStorage.getItem("banks"));

const initialState = {
  banks: banks ? banks : [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

//Get products

export const getBanks = createAsyncThunk("api/banks", async (_, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token;
    //const user_id = thunkAPI.getState().auth.user.user_id;
    return await bankService.getBanks({ token });
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const bankSlice = createSlice({
  name: "bank",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder

      .addCase(getBanks.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getBanks.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.banks = action.payload;
      })
      .addCase(getBanks.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.banks = [];
      });
  },
});

export const { reset } = bankSlice.actions;
export default bankSlice.reducer;
