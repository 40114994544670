import React, { useState } from "react";

//Components
import QuickSave from "../components/modals/QuickSave";

const Plans = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <div className="flex-1 flex-col space-y-10 ">
        <div className="flex flex-row justify-between ">
          <div>Back</div>
          <div>Edit Plan</div>
        </div>
        <div className="flex flex-row justify-between items-center w-full md:space-x-0 space-x-40">
          <div className="flex flex-col">
            <span className="flex flex-row items-center hover:cursor-pointer">
              <p className="text-green-500 font-light text-sm mr-1">
                Total Savings
              </p>
              <svg
                width="15"
                height="15"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.9188 8.17969H11.6888H6.07877C5.11877 8.17969 4.63877 9.33969 5.31877 10.0197L10.4988 15.1997C11.3288 16.0297 12.6788 16.0297 13.5088 15.1997L15.4788 13.2297L18.6888 10.0197C19.3588 9.33969 18.8788 8.17969 17.9188 8.17969Z"
                  fill="#4ac892"
                />
              </svg>
            </span>
            <span className="flex flex-row mt-5">
              <p className="text-xl font-bold text-h28_dark_green">₦</p>
              <h3 className="text-5xl font-bold md:ml-1 ml-0 text-h28_dark_green">
                0.00
              </h3>
            </span>
          </div>
          <div className="w-44">
            <button
              className="bg-h28_dark_green hover:bg-emerald-900 rounded-lg p-3 w-full"
              onClick={() => setIsOpen(true)}
            >
              <span className="text-white font-semibold tracking-wider w-full">
                Quick save
              </span>
            </button>
          </div>
        </div>
        <div className="flex flex-row justify-between space-x-20">
          <div className="flex flex-row justify-between border rounded-lg w-1/2 ">
            <div className="w-2/3 p-5">
              <h3 className="text-neutral-500">Account Number</h3>
              <h3>0012969332</h3>
              <h3 className="text-neutral-500">Sterling Bank</h3>
            </div>
            <div className="w-1/3 border-l p-5">
              <span>
                <p>C</p>
              </span>
              <h3>Copy to clipboard</h3>
            </div>
          </div>
          <div className="rounded-lg bg-emerald-50 w-1/2 p-5">
            <div className="flex flex-row justify-between">
              <h3 className="text-neutral-500 text-xs">Autosave Deposits</h3>
              <span className="bg-yellow-400 p-1 rounded">
                <p className="text-white text-xs">Monthly</p>
              </span>
            </div>
            <div>
              <span className="flex flex-row ">
                <p className="text-xl font-bold text-h28_dark_green">₦</p>
                <h3 className="text-5xl font-bold md:ml-1 ml-0 text-h28_dark_green">
                  0.00
                </h3>
              </span>
            </div>
          </div>
        </div>
        <div className="flex flex-col p-5 rounded-lg border">
          <div>
            <h3 className="text-xl font-medium text-h28_dark_green">
              My transactions
            </h3>
          </div>
          <div className="flex flex-row space-x-2 mt-5">
            <div className="flex justify-center w-1/4 bg-h28_dark_green text-white rounded-md">
              All
            </div>
            <div className="flex justify-center w-1/4 border hover:border-h28_grey_2 rounded-md">
              Credit
            </div>
            <div className="flex justify-center w-1/4 border hover:border-h28_grey_2 rounded-md">
              Debit
            </div>
            <div className="flex justify-center w-1/4 border hover:border-h28_grey_2 rounded-md">
              Interest
            </div>
          </div>
          <div className="flex flex-col mt-10 space-y-10">
            <div className="flex flex-row justify-between ">
              <div className="flex flex-row items-center space-x-4">
                <div className="w-10 h-10 bg-green-400 p-5 rounded-full"></div>
                <div>
                  <h3>Credit</h3>
                  <h5 className="text-xs text-neutral-500">
                    Wed, 23 Mar 2022 16:51:12 GMT
                  </h5>
                </div>
              </div>
              <div>
                <h3>₦ 10,000</h3>
              </div>
            </div>
            <div className="flex flex-row justify-between ">
              <div className="flex flex-row items-center space-x-4">
                <div className="w-10 h-10 bg-green-400 p-5 rounded-full"></div>
                <div>
                  <h3>Credit</h3>
                  <h5 className="text-xs text-neutral-500">
                    Wed, 23 Mar 2022 16:51:12 GMT
                  </h5>
                </div>
              </div>
              <div>
                <h3>₦ 10,000</h3>
              </div>
            </div>
            <div className="flex flex-row justify-between ">
              <div className="flex flex-row items-center space-x-4">
                <div className="w-10 h-10 bg-red-500 p-5 rounded-full"></div>
                <div>
                  <h3>Debit</h3>
                  <h5 className="text-xs text-neutral-500">
                    Wed, 23 Mar 2022 16:51:12 GMT
                  </h5>
                </div>
              </div>
              <div>
                <h3>₦- 10,000</h3>
              </div>
            </div>
            <div className="flex flex-row justify-between ">
              <div className="flex flex-row items-center space-x-4">
                <div className="w-10 h-10 bg-green-400 p-5 rounded-full"></div>
                <div>
                  <h3>Credit</h3>
                  <h5 className="text-xs text-neutral-500">
                    Wed, 23 Mar 2022 16:51:12 GMT
                  </h5>
                </div>
              </div>
              <div>
                <h3>₦ 10,000</h3>
              </div>
            </div>
            <div className="flex flex-row justify-between ">
              <div className="flex flex-row items-center space-x-4">
                <div className="w-10 h-10 bg-green-400 p-5 rounded-full"></div>
                <div>
                  <h3>Credit</h3>
                  <h5 className="text-xs text-neutral-500">
                    Wed, 23 Mar 2022 16:51:12 GMT
                  </h5>
                </div>
              </div>
              <div>
                <h3>₦ 10,000</h3>
              </div>
            </div>
            <div className="flex flex-row justify-between ">
              <div className="flex flex-row items-center space-x-4">
                <div className="w-10 h-10 bg-green-400 p-5 rounded-full"></div>
                <div>
                  <h3>Credit</h3>
                  <h5 className="text-xs text-neutral-500">
                    Wed, 23 Mar 2022 16:51:12 GMT
                  </h5>
                </div>
              </div>
              <div>
                <h3>₦ 10,000</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isOpen && <QuickSave open={isOpen} setIsOpen={setIsOpen} />}
    </>
  );
};

export default Plans;
