import axios from "axios";

const BaseUrl = process.env.REACT_APP_BASE_API_URL;

//Get products from api
const getProducts = async ({ token }) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(
    `${BaseUrl}/getclientinvestibleproducts`,
    config
  );

  localStorage.setItem("products", JSON.stringify(response.data));

  return response.data;
};

const getOneProduct = async ({ token, id }) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(
    `${BaseUrl}/getliabilityproducts/${id}`,
    config
  );

  localStorage.setItem("products", JSON.stringify(response.data));

  return response.data;
};

const setProduct = async ({ token, formData }) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(`${BaseUrl}/user-kyc`, formData, config);
  return response.data;
};

const productService = {
  getProducts,
  getOneProduct,
  setProduct,
};

export default productService;
