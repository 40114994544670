import React, { useState } from "react";
import InvestmentPlans from "../components/invest/plans/InvestmentPlans";
import MutualFund from "../components/invest/plans/MutualFund";

const Invest = () => {
  const [activeTab, setActiveTab] = useState(1);

  const active =
    "text-h28_dark_green -m-0.5 border-b-2 border-h28_dark_green font-bold text-white hover:cursor-pointer";
  const normal = "text-h28_dark_green hover:cursor-pointer";

  return (
    <>
      <div className="flex-1 flex-col space-y-10">
        <div className="flex flex-row justify-between items-center">
          <span>
            <h3 className="text-2xl font-bold text-h28_dark_green">
              Investment Options
            </h3>
            <p className="text-gray-500">
              Select your preferred investment option
            </p>
          </span>
          <span>
            {/* <svg
              width="207"
              height="100"
              viewBox="0 0 107 81"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M30.6739 80.1661C47.6146 80.1661 61.3478 66.433 61.3478 49.4922C61.3478 32.5515 47.6146 18.8184 30.6739 18.8184C13.7332 18.8184 0 32.5515 0 49.4922C0 66.433 13.7332 80.1661 30.6739 80.1661ZM30.4858 74.5206C44.4125 74.5206 55.7024 63.2308 55.7024 49.304C55.7024 35.3773 44.4125 24.0875 30.4858 24.0875C16.5591 24.0875 5.26924 35.3773 5.26924 49.304C5.26924 63.2308 16.5591 74.5206 30.4858 74.5206Z"
                fill="#1D4165"
                fillOpacity="0.2"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M53.2559 61.3478C70.1966 61.3478 83.9298 47.6146 83.9298 30.6739C83.9298 13.7332 70.1966 0 53.2559 0C36.3152 0 22.582 13.7332 22.582 30.6739C22.582 47.6146 36.3152 61.3478 53.2559 61.3478ZM53.0677 55.7023C66.9944 55.7023 78.2842 44.4124 78.2842 30.4857C78.2842 16.559 66.9944 5.26911 53.0677 5.26911C39.1409 5.26911 27.8511 16.559 27.8511 30.4857C27.8511 44.4124 39.1409 55.7023 53.0677 55.7023Z"
                fill="#1D4165"
                fillOpacity="0.2"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M75.8379 80.1661C92.7787 80.1661 106.512 66.433 106.512 49.4922C106.512 32.5515 92.7787 18.8184 75.8379 18.8184C58.8972 18.8184 45.1641 32.5515 45.1641 49.4922C45.1641 66.433 58.8972 80.1661 75.8379 80.1661ZM75.65 74.5206C89.5767 74.5206 100.867 63.2308 100.867 49.304C100.867 35.3773 89.5767 24.0875 75.65 24.0875C61.7232 24.0875 50.4334 35.3773 50.4334 49.304C50.4334 63.2308 61.7232 74.5206 75.65 74.5206Z"
                fill="#1D4165"
                fillOpacity="0.2"
              />
            </svg> */}
          </span>
        </div>
        <div className="flex flex-row space-x-3 border-b-2 border-h28_light_green_2 ">
          <div
            className={activeTab === 1 ? active : normal}
            onClick={() => setActiveTab(1)}
          >
            <span className="flex flex-row items-center">
              <p className="text-xl leading-7 ">Mutual Funds</p>
            </span>
          </div>
          <div
            className={activeTab === 2 ? active : normal}
            onClick={() => setActiveTab(2)}
          >
            <span className="flex flex-row items-center">
              <p className=" text-xl leading-7">Investment Plans</p>
            </span>
          </div>
        </div>
        <div className="flex flex-row space-x-10">
          <div className="w-full">
            {activeTab === 1 ? <MutualFund /> : <InvestmentPlans />}
          </div>
        </div>
      </div>
    </>
  );
};

export default Invest;
