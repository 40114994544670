import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, NavLink, useNavigate, Navigate } from "react-router-dom";
import { alertActions } from "../app/store";
import logo from "../assets/images/Group 1846.svg";

import { logout, reset } from "../features/auth/authSlice";
import { getKYC, reset as resetKyc } from "../features/kyc/kycSlice";
import {
  getProfile,
  reset as profileReset,
} from "../features/profile/profileSlice";

const MainLayout = () => {
  const { user, isError, message } = useSelector((state) => state.auth);
  const { profile } = useSelector((state) => state.profile);
  const { status } = useSelector((state) => state.kyc);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  //const [isActive, setIsActive] = useState(0);
  //console.log(status);
  useEffect(() => {
    dispatch(getProfile());
    return () => {
      dispatch(profileReset());
    };
  }, [dispatch]);

  if (status?.status === false) {
    return <Navigate to="/onboarding" />;
  }

  setTimeout(() => {
    dispatch(reset());
    dispatch(logout());
    dispatch(
      alertActions.error({
        message: "Session ended. Please login again.",
        showAfterRedirect: true,
      })
    );
    navigate("/login");
  }, 60 * 60 * 500);

  // if (kyc.status === false) {
  //   return <Navigate to="/onboarding" />;
  // }

  const onLogout = () => {
    dispatch(reset());
    dispatch(logout());
    dispatch(
      alertActions.success({
        message: "Successfully logged out",
        showAfterRedirect: true,
      })
    );
    navigate("/login");
  };

  const myStyle = {
    backgroundColor: "#f9f9f9",
    backgroundImage: `url("../assets/images/logo_main-2.svg")`,
  };

  const activeLink =
    "flex space-x-3 items-center  text-md py-3 px-6 my-1 border-l-4 border-h28_dark_green border-white bg-h28_green transition duration-200";
  const normalLink =
    "flex space-x-3 items-center text-md py-3 px-6 my-1 border-l-4 border-h28_dark_green hover:border-white hover:bg-h28_green transition duration-200";

  const onClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="min-h-screen font-gabarito antialiased lg:flex bg-h28_light_green text-gray-500 overflow-x-0">
      <aside
        className={`absolute ${
          !isOpen ? "-translate-x-full" : "-translate-x-0"
        } inset-y-0 left-0 overflow-x-hidden overflow-y-auto transform md:transform-none lg:relative z-10 w-[20rem] bg-h28_dark_green text-white md:min-h-screen min-h-0 `}
      >
        <nav className="">
          <div className="flex justify-between">
            <span className="text-2xl sm:text-2xl font-bold p-6 flex flex-row items-center space-x-2">
              <img className="w-24" src={logo} alt="logo" />
            </span>
            <button
              onClick={onClick}
              className="p-2 focus:outline-none md:hidden focus:bg-black hover:bg-black cursor-pointer rounded-md"
            >
              <svg
                className="w-7 h-7"
                width="7"
                height="7"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.9993 20.67C14.8093 20.67 14.6193 20.6 14.4693 20.45L7.9493 13.93C6.8893 12.87 6.8893 11.13 7.9493 10.07L14.4693 3.55002C14.7593 3.26002 15.2393 3.26002 15.5293 3.55002C15.8193 3.84002 15.8193 4.32002 15.5293 4.61002L9.0093 11.13C8.5293 11.61 8.5293 12.39 9.0093 12.87L15.5293 19.39C15.8193 19.68 15.8193 20.16 15.5293 20.45C15.3793 20.59 15.1893 20.67 14.9993 20.67Z"
                  fill="currentColor"
                />
              </svg>
            </button>
          </div>
          <div className="flex flex-col justify-between mt-12 h-[40rem] md:h-[47rem]">
            <div className="">
              <div className="py-3 px-8 my-5">
                <h3 className="font-bold text-xl text-gray-300">
                  Hello <span className="text-white">{profile?.firstname}</span>
                </h3>
              </div>
              <NavLink
                onClick={onClick}
                to="/"
                end
                className={({ isActive }) =>
                  isActive ? activeLink : normalLink
                }
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.9"
                    d="M20.83 8.01002L14.28 2.77002C13 1.75002 11 1.74002 9.72996 2.76002L3.17996 8.01002C2.23996 8.76002 1.66996 10.26 1.86996 11.44L3.12996 18.98C3.41996 20.67 4.98996 22 6.69996 22H17.3C18.99 22 20.59 20.64 20.88 18.97L22.14 11.43C22.32 10.26 21.75 8.76002 20.83 8.01002Z"
                    fill="#44C3D2"
                  />
                  <path
                    d="M12 18.75C11.59 18.75 11.25 18.41 11.25 18V15C11.25 14.59 11.59 14.25 12 14.25C12.41 14.25 12.75 14.59 12.75 15V18C12.75 18.41 12.41 18.75 12 18.75Z"
                    fill="#1D4165"
                  />
                </svg>
                <span className="text-lg">Dashboard</span>
              </NavLink>

              <NavLink
                onClick={onClick}
                to="/invest"
                className={({ isActive }) =>
                  isActive ? activeLink : normalLink
                }
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM9.11 16.9C9.11 17.18 8.89 17.4 8.61 17.4H5.82C5.54 17.4 5.32 17.18 5.32 16.9V12.28C5.32 11.65 5.83 11.14 6.46 11.14H8.61C8.89 11.14 9.11 11.36 9.11 11.64V16.9ZM13.89 16.9C13.89 17.18 13.67 17.4 13.39 17.4H10.6C10.32 17.4 10.1 17.18 10.1 16.9V7.74C10.1 7.11 10.61 6.6 11.24 6.6H12.76C13.39 6.6 13.9 7.11 13.9 7.74V16.9H13.89ZM18.68 16.9C18.68 17.18 18.46 17.4 18.18 17.4H15.39C15.11 17.4 14.89 17.18 14.89 16.9V13.35C14.89 13.07 15.11 12.85 15.39 12.85H17.54C18.17 12.85 18.68 13.36 18.68 13.99V16.9Z"
                    fill="#44C3D2"
                  />
                </svg>
                <span className="text-lg">Invest</span>
              </NavLink>

              <NavLink
                onClick={onClick}
                to="/portfolio"
                className={({ isActive }) =>
                  isActive ? activeLink : normalLink
                }
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21.091 6.97953C20.241 6.03953 18.821 5.56953 16.761 5.56953H16.521V5.52953C16.521 3.84953 16.521 1.76953 12.761 1.76953H11.241C7.48101 1.76953 7.48101 3.85953 7.48101 5.52953V5.57953H7.24101C5.17101 5.57953 3.76101 6.04953 2.91101 6.98953C1.92101 8.08953 1.95101 9.56953 2.05101 10.5795L2.06101 10.6495L2.13847 11.4628C2.15273 11.6126 2.2334 11.7479 2.35929 11.8303C2.59909 11.9872 3.00044 12.2459 3.24101 12.3795C3.38101 12.4695 3.53101 12.5495 3.68101 12.6295C5.39101 13.5695 7.27101 14.1995 9.18101 14.5095C9.27101 15.4495 9.68101 16.5495 11.871 16.5495C14.061 16.5495 14.491 15.4595 14.561 14.4895C16.601 14.1595 18.571 13.4495 20.351 12.4095C20.411 12.3795 20.451 12.3495 20.501 12.3195C20.8977 12.0953 21.3093 11.819 21.6845 11.5484C21.7975 11.4668 21.8698 11.3408 21.8852 11.2023L21.901 11.0595L21.951 10.5895C21.961 10.5295 21.961 10.4795 21.971 10.4095C22.051 9.39953 22.031 8.01953 21.091 6.97953ZM13.091 13.8295C13.091 14.8895 13.091 15.0495 11.861 15.0495C10.631 15.0495 10.631 14.8595 10.631 13.8395V12.5795H13.091V13.8295ZM8.91101 5.56953V5.52953C8.91101 3.82953 8.91101 3.19953 11.241 3.19953H12.761C15.091 3.19953 15.091 3.83953 15.091 5.52953V5.57953H8.91101V5.56953Z"
                    fill="#44C3D2"
                  />
                  <path
                    d="M20.8733 13.7349C21.2269 13.5666 21.6342 13.8469 21.5988 14.2369L21.2398 18.1907C21.0298 20.1907 20.2098 22.2307 15.8098 22.2307H8.18984C3.78984 22.2307 2.96984 20.1907 2.75984 18.2007L2.41913 14.4529C2.38409 14.0674 2.78205 13.7874 3.13468 13.947C4.2741 14.4625 6.37724 15.3771 7.67641 15.7174C7.84072 15.7604 7.97361 15.878 8.04556 16.0319C8.65253 17.33 9.96896 18.0207 11.8698 18.0207C13.752 18.0207 15.085 17.3034 15.694 16.0021C15.766 15.8481 15.8991 15.7305 16.0635 15.6873C17.443 15.3243 19.6816 14.3019 20.8733 13.7349Z"
                    fill="#44C3D2"
                  />
                </svg>

                <span className="text-lg">Portfolio</span>
              </NavLink>
              <NavLink
                onClick={onClick}
                to="/settings"
                className={({ isActive }) =>
                  isActive ? activeLink : normalLink
                }
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.0002 14C6.99016 14 2.91016 17.36 2.91016 21.5C2.91016 21.78 3.13016 22 3.41016 22H20.5902C20.8702 22 21.0902 21.78 21.0902 21.5C21.0902 17.36 17.0102 14 12.0002 14Z"
                    fill="#44C3D2"
                  />
                  <path
                    d="M16.72 10.35L15.76 9.39C16.26 8.64 16.55 7.74 16.55 6.77C16.55 4.13 14.41 2 11.78 2C9.15 2 7 4.14 7 6.77C7 9.4 9.14 11.54 11.77 11.54C12.74 11.54 13.64 11.25 14.39 10.75L15.35 11.71C15.54 11.9 15.79 11.99 16.03 11.99C16.28 11.99 16.53 11.9 16.71 11.71C17.09 11.34 17.09 10.73 16.72 10.35Z"
                    fill="#44C3D2"
                  />
                </svg>

                <span className="text-lg">Settings</span>
              </NavLink>
            </div>
            <div>
              <button
                onClick={onLogout}
                className="flex space-x-3 py-2.5 px-6 my-3 text-md items-center border-l-4 border-h28_dark_green  hover:border-white hover:bg-h28_green transition duration-200 w-full mt-76"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.8 2H14.2C11 2 9 4 9 7.2V11.25H15.25C15.66 11.25 16 11.59 16 12C16 12.41 15.66 12.75 15.25 12.75H9V16.8C9 20 11 22 14.2 22H16.79C19.99 22 21.99 20 21.99 16.8V7.2C22 4 20 2 16.8 2Z"
                    fill="#44C3D2"
                  />
                  <path
                    d="M4.56141 11.2498L6.63141 9.17984C6.78141 9.02984 6.85141 8.83984 6.85141 8.64984C6.85141 8.45984 6.78141 8.25984 6.63141 8.11984C6.34141 7.82984 5.86141 7.82984 5.57141 8.11984L2.22141 11.4698C1.93141 11.7598 1.93141 12.2398 2.22141 12.5298L5.57141 15.8798C5.86141 16.1698 6.34141 16.1698 6.63141 15.8798C6.92141 15.5898 6.92141 15.1098 6.63141 14.8198L4.56141 12.7498H9.00141V11.2498H4.56141Z"
                    fill="#44C3D2"
                  />
                </svg>

                <span className="text-lg">Logout</span>
              </button>
            </div>
          </div>
        </nav>
      </aside>

      <div className="relative z-0 lg:flex-grow">
        <header className="flex h-10 md:h-18 bg-h28_light_green items-center px-3 justify-between">
          <button
            onClick={onClick}
            className="p-2 md:hidden cursor-pointer rounded-md"
          >
            <svg
              className="w-8 h-8"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21 7.75H3C2.59 7.75 2.25 7.41 2.25 7C2.25 6.59 2.59 6.25 3 6.25H21C21.41 6.25 21.75 6.59 21.75 7C21.75 7.41 21.41 7.75 21 7.75Z"
                fill="currentColor"
              />
              <path
                d="M21 12.75H3C2.59 12.75 2.25 12.41 2.25 12C2.25 11.59 2.59 11.25 3 11.25H21C21.41 11.25 21.75 11.59 21.75 12C21.75 12.41 21.41 12.75 21 12.75Z"
                fill="currentColor"
              />
              <path
                d="M21 17.75H3C2.59 17.75 2.25 17.41 2.25 17C2.25 16.59 2.59 16.25 3 16.25H21C21.41 16.25 21.75 16.59 21.75 17C21.75 17.41 21.41 17.75 21 17.75Z"
                fill="currentColor"
              />
            </svg>
          </button>
          <div className="flex flex-row">
            <span className=" text-2xl sm:text-3xl text-neutral-800 p-4 justify-end"></span>
          </div>
        </header>
        <div className="bg-cover">
          <main className="flex flex-col md:flex-row items-center justify-center gap-10 space-x-0 md:space-x-5 w-full p-5 md:p-28 text-gray-600 relative z-0 overflow-x-hidden overflow-y-scroll">
            <div className="w-full mx-10">
              <Outlet />
            </div>
            {/* <div className="w-full md:w-1/3 h-[760px] p-7 bg-white rounded-lg">
              <div className="flex flex-row justify-between items-center">
                <h4 className="text-sm text-h28_dark_green font-semibold">
                  Promo & Offers
                </h4>
                <span>
                  <p className="text-sm text-neutral-500">View all</p>
                </span>
              </div>
              <div className="bg-h28_dark_green h-24 rounded-md mt-5 mb-10"></div>
              <div className="mt-10">
                <h4 className="text-h28_dark_green font-semibold">
                  Quick Actions
                </h4>
              </div>
              <div className="flex flex-col space-y-3.5 mt-3">
                <div className="bg-h28_light_green p-3.5 border-l-4 border-h28_light_green_2 rounded-md">
                  <span className="flex flex-row items-center space-x-4">
                    <svg
                      width="37"
                      height="30"
                      viewBox="0 0 37 30"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M22 30C30.2843 30 37 23.2843 37 15C37 6.71573 30.2843 0 22 0C13.7157 0 7 6.71573 7 15C7 23.2843 13.7157 30 22 30ZM30.021 11.8387C30.4842 11.3647 30.4755 10.605 30.0015 10.1418C29.5275 9.67856 28.7678 9.6873 28.3046 10.1613L21.6628 16.9575L18.8582 14.0877C18.395 13.6137 17.6353 13.605 17.1613 14.0682C16.6873 14.5314 16.6786 15.2912 17.1418 15.7652L20.8046 19.5131C21.0304 19.7442 21.3397 19.8744 21.6628 19.8744C21.9858 19.8744 22.2952 19.7442 22.521 19.5131L30.021 11.8387Z"
                        fill="#1D4165"
                      />
                      <line
                        x1="1.2"
                        y1="10.8"
                        x2="12.7535"
                        y2="10.8"
                        stroke="#44C3D2"
                        stroke-width="2.4"
                        stroke-linecap="round"
                      />
                      <line
                        x1="3.99102"
                        y1="14.9855"
                        x2="12.7538"
                        y2="14.9855"
                        stroke="#44C3D2"
                        stroke-width="2.4"
                        stroke-linecap="round"
                      />
                      <line
                        x1="6.78203"
                        y1="19.1721"
                        x2="12.7541"
                        y2="19.1721"
                        stroke="#44C3D2"
                        stroke-width="2.4"
                        stroke-linecap="round"
                      />
                    </svg>
                    <p className="text-sm text-h28_dark_green">Refer & Earn</p>
                  </span>
                </div>
                <div className="bg-h28_light_green p-3.5 border-l-4 border-h28_light_green_2 rounded-md">
                  <span className="flex flex-row items-center space-x-4">
                    <svg
                      width="37"
                      height="30"
                      viewBox="0 0 37 30"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M22 30C30.2843 30 37 23.2843 37 15C37 6.71573 30.2843 0 22 0C13.7157 0 7 6.71573 7 15C7 23.2843 13.7157 30 22 30ZM30.021 11.8387C30.4842 11.3647 30.4755 10.605 30.0015 10.1418C29.5275 9.67856 28.7678 9.6873 28.3046 10.1613L21.6628 16.9575L18.8582 14.0877C18.395 13.6137 17.6353 13.605 17.1613 14.0682C16.6873 14.5314 16.6786 15.2912 17.1418 15.7652L20.8046 19.5131C21.0304 19.7442 21.3397 19.8744 21.6628 19.8744C21.9858 19.8744 22.2952 19.7442 22.521 19.5131L30.021 11.8387Z"
                        fill="#1D4165"
                      />
                      <line
                        x1="1.2"
                        y1="10.8"
                        x2="12.7535"
                        y2="10.8"
                        stroke="#44C3D2"
                        stroke-width="2.4"
                        stroke-linecap="round"
                      />
                      <line
                        x1="3.99102"
                        y1="14.9855"
                        x2="12.7538"
                        y2="14.9855"
                        stroke="#44C3D2"
                        stroke-width="2.4"
                        stroke-linecap="round"
                      />
                      <line
                        x1="6.78203"
                        y1="19.1721"
                        x2="12.7541"
                        y2="19.1721"
                        stroke="#44C3D2"
                        stroke-width="2.4"
                        stroke-linecap="round"
                      />
                    </svg>
                    <p className="text-sm text-h28_dark_green">
                      Do you have a question or need help?
                    </p>
                  </span>
                </div>
                <div className="bg-h28_light_green p-3.5 border-l-4 border-h28_light_green_2 rounded-md">
                  <span className="flex flex-row items-center space-x-4">
                    <svg
                      width="37"
                      height="30"
                      viewBox="0 0 37 30"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M22 30C30.2843 30 37 23.2843 37 15C37 6.71573 30.2843 0 22 0C13.7157 0 7 6.71573 7 15C7 23.2843 13.7157 30 22 30ZM30.021 11.8387C30.4842 11.3647 30.4755 10.605 30.0015 10.1418C29.5275 9.67856 28.7678 9.6873 28.3046 10.1613L21.6628 16.9575L18.8582 14.0877C18.395 13.6137 17.6353 13.605 17.1613 14.0682C16.6873 14.5314 16.6786 15.2912 17.1418 15.7652L20.8046 19.5131C21.0304 19.7442 21.3397 19.8744 21.6628 19.8744C21.9858 19.8744 22.2952 19.7442 22.521 19.5131L30.021 11.8387Z"
                        fill="#1D4165"
                      />
                      <line
                        x1="1.2"
                        y1="10.8"
                        x2="12.7535"
                        y2="10.8"
                        stroke="#44C3D2"
                        stroke-width="2.4"
                        stroke-linecap="round"
                      />
                      <line
                        x1="3.99102"
                        y1="14.9855"
                        x2="12.7538"
                        y2="14.9855"
                        stroke="#44C3D2"
                        stroke-width="2.4"
                        stroke-linecap="round"
                      />
                      <line
                        x1="6.78203"
                        y1="19.1721"
                        x2="12.7541"
                        y2="19.1721"
                        stroke="#44C3D2"
                        stroke-width="2.4"
                        stroke-linecap="round"
                      />
                    </svg>
                    <p className="text-sm text-h28_dark_green">
                      Send funds to a bank account or beneficiary
                    </p>
                  </span>
                </div>
              </div> 
            </div>
            */}
          </main>
        </div>
      </div>
    </div>
    // <div className="flex w-full min-h-screen antialiased font-open bg-neutral-100 relative overflow-x-hidden">
    //   <Sidebar />
    //   <main className=" w-full p-5 md:p-10 mt-5 text-zinc-600 relative z-0">
    //     <Outlet />
    //   </main>
    // </div>
  );
};

export default MainLayout;
