import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import Account from "../components/settings/Account";
import Results from "../components/settings/Results";
import Security from "../components/settings/Security";
import * as FaIcon from "react-icons/fa6";
import { getProfile, reset } from "../features/profile/profileSlice";
import {
  getVirtualAccount,
  reset as virtualAccountRest,
} from "../features/virtualAccount/virtualAccountSlice";
import KycAlert from "../components/modals/KycAlert";

const Settings = () => {
  const [activeTab, setActiveTab] = useState(1);
  const [openKYC, setOpenKYC] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { profile, message } = useSelector((state) => state.profile);
  const { virtual_account } = useSelector((state) => state.virtual_account);

  useEffect(() => {
    dispatch(getProfile());
    return () => {
      dispatch(reset());
    };
  }, [dispatch, message]);

  useEffect(() => {
    dispatch(getVirtualAccount());
    return () => {
      dispatch(virtualAccountRest());
    };
  }, []);

  const active =
    "bg-h28_dark_green p-2.5 text-white rounded-md hover:cursor-pointer";
  const normal = "text-neutral-600 p-2.5 rounded-md hover:cursor-pointer";

  return (
    <>
      <div className="flex flex-col gap-10">
        {/* <div className="flex flex-row space-x-3 bg-blue-100 p-3 w-2/3 rounded-lg">
        <div
          className={activeTab === 1 ? active : normal}
          onClick={() => setActiveTab(1)}
        >
          <span className="flex flex-row items-center">
            <p className="font-semibold text-xs uppercase">Account Settings</p>
          </span>
        </div>
        <div
          className={activeTab === 2 ? active : normal}
          onClick={() => setActiveTab(2)}
        >
          <span className="flex flex-row items-center">
            <p className="font-semibold text-xs uppercase">Security Settings</p>
          </span>
        </div>
        <div
          className={activeTab === 3 ? active : normal}
          onClick={() => setActiveTab(3)}
        >
          <span className="flex flex-row items-center">
            <p className="font-semibold text-xs uppercase">Other Settings</p>
          </span>
        </div>
      </div>
      <div className="p-10 rounded-md border border-blue-100">
        {activeTab === 1 ? (
          <Account />
        ) : activeTab === 2 ? (
          <Security />
        ) : (
          <Results />
        )}
      </div> */}
        <div className="flex flex-col items-center justify-center gap-2 rounded-lg bg-h28_dark_green p-5">
          <h3 className="font-bold text-white text-lg ">Profile</h3>
          <div className="w-16 h-16 rounded-full border-2 border-white mt-3 flex items-center justify-center text-white text-2xl font-bold">
            {/* {profile && profile?.firstname.charAt(0)}
          {profile && profile?.surname.charAt(0)} */}
          </div>
          {profile.clientType === "I" ? (
            <h4 className="text-md font-semibold text-gray-100">
              {profile.surname} {profile.firstname}
            </h4>
          ) : (
            <h4 className="text-md font-semibold text-gray-100">
              {profile.companyName}
            </h4>
          )}

          <p className="text-sm text-gray-200 -mt-3">{profile.emailAddress}</p>
        </div>
        <div className="flex md:flex-row flex-col gap-10">
          <div className="md:w-1/2 w-full flex flex-col gap-5">
            <h3 className="text-md font-semibold ">Wallet</h3>
            <div className="flex flex-row justify-between items-center bg-white rounded-lg p-5">
              <div className="flex fex-row items-center gap-3">
                <span>
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M22 19V22H2V19C2 18.45 2.45 18 3 18H21C21.55 18 22 18.45 22 19Z"
                      fill="#1D4165"
                      stroke="#1D4165"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path d="M7 11H5V18H7V11Z" fill="#1D4165" />
                    <path d="M11 11H9V18H11V11Z" fill="#44C3D2" />
                    <path d="M15 11H13V18H15V11Z" fill="#1D4165" />
                    <path d="M19 11H17V18H19V11Z" fill="#44C3D2" />
                    <path
                      d="M23 22.75H1C0.59 22.75 0.25 22.41 0.25 22C0.25 21.59 0.59 21.25 1 21.25H23C23.41 21.25 23.75 21.59 23.75 22C23.75 22.41 23.41 22.75 23 22.75Z"
                      fill="#1D4165"
                    />
                    <path
                      d="M21.37 5.74984L12.37 2.14984C12.17 2.06984 11.83 2.06984 11.63 2.14984L2.63 5.74984C2.28 5.88984 2 6.29984 2 6.67984V9.99984C2 10.5498 2.45 10.9998 3 10.9998H21C21.55 10.9998 22 10.5498 22 9.99984V6.67984C22 6.29984 21.72 5.88984 21.37 5.74984ZM12 8.49984C11.17 8.49984 10.5 7.82984 10.5 6.99984C10.5 6.16984 11.17 5.49984 12 5.49984C12.83 5.49984 13.5 6.16984 13.5 6.99984C13.5 7.82984 12.83 8.49984 12 8.49984Z"
                      fill="#44C3D2"
                    />
                  </svg>
                </span>
                <span className="flex flex-col">
                  {virtual_account.map((account) => (
                    <h3 className="font-bold">{account.virtualAccountNo}</h3>
                  ))}

                  <p className="text-sm -mt-1">Rand Merchant Bank</p>
                </span>
              </div>
              <div className="flex flex-row items-center gap-1">
                <span>
                  <FaIcon.FaRegCopy />
                </span>
                <p>Copy</p>
              </div>
            </div>
          </div>
          <div className="md:w-1/2 w-full flex flex-col gap-5">
            <h3 className="text-md font-semibold ">Account Settings</h3>
            <div className="flex flex-col gap-4 justify-between bg-white rounded-lg p-5">
              {profile.clientType === "C" ? (
                <>
                  <div className="flex flex-row justify-between items-center">
                    <div className="flex flex-row items-center gap-3">
                      <span className="bg-h28_light_green rounded-md p-2 w-20">
                        <FaIcon.FaBuildingUser
                          size={20}
                          className="text-h28_dark_green"
                        />
                      </span>
                      <p>Business Details</p>
                    </div>
                    <span>
                      <FaIcon.FaAngleRight
                        size={15}
                        className="text-gray-400"
                      />
                    </span>
                  </div>
                  <hr />
                </>
              ) : (
                <>
                  <Link
                    to="/settings/details"
                    className="flex flex-row justify-between items-center"
                  >
                    <div className="flex flex-row items-center gap-3">
                      <span className="bg-h28_light_green rounded-md p-2">
                        <FaIcon.FaUser
                          size={20}
                          className="text-h28_dark_green"
                        />
                      </span>
                      <p>Personal Details</p>
                    </div>
                    <span>
                      <FaIcon.FaAngleRight
                        size={15}
                        className="text-gray-400"
                      />
                    </span>
                  </Link>
                  <hr />
                </>
              )}

              <Link
                to="/settings/bank"
                className="flex flex-row justify-between items-center"
              >
                <div className="flex flex-row items-center gap-3">
                  <span className="bg-h28_light_green rounded-md p-2">
                    <FaIcon.FaMoneyBillWave
                      size={20}
                      className="text-h28_dark_green"
                    />
                  </span>
                  <p>Bank Details</p>
                </div>
                <span>
                  <FaIcon.FaAngleRight size={15} className="text-gray-400" />
                </span>
              </Link>
              <hr />
              <div
                onClick={() => setOpenKYC(true)}
                className="flex flex-row justify-between items-center cursor-pointer"
              >
                <div className="flex flex-row items-center gap-3">
                  <span className="bg-h28_light_green rounded-md p-2">
                    <FaIcon.FaFileCircleCheck
                      size={20}
                      className="text-h28_dark_green"
                    />
                  </span>
                  <p>KYC</p>
                </div>
                <span>
                  <FaIcon.FaAngleRight size={15} className="text-gray-400" />
                </span>
              </div>
              <hr />
              <div className="flex flex-row justify-between items-center">
                <div className="flex flex-row items-center gap-3">
                  <span className="bg-h28_light_green rounded-md p-2">
                    <FaIcon.FaHeadset
                      size={20}
                      className="text-h28_dark_green"
                    />
                  </span>
                  <p>Help and Support</p>
                </div>
                <span>
                  <FaIcon.FaAngleRight size={15} className="text-gray-400" />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {openKYC && <KycAlert open={openKYC} setOpenKycAlert={setOpenKYC} />}
    </>
  );
};

export default Settings;
