import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import portfolioBalanceService from "./portfolioBalanceService";

const initialState = {
  portfolio_balance: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

//Get products

export const getPortfolioBalance = createAsyncThunk(
  "api/get_portfolio_balance",
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      //const user_id = thunkAPI.getState().auth.user.user_id;
      return await portfolioBalanceService.getPortfolioBalance({ token });
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const portfolioBalanceSlice = createSlice({
  name: "portfolio_balance",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder

      .addCase(getPortfolioBalance.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPortfolioBalance.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.portfolio_balance = action.payload;
      })
      .addCase(getPortfolioBalance.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.portfolio_balance = [];
      });
  },
});

export const { reset } = portfolioBalanceSlice.actions;
export default portfolioBalanceSlice.reducer;
