import axios from "axios";

const BaseUrl = process.env.REACT_APP_BASE_API_URL;

//Get products from api
const resetPasswordRequest = async (userData) => {
  const response = await axios.post(
    `${BaseUrl}/resetpasswordrequest`,
    userData
  );

  return response.data;
};

const resetPassword = async (userData) => {
  const response = await axios.post(`${BaseUrl}/resetpassword`, userData);

  return response.data;
};

const forgotPasswordService = {
  resetPasswordRequest,
  resetPassword,
};

export default forgotPasswordService;
