import axios from "axios";

const BaseUrl = process.env.REACT_APP_BASE_API_URL;

//Get products from api
const addSubscription = async ({ token, formData }) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    `${BaseUrl}/mutualfundsubscriptionnoaccount`,
    formData,
    config
  );

  return response.data;
};

const subscriptionService = {
  addSubscription,
};

export default subscriptionService;
