import axios from "axios";

const BaseUrl = process.env.REACT_APP_BASE_API_URL;

//Get products from api
const getBanks = async ({ token }) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(`${BaseUrl}/getbanks`, config);

  localStorage.setItem("banks", JSON.stringify(response.data));

  return response.data;
};

const bankService = {
  getBanks,
};

export default bankService;
