import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import { alertActions } from "../../../app/store";
import { resetPassord } from "../../../features/forgotPassword/forgotPasswordSlice";

const ResetPassword = () => {
  const { token } = useParams();

  console.log(token);

  const initialValues = {
    password: "",
  };

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required("This field is required!")
      .min(6, "Password must be at least 6 characters")
      .matches(
        /(?=.*?[A-Z])/,
        "Password must contain at least one capital letter"
      )
      .matches(/(?=.*?[0-9])/, "Password must contain at least one number")
      .matches(
        /(?=.*?[#?!@$%^&*-])/,
        "Password must contain at least one special character"
      ),
    confirmpassword: Yup.string().oneOf(
      [Yup.ref("password")],
      "Passwords must match"
    ),
  });

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = async (formValue) => {
    const { password } = formValue;

    dispatch(alertActions.clear());

    try {
      setLoading(true);

      await dispatch(
        resetPassord({ password: password, token: token })
      ).unwrap();

      navigate("/login");
      dispatch(
        alertActions.success({
          message: "Please proceed to login",
          showAfterRedirect: true,
        })
      );
      setLoading(false);
    } catch (error) {
      dispatch(alertActions.error(error));
      setLoading(false);
    }
  };

  return (
    <div className="bg-white rounded-lg w-[420px] md:w-[440px] p-8 ">
      <h3 className="text-4xl font-bold text-h28_dark_green mb-2">
        Reset Password
      </h3>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched }) => (
          <Form>
            <div className="mt-4">
              <div className="mt-4">
                <label className="block text-h28_dark_green text-sm font-semibold">
                  New Password
                </label>
                <Field
                  type="password"
                  placeholder="Password"
                  autoComplete="off"
                  name="password"
                  className={`w-full px-4 py-3 mt-1 border text-neutral-500 text-sm rounded-md focus:outline-none ${
                    errors.password && touched.password ? "border-red-500" : ""
                  } focus:border-h28_dark_green`}
                />
                <ErrorMessage
                  name="password"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>

              <div className="mt-4">
                <label className="block text-h28_dark_green text-sm font-semibold">
                  Confirm Password
                </label>
                <Field
                  type="password"
                  placeholder="Password"
                  autoComplete="off"
                  name="confirmpassword"
                  className={`w-full px-4 py-3 mt-1 border text-neutral-500 text-sm rounded-md focus:outline-none ${
                    errors.confirmpassword && touched.confirmpassword
                      ? "border-red-500"
                      : ""
                  } focus:border-h28_dark_green`}
                />
                <ErrorMessage
                  name="confirmpassword"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>

              <div className="flex items-baseline justify-between mt-3">
                {loading ? (
                  <button
                    type="submit"
                    className="w-full px-4 py-3 font-bold text-white bg-h28_green rounded-md flex items-center justify-center"
                    disabled={loading}
                  >
                    <span
                      className="mr-5 inline-block h-5 w-5 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                      role="status"
                    >
                      <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                        Loading...
                      </span>
                    </span>
                    Loading...
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="w-full px-4 py-3 font-bold text-white bg-h28_dark_green rounded-md"
                  >
                    Reset
                  </button>
                )}
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ResetPassword;
