import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Field, ErrorMessage, useField, useFormikContext } from "formik";
import {
  getQoreidToken,
  reset as getQoreidTokenReset,
} from "../../../features/qoreid/qoreidSlice";
import { getProfile, reset } from "../../../features/profile/profileSlice";
import {
  getRequiredDocuments,
  reset as getRequiredDocumentsReset,
} from "../../../features/requiredDocuments/requiredDocumentsSlice";
import { alertActions } from "../../../app/store";

const Identity = ({ errors, touched, values, uploadFile }) => {
  const [uploadMessage, setUploadMessage] = useState("");
  const [idData, setIdData] = useState(null);
  const [files, setFiles] = useState([]);
  const dispatch = useDispatch();

  const removeFile = (filename) => {
    setFiles(files.filter((file) => file.name !== filename));
  };
  const url = process.env.REACT_APP_QOREID_API;
  const { qoreid_token } = useSelector((state) => state.qoreid_token);
  const { user } = useSelector((state) => state.auth);
  const { profile } = useSelector((state) => state.profile);

  const { required_documents } = useSelector(
    (state) => state.required_documents
  );

  const documentIndex = required_documents.find(
    (required_document) =>
      required_document.document === "PHOTOCOPY OF MEANS OF IDENTIFICATION"
  );

  const documentIndex2 = required_documents.find(
    (required_document) =>
      required_document.document === "COMPLETED OPENING FORM"
  );

  const BaseUrl = process.env.REACT_APP_BASE_API_URL;

  useEffect(() => {
    dispatch(getProfile());
    return () => {
      dispatch(reset());
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(getRequiredDocuments());
    return () => {
      dispatch(getRequiredDocumentsReset());
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(getQoreidToken());
    return () => {
      dispatch(getQoreidTokenReset());
    };
  }, [dispatch]);

  const uploadHandler = (event) => {
    // event.preventDefault();
    const file = event.target.files[0];
    if (!file) return;
    if (file.size > 1024 * 1024) {
      dispatch(
        alertActions.error({
          message: "File size should not exceed 1MB",
          showAfterRedirect: true,
        })
      );
      return;
    }
    file.isUploading = true;
    setFiles([...files, file]);

    // upload file
    const formData = new FormData();

    const formData2 = new FormData();

    setUploadMessage("Uploading please wait");

    formData.append("Files", file);
    formData.append("DocumentId", documentIndex.documentId);
    formData.append("Comment", "PHOTOCOPY OF MEANS OF IDENTIFICATION");

    formData2.append("Files", file);
    formData2.append("DocumentId", documentIndex2.documentId);
    formData2.append("Comment", "COMPLETED OPENING FORM");

    const requestOne = axios.post(`${BaseUrl}/uploadclientdocument`, formData, {
      mode: "cors",
      headers: {
        "Content-Type": "multipart/form-data",
        accept: "*/*",
        Authorization: `Bearer ${user.token}`,
      },
    });

    const requestTwo = axios.post(
      `${BaseUrl}/uploadclientdocument`,
      formData2,
      {
        mode: "cors",
        headers: {
          "Content-Type": "multipart/form-data",
          accept: "*/*",
          Authorization: `Bearer ${user.token}`,
        },
      }
    );

    axios
      .all([requestOne, requestTwo])
      .then(
        axios.spread((data1, data2) => {
          console.log("One");
          file.isUploading = false;
          //setUploadMessage("Uploaded Successfully");
          dispatch(
            alertActions.success({
              message: "Uploaded Successfully",
              showAfterRedirect: true,
            })
          );

          setFiles([...files, file]);
          console.log("data1", data1, "data2", data2);
        })
      )
      .catch((err) => {
        // inform the user
        console.log(err);
        dispatch(alertActions.error(err));
        removeFile(file.name);
      });
  };

  async function fetchIdData(id_number, idType) {
    ///await new Promise((r) => setTimeout(r, 500));

    const config = {
      headers: {
        Authorization: `Bearer ${qoreid_token.accessToken}`,
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
      },
    };

    if (idType === "NIN") {
      setIdData(null);
      await axios
        .post(
          `${url}/nin/${id_number}`,
          { firstname: profile.firstname, lastname: profile.surname },
          config
        )
        .then((res) => {
          console.log(res);
          setIdData(res);
        })
        .catch((err) => {
          // inform the user
          console.log(err);
          setIdData(err);
        });
    } else if (idType === "VOTERS") {
      setIdData(null);
      const response = await axios.post(
        `${url}/vin/${id_number}`,
        {
          firstname: profile.firstname,
          lastname: profile.surname,
          dob: moment(profile.dateOfBirth).format("YYYY-MM-DD"),
        },
        config
      );
      console.log(response);
      setIdData(response);
    } else if (idType === "DRIVERS_LICENSE") {
      setIdData(null);
      const response = await axios.post(
        `${url}/driver-license/${id_number}`,
        {
          firstname: profile.firstname,
          lastname: profile.surname,
          dob: moment(profile.dateOfBirth).format("YYYY-MM-DD"),
        },
        config
      );
      console.log(response);
      setIdData(response);
    } else {
      setIdData(null);
      const response = await axios.post(
        `${url}/passport/${id_number}`,
        { firstname: profile.firstname, lastname: profile.surname },
        config
      );
      console.log(response);
      setIdData(response);
    }
  }

  return (
    <div className="mt-5">
      {/* <div className="">
        <label className="block text-h28_dark_green text-md font-semibold mb-1">
          Nationality
        </label>
        <Field
          as="select"
          name="nationality"
          className={`w-full px-4 py-3 mt-1 border text-neutral-500 text-sm rounded-md focus:outline-none ${
            errors.nationality && touched.nationality ? "border-red-500" : ""
          } focus:border-nelsa_primary`}
        >
          <option value="">Select</option>
          <option value="Other">Other</option>
          <option value="Nigerian">Nigerian</option>
        </Field>
        <ErrorMessage
          name="nationality"
          component="div"
          className="text-red-500 text-sm"
        />
      </div> */}
      <div className="mt-5">
        <label className="block text-h28_dark_green text-md font-semibold mb-1">
          ID Type
        </label>
        <Field
          as="select"
          name="idType"
          className={`w-full px-4 py-3 mt-1 border text-neutral-500 text-sm rounded-md focus:outline-none ${
            errors.idType && touched.idType ? "border-red-500" : ""
          } focus:border-nelsa_primary`}
        >
          <option value="">Select</option>
          <option value="NIN">NIN</option>
          <option value="PASSPORT">International Passport</option>
          <option value="VOTERS">Voters Card</option>
          <option value="DRIVERS_LICENSE">Driver's License</option>
        </Field>
        <ErrorMessage
          name="idType"
          component="div"
          className="text-red-500 text-sm"
        />
      </div>
      <div className="mt-5">
        <label className="block text-h28_dark_green text-ms font-semibold mb-1">
          Identification Number
        </label>
        <Field
          type="text"
          name="id_number"
          onBlur={(e) => {
            let id_number = e.currentTarget.value;
            fetchIdData(id_number, values.idType);
          }}
          placeholder="ID Number"
          autoComplete="off"
          className={`w-full px-4 py-3 mt-1 border text-neutral-500 text-sm rounded-md focus:outline-none ${
            errors.id_number && touched.id_number ? "border-red-500" : ""
          } focus:border-nelsa_primary `}
        />
        <ErrorMessage
          name="id_number"
          component="div"
          className="text-red-500 text-sm"
        />
        {idData && (
          <div>
            <p
              className={`text-sm ${
                idData?.status === 200 ? "text-green-500" : "text-red-500"
              } capitalize`}
            >
              {idData?.status === 200
                ? "Verified"
                : "No Match, please try again"}
            </p>
          </div>
        )}
      </div>

      <div className="mt-4">
        <label className="block text-h28_dark_green text-ms font-semibold mb-1">
          Identification Image
        </label>

        <input
          type={"file"}
          accept={"image/jpeg,image/png,application/pdf"}
          onChange={uploadHandler}
          required
          className={`w-full px-3 py-2.5 mt-1 border text-black text-sm rounded-md focus:border-nelsa_primary focus:outline-none`}
        />
        {/* <IdUpload />
        {files.map((file, index) => (
          <span className="flex flex-row p-3 my-2 bg-blue-50 rounded-lg">
            <p key={index} className="text-sm text-h28_light_green_2">
              {file?.name}
            </p>
          </span>
        ))} */}
        {/* {uploadMessage && (
          <p className="text-md text-green-600">
            Document uploaded successfully
          </p>
        )} */}
      </div>
      {/* {values.nationality === "Nigerian" ? (
        <div className="">
          <label className="block text-h28_dark_green text-ms font-semibold mb-1">
            BVN
          </label>
          <Field
            type="text"
            name="bvn"
            placeholder="Enter your bvn"
            autoComplete="off"
            className={`w-full px-4 py-3 mt-1 border text-neutral-500 text-sm rounded-md focus:outline-none ${
              errors.bvn && touched.bvn ? "border-red-500" : ""
            } focus:border-nelsa_primary `}
          />
          <ErrorMessage
            name="bvn"
            component="div"
            className="text-red-500 text-sm"
          />
        </div>
      ) : (
        ""
      )} */}
    </div>
  );
};

export default Identity;
